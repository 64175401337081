import React, {useEffect, useState} from 'react'
import Button from '../../../../../../../components/Buttons/Button'
import CreateCreditCode from './components/CreateCreditCode'
import Search from '../../../../../../../components/Inputs/Search'
import CreditCodeTable from './components/CreditCodeTable'
import { api } from '../../../../../../../api/billing'
import { useQuery } from 'react-query'
import Pagination from '../../../../../../../components/pagination/Pagination'

const CourseCreditCode = () => {

  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false)
  const [activeCourse, setActiveCourse] = useState(null)

  const {
    data: courseCodes,
    isLoading,
    refetch,
  } = useQuery(
    ["course codes",{ currentPage, search, perPage }],
    ({ queryKey }) => {
      return api.getCourseCreditCodes({ ...queryKey[1]});
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  useEffect( () => {
    refetch()
  }, [ currentPage, perPage, search ] )

  return (
    <div className='bg-lightBlu p-4'>   
        <header className='text-lg font-semibold flex justify-between items-center'>
            Course Credit Code
             <div>
                <Button 
                    bgColor={'bg-darkBlue'} width={'w-[200px] mx-auto mt-4'}
                    text={'Create Course Credit Code'}
                    onClick={ () => setOpenModal(true) }
                />
             </div>
        </header>
        <section>
            <CreateCreditCode 
               refetch={refetch} openModal={openModal}
               setOpenModal={setOpenModal} activeCourseCode={activeCourse}
               setActiveCourseCode={setActiveCourse}
            />
        </section>
        <section className='bg-white shadow-lg px-3 mt-5 pb-3 rounded-lg'>
            <header className='flex justify-between items-center'>
                <h2 className='text-sm'>
                   Courses With Credit Codes
                </h2>
                    <div className='mt-2'>
                    <Search 
                        className={'mb-0 w-[300px] text-xs pl-2'}
                        placeholder={'Search Credit Code'} 
                        onChange={setSearch}
                    />
                    </div>
              </header>
            <CreditCodeTable
             isLoading = {isLoading}
             courseCodes={courseCodes}
             perPage={perPage}
             refetch={refetch}
             setActiveCourse={setActiveCourse}
             setOpenModal={setOpenModal}
            />
            <Pagination 
                data={courseCodes?.meta}
                perPage={perPage}
                pageNo={currentPage}
                setPageNo={setCurrentPage}
                setPerPage={setPerPage}
            />
        </section>
   </div>
  )
}

export default CourseCreditCode