import React from 'react'
import Button from '../../../../../../../components/Buttons/Button'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import CustomDropdown from './../../../../../../../components/dropdown/CustomDropdown';
import Switch from 'react-switch'
import { api } from '../../../../../../../api/quiz-and-sport';
import { useMutation, useQuery } from 'react-query';
import { api as teamsApi } from '../../../../../../../api/teams';
import toast from 'react-hot-toast';
import { errorResponse } from '../../../../../../../components/utils/errorResponse';
import { useNavigate } from 'react-router-dom';
import addGroupImage from '../../../../../../../assets/Add Group.png'

const CreateSport = ({activeSport, setActiveSport}) => {

    const key = "get quiz dropdown"
    const orgId =  JSON.parse(sessionStorage.getItem('user'))?.org?.id
    const navigate = useNavigate()
    const { data: quiz, isLoading: quizLoading, } = useQuery(
        [key],
        () => { 
            return api.getQuizes();
        },
        {
            cacheTime: 100,
            staleTime: 100,
            select: response => response?.quizzies
      });

      const { data: teams, isLoading: teamsLoading, } = useQuery(
        [ 'get teams dropdown', { perPage: 10000, currentPage: 1, search: null, orgId }],
        ({queryKey}) => { 
            return teamsApi.getTeams(queryKey[1]);
        },
        {
            cacheTime: 100,
            staleTime: 100,
            select: response => response?.data
      });

    const createSportMutation = useMutation(
         activeSport === null ? api.createSports : api.updateSports , {
        onSuccess: res => {
          toast.remove()
          toast.success(res?.message)
          navigate(`/admin/learners/quiz-and-sports?tab=sports`)
        },
        onMutate: () => {
            toast.loading('Loading...')
        },
        onError: error => errorResponse(error)
    })

    const initialValues = {
         orgQuizId: activeSport === null ? "" : activeSport?.quizFunId,
         name: activeSport === null ? "" : activeSport?.name,
         teams: activeSport === null ? [] : activeSport?.sportTeams?.map( sport => {
              return { label: sport?.team?.name, value: sport?.team?.id }
         }),
         status: 'active'
    }

    const validate = (values) => {
        const errors = {};
        if(!values?.name) errors.name = `Error! sport name is required`
        else if(!values?.orgQuizId) errors.orgQuizId = `Error! select quiz.`
        else if(!values?.teams?.length) errors.teams = `Error! select atleast one team.`
        return errors;
    }

    const createSport = (values, actions) => {
        actions.setSubmitting(false);
        const payload = {
          ...values, quizFunId: values.orgQuizId, 
           quizFunTitle: quiz?.find( q => q?.id === values.orgQuizId )?.quiz_title,
           teams: values?.teams?.map( team => team?.value )
        }
        createSportMutation.mutate( 
            activeSport === null ? { orgId, data: payload } :
            { orgId, data: payload, id: activeSport?.id }
        )
      }

  return (
    <section>
        <header className='flex items-center justify-between mt-3'>
             <h5 className='text-md font-semibold -mt-2 bi bi-plus-circle'> 
               &nbsp; {activeSport === null ? 'Create' : 'Edit' } Inter House Sport 
             </h5>
             <Button 
               width={'w-[150px]'}
               text={'Back'}
               renderAs='link'
               to={-1}
               bgColor={'bg-secondary'}
             />
        </header>
        <section className='border flex justify-between pr-0 px-7 border-slate-400 rounded-lg mx-3 mt-8'>
             <div className='w-[40%] py-7 flex items-center justify-center'>
                 <img src={addGroupImage} className='w-[260px] h-[260px]' alt="add group" />
             </div>
               <section className='w-[52%]'>
               <div className=''>
                    <section className='bg-white mx-auto rounded-xl p-4 shadow-lg'>
                    <Formik
                              initialValues={initialValues}
                              validate={validate}
                              enableReinitialize={true}
                              onSubmit={createSport}
                        >
                        {({ values, setFieldError, setFieldValue, isValid, errors }) => (
                            <Form>
                               <h5 className='pl-5 text-md mb-2 font-semibold'>
                                  Enter Sport Details
                               </h5>
                                <div className='w-[90%] mx-auto'>
                                    <label htmlFor="name" className='text-xs block'>
                                        Sports Name <span className='text-sm pl-1 text-red-500'>*</span>
                                    </label>
                                    <Field 
                                      style={{border: errors?.name ? '1px solid red' : '1px solid #ddd' }} 
                                      className="input-style pt-4 pb-4 bg-primaryGray"  name='name' 
                                      placeholder='Enter inter house sport name'
                                    />
                                    <p className='text-xs mt-2 text-red-500'>
                                        <ErrorMessage name='name'  />
                                    </p>
                                </div>
                                <div className='w-[90%] mx-auto mt-3'>
                                    <label htmlFor="orgQuizId" className='text-xs block'>
                                        Select Quiz <span className='text-sm pl-1 text-red-500'>*</span>
                                    </label>
                                    <CustomDropdown 
                                      options={
                                        quiz?.length ? 
                                          quiz?.map( quiz => { 
                                            return { label: quiz?.quiz_title, value: quiz?.id }
                                          }) : []
                                      }
                                      value={ 
                                        { 
                                          label: quiz?.find( q => q?.id === values.orgQuizId )?.quiz_title,
                                          id: values?.orgQuizId
                                       }
                                      }
                                      multiSelect={false}
                                      placeholder={'Select Quiz...'}
                                      className={'bg-primaryGray text-sm select-custom'}
                                      width={'w-full'}
                                      onChange={ values => {
                                          setFieldValue('orgQuizId', values?.value)
                                      } }
                                      />
                                    <p className='text-xs mt-2 text-red-500'>
                                        <ErrorMessage name='orgQuizId'  />
                                    </p>
                                </div>
                                <div className='w-[90%] mx-auto mt-3'>
                                    <label htmlFor="orgQuizId" className='text-xs block'>
                                        Select Teams <span className='text-sm pl-1 text-red-500'>*</span>
                                    </label>
                                    <CustomDropdown 
                                      options={
                                        teamsLoading ? [] : 
                                          teams?.length ? 
                                          teams?.map( team => {
                                            return { label: team?.team?.name, value: team?.team?.id }
                                          })
                                          : []
                                      }
                                      value={values?.teams}
                                      multiSelect
                                      placeholder={'Select teams to participate...'}
                                      className={'bg-primaryGray text-sm select-custom'}
                                      width={'w-full'}
                                      onChange={ values => {
                                          setFieldValue('teams', values)
                                      } }
                                      />
                                    <p className='text-xs mt-2 text-red-500'>
                                        <ErrorMessage name='teams'  />
                                    </p>
                                </div>
                                <div className='flex text-xs items-center mt-4 ml-[5%]'>
                                  Status &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Switch
                                        height={20}
                                        width={40}
                                        handleDiameter={18}
                                        checked={values?.status === 'active'}
                                        onChange={() =>
                                          setFieldValue('status', values?.status === 'active' ? 'inactive' : 'active')
                                        }
                                    />
                                </div>
                                <div className='w-[90%] flex justify-center mx-auto my-3'>
                                    <Button
                                      type='submit'
                                      width={'w-[250px]'}
                                      bgColor={'bg-secondary'}
                                      text={ activeSport === null ? 'Create Sport Team': 'Edit Sport Team'}
                                      onClick={ () => document.querySelector('#create-sport-btn').click() }
                                    />
                                    <button type='submit' className='hidden' id='create-sport-btn' />
                                </div>
                            </Form>
                        )}
                    </Formik>
                    </section>
                </div>
               </section>
   </section>
    </section>
  )
}

export default CreateSport