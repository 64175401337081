import React, { useState } from "react";
import CenteredModal from "../../../../../../../../components/Modal/CenteredModal";
import Prerequisites from "./Prerequisites";

const Settings = ({ settingsModal, handleSettingsModal, selectedLearners_ = [] }) => {

  const [selectedPrerequisites, setSelectedPrerequisites] = useState([]);
  const [selectedLearners, setSelectedLearners] = useState(selectedLearners_);

  const [currentView, setCurrentView] = useState(1);

  const handleView = (value) => {
    return () => {
      setCurrentView(value);
    };
  };

  return (
    <CenteredModal
      hasHeader={false}
      open={settingsModal}
      setOpen={handleSettingsModal}
      bgColor={'bg-gray-100'}
    >
      <div className="w-full flex items-start p-2 justify-start flex-col">
        <h1 className="text-2xl font-medium text-darkBlue">Settings</h1>
        <p className="text-sm text-gray-500 text-left">
          Set the requirements, choose learners and assign roles within your
          learning path
        </p>
        <div className="w-full flex items-center py-4 justify-start gap-5">
          <button
            className={
              currentView === 1 ? "text-customOrange underline" : "text-black"
            }
            onClick={handleView(1)}
          >
            Prerequisites
          </button>
        </div>
        {currentView === 1 && (
          <Prerequisites
            selectedPrerequisites={selectedPrerequisites}
            setSelectedPrerequisites={setSelectedPrerequisites}
            handleSettingsModal={handleSettingsModal}
          />
        )}
      </div>
    </CenteredModal>
  );
};

export default Settings;
