import React from 'react'
import { v4 } from 'uuid'
import { motion, AnimatePresence } from 'framer-motion'

type SlidesType = {
  pages: any[],
  setPages: React.Dispatch<React.SetStateAction<any[]>>
}

const Slides: React.FC<SlidesType> = ({pages, setPages}) => {
  return (
    <div className="px-1">
      <AnimatePresence mode="popLayout">
        {pages.map((Page, index) => (
          <motion.div
            key={Page.id}
            layout
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
            className='group relative h-[140px] py-1 flex-col text-center mb-2 overflow-hidden flex justify-center items-center bg-slate-100 rounded shadow-sm'
          >
            <motion.span layout className='text-xs text-gray-500'>{index + 1}</motion.span>
            <div className='h-[90%] block mx-auto pointer-events-none w-[90%] overflow-hidden bg-white border border-gray-200'>
              <div className="scale-[0.15] origin-center w-[600%] h-[600%] -translate-x-[42%] -translate-y-[42%]">
                <Page.Page />
              </div>
            </div>
            <span 
              className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-md bi bi-trash hover:cursor-pointer text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10'
              onClick={() => {
                setPages?.(pages.filter(page => page.id !== Page.id))
              }}
            />
            <div className='absolute inset-0 bg-black opacity-0 group-hover:opacity-30 transition-opacity duration-200' />
            <div className='absolute inset-0 hover:cursor-pointer' />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
}

export default Slides