import { Add, Edit } from '@material-ui/icons';
import React from 'react'
import { Routes, Route } from "react-router";
import AddNewRole from './AddNewRole';
import EditRole from './EditRolex';

const RolesPrivilegesRoutes = () => {
  return (
    <Routes>
      <Route path="/new-role" element={<AddNewRole />} />
      <Route path="/edit-role" element={<EditRole />} />
    </Routes>
  )
}

export default RolesPrivilegesRoutes