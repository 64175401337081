import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { rawTimeZones } from '@vvo/tzdb';
import CustomDropdown from '../../../../../../../components/dropdown/CustomDropdown';
import { createResource } from '../../../../../../../api/uploadResourse';
import toast from 'react-hot-toast';
import { errorResponse } from '../../../../../../../components/utils/errorResponse';  
import ResourceLoader from '../../../../../../../components/loader/ResourceLoader';
import { api } from '../../../../../../../api';
import { refetchType } from '../ProfileAndAccountSetup';

type initialValuesType = {
   theme: string,
   bodyTextColor: string,
   bodyColor: string,
   primaryButtonColor: string, 
   secondaryButtonColor: string,
   primaryButtonTextColor: string,
   secondaryButtonTextColor: string,
   logo: string,
   mobileLogo: string,
   fontStyle: string,
   normalFont: string,
   fontFamily: string,
   timeZone: string,
   language: 'English' | 'French' | 'Portuguese',
   dateFormat: string,
   timeFormat: '24 hours' | '12 hours',
}

type orgAappearanceType = {
  desktopLogo: string
  mobileLogo: string
  themeColour: string,
  bodyBackgroundColour: string,
  bodyTextBackGroundColour: string,
  headerbackgroundColour: string,
  menuTextColour: string,
  primaryButtonColour: string,
  secondaryButtonColour: string,
  primaryTextColour: string
  secondaryTextColour: string,
  font: string,
  fontSize: string,
  fontStyle: string,
  timezone: string,
  dateFormat: string
  language: 'English' | 'French' | 'Portuguese',
  timeFormat: '24 hours' | '12 hours'
}

type AccountCustomizationType = {
  orgAppearance: orgAappearanceType
  refetch: refetchType
}

const AccountCustomization: React.FC<AccountCustomizationType> = ({orgAppearance, refetch}) => {

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const orgId: string = JSON.parse(sessionStorage.getItem('user')!)?.org?.id

  const initialValues: initialValuesType = {
    logo: orgAppearance?.desktopLogo,
    mobileLogo: orgAppearance?.mobileLogo,
    fontStyle: orgAppearance?.fontStyle,
    dateFormat: orgAppearance?.dateFormat, 
    theme: orgAppearance?.themeColour || '#091F46', 
    language: orgAppearance?.language, 
    normalFont: orgAppearance?.fontSize,
    fontFamily: orgAppearance?.font || 'Roboto',
    bodyColor: orgAppearance?.bodyBackgroundColour ||'#F2F4F7',
    bodyTextColor: orgAppearance?.bodyTextBackGroundColour || '#111111', 
    primaryButtonColor: orgAppearance?.primaryButtonColour || '#091F46', 
    secondaryButtonColor: orgAppearance?.secondaryButtonColour || '#FF9100',
    primaryButtonTextColor: orgAppearance?.primaryTextColour || '#FFFFFF',
    secondaryButtonTextColor: orgAppearance?.secondaryTextColour || '#FFFFFF', 
    timeFormat: orgAppearance?.timeFormat || '12 hours', 
    timeZone: orgAppearance?.timezone || '+01:00 West Africa Time - Lagos, Kano, Ibadan, Abuja (Africa/Lagos)', 
  } 

  const [fonts] = useState<string[]>([
    'Arial', 'Helvetica', 'Times New Roman', 'Courier New', 
    'Georgia', 'Verdana', 'Trebuchet MS', 'Comic Sans MS', 
    'Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Poppins'
  ])

  const languages: ('Enlish'| 'French' | 'Portiguese')[] = ['Enlish','French','Portiguese',]

  const fontsStyle: (string|number)[] = ['normal', 'italic', 'oblique']
  const timeFormat: ('24 hours' | '12 hours')[] = ['24 hours', '12 hours']
  const dateFormats: string[] = ['dd-mm-yyyy', 'yyyy-mm-dd', 'mm-dd-yyyy', 'yyyy-dd-mm']
  
  const uploadClientProfile = async ( 
    event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any, field: string
   ) : Promise<void> => {
         setIsUploading(true)
         try{
            const file: File =  event?.target?.files![0]; 
         if (file) {
            const formData: FormData = new FormData();
            formData.append("file", file);
            const response = await createResource.uploadResource(formData)
            toast.success(response?.data?.message)
            setFieldValue(field,response?.data?.data)
            setIsUploading(false)
         } 
         } catch(error){
            setIsUploading(false)
            errorResponse(error)
         }
 }

  const createProfile = async (values: initialValuesType, actions:any) : Promise<void>  => {
    actions.setSubmitting(false);
    try{
      sessionStorage.setItem('account-customization', JSON.stringify(values))
      toast.loading('Loading...')
      const response = await api.createOrganisationAppearance({
        desktopLogo: values.logo,
        bodyBackgroundColour: values.bodyColor,
        bodyTextBackGroundColour: values.bodyTextColor,
        primaryButtonColor: values.primaryButtonColor,
        dateFormat: values.dateFormat,
        mobileLogo: values.mobileLogo,
        fontSize: values.normalFont,
        language: values.language,
        fontStyle: values.fontStyle,
        themeColour: values.theme,
        timezone: values.timeZone,
        timeFormat: values.timeFormat,
        primaryButtonTextColor: values.primaryButtonTextColor,
        secondaryButtonColor: values.secondaryButtonColor,
        secondaryButtonTextColor: values.secondaryButtonTextColor,
        font: values.fontFamily,
      }, orgId) 
      toast.remove()
      toast.success(response?.message)
      window.location.reload()
    }catch(error: any){
      toast.remove()
      toast.error(error?.response?.data?.message)
    }
  };

  return (
    <div>  { isUploading && <ResourceLoader /> }
        <h5 className='text-lg font-semibold'>
           Customization
        </h5>
        <p className='text-sm font-light mt-1'>
           Set Up the customization for your account
        </p>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={createProfile}
        >
          {({ values, setFieldError, setFieldValue, setFieldTouched }) => (
            <Form> 
                 <h4 className='text-sm mt-3 font-semibold'>Site Logo</h4>
                  <section className='grid grid-cols-2 gap-5 mt-2'>
                       <div>
                         {
                           values.logo && 
                           <img src={values.logo} alt="logo" />
                         }
                        <label htmlFor="logo" className='text-xs'>
                           Logo (File must be at most 350px by 80 px)
                        </label>
                         <input
                             id='logo' className="hidden" 
                             name='logo' type='file'
                             onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                              uploadClientProfile(event, setFieldValue, 'logo')
                            }}
                          />
                          <div className='flex'>
                             <div className='py-4 w-[65%] bg-primaryGray' />
                              <label htmlFor='logo' className='bg-secondary bi bi-file-earmark-arrow-up w-[35%] text-white text-xs py-4 text-center'>
                                 &nbsp;Browse Media
                              </label>
                          </div>
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='logo' />
                          </p>
                       </div>
                       <div>
                       {
                           values.mobileLogo && 
                           <img src={values.mobileLogo} alt="logo" />
                         }
                        <label htmlFor="mobileLogo" className='text-xs'>
                           Mobile Logo (File must be at most 100px by 40 px)
                        </label>
                         <input 
                             id='mobileLogo' className="hidden" 
                             name='mobileLogo' type='file'
                             onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                              uploadClientProfile(event, setFieldValue, 'mobileLogo')
                            }}
                          />
                          <div className='flex'>
                             <div className='py-4 w-[65%] bg-primaryGray' />
                              <label htmlFor="mobileLogo" className='bg-secondary  bi bi-file-earmark-arrow-up w-[35%] text-white text-xs py-4 text-center'>
                                 &nbsp;Browse Media
                              </label>
                          </div>
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='mobileLogo' />
                          </p>
                       </div>
                 </section>
                 <section>
                    <h4 className='text-sm font-semibold mt-8'>Brand Colors</h4>
                      <div className='mt-3 grid py-5 grid-cols-3 gap-5'>
                            <div className='flex items-center'>
                                <Field type="color" id='theme-color' name='theme'  />
                                <label htmlFor="theme-color" className='pl-2 text-xs'>
                                    Theme
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Field type="color" id='body-color' name='bodyColor'  />
                                <label htmlFor="body-color" className='pl-2 text-xs'>
                                    Body Background Color
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Field type="color" id='body-text' name='bodyTextColor'  />
                                <label htmlFor="body-text" className='pl-2 text-xs'>
                                    Primary Text Color
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Field type="color" id='button-color' name={'primaryButtonColor'}  />
                                <label htmlFor="button-color" className='pl-2 text-xs'>
                                    Primary Button Color
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Field type="color" id='p-body-color' name={'primaryButtonTextColor'}  />
                                <label htmlFor="p-body-color" className='pl-2 text-xs'>
                                    Primary Button text Color
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Field type="color" id='s-body' name={'secondaryButtonColor'}  />
                                <label htmlFor="s-body" className='pl-2 text-xs'>
                                    Secondary Button Color
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Field type="color" id='s-body-text' name={'secondaryButtonTextColor'}  />
                                <label htmlFor="s-body-text" className='pl-2 text-xs'>
                                    Secondary Button Text Color
                                </label>
                            </div>
                      </div>
                 </section>
                 <h4 className='text-sm font-semibold mt-8'>Brand Text</h4>
                 <section className='grid grid-cols-2 gap-5 mt-2'>
                       <div>
                        <label htmlFor="fontFamily" className='text-xs'>
                           Font Family
                        </label>
                        <CustomDropdown
                            options={fonts.map( (font:any) => {
                                return { label: font, value: font }
                            })}
                            value={{label: values.fontFamily, value: values.fontFamily}} 
                            onChange={ (value:any) => {
                              setFieldValue('fontFamily', value?.value)
                            }}
                            onBlur={ (value: any) => {
                                if(!value?.value){
                                    setFieldTouched('fontFamily', true, true)
                                  }
                            }}
                            name={'fontFamily'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'System Font'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='fontFamily' />
                          </p>
                       </div>
                       <div>
                        <label htmlFor="fontStyle" className='text-xs'>
                           Font Style  
                        </label>
                        <CustomDropdown
                            options={fontsStyle.map( (font:any) => {
                                return { label: font, value: font }
                            })}
                            value={{label: values.fontStyle, value: values.fontStyle}} 
                            onChange={ (value:any) => {
                              setFieldValue('fontStyle', value?.value)
                            }}
                            onBlur={ (value: any) => {
                                if(!value?.value){
                                    setFieldTouched('fontStyle', true, true)
                                  }
                            }}
                            name={'fontStyle'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'System Font'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='fontStyle' />
                          </p>
                       </div>
                 </section>
                 <section className='grid grid-cols-2 gap-5 mt-2'>
                       <div>
                        <label htmlFor="normalFont" className='text-xs'>
                           Font Size   
                        </label>
                         <Field 
                             id='normalFont' className="input-style bg-primaryGray" 
                             name='normalFont'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='normalFont' />
                          </p>
                       </div>
                     </section>
                     <h4 className='text-sm font-semibold mt-8'>Platform Setting</h4>
                     <section className='grid grid-cols-2 gap-5 mt-2'>
                       <div> 
                        <label htmlFor="timeZone" className='text-xs'>
                           Timezone
                        </label>
                        <CustomDropdown
                            options={ rawTimeZones.map( (tz:any) => {
                                return { 
                                         label:`${tz.rawFormat} (${tz.name})`,
                                         value: `${tz.rawFormat} (${tz.name})` 
                                      }
                            })}
                            value={{label: values.timeZone, value: values.timeZone}} 
                            onChange={ (value:any) => {
                              setFieldValue('timeZone', value?.value)
                            }}
                            onBlur={ (value: any) => {
                                if(!value?.value){
                                    setFieldTouched('timeZone', true, true)
                                  }
                            }}
                            name={'timeZone'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'System Font'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='timeZone' />
                          </p>
                       </div>
                       <div> 
                        <label htmlFor="language" className='text-xs'>
                           Prefered Language (you can also change language later)
                        </label>
                        <CustomDropdown
                            options={ languages.map( (lg:string) => {
                                return { label: lg, value: lg }
                            })}
                            value={{label: values.language, value: values.language}} 
                            onChange={ (value:any) => {
                              setFieldValue('language', value?.value)
                            }}
                            onBlur={ (value: any) => {
                                if(!value?.value){
                                    setFieldTouched('language', true, true)
                                  }
                            }}
                            name={'language'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'English'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='language' />
                          </p>
                       </div>
                 </section>   
                 <section className='grid grid-cols-2 gap-5 mt-2'>
                       <div> 
                        <label htmlFor="dateFormat" className='text-xs'>
                          Date Format
                        </label>
                        <CustomDropdown
                            options={dateFormats.map( (date:string) => {
                                return { label: date, value: date }
                            })}
                            value={{label: values.dateFormat, value: values.dateFormat}} 
                            onChange={ (value:any) => {
                              setFieldValue('dateFormat', value?.value)
                            }}
                            onBlur={ (value: any) => {
                                if(!value?.value){
                                    setFieldTouched('dateFormat', true, true)
                                  }
                            }}
                            name={'fontFamily'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'dd-mm-yyyy'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='dateFormat' />
                          </p>
                       </div>
                       <div>
                        <label htmlFor="timeFormat" className='text-xs'>
                           Time Format 
                        </label>
                        <CustomDropdown
                            options={timeFormat.map( (time:any) => {
                                return { label: time, value: time }
                            })}
                            value={{label: values.timeFormat, value: values.timeFormat}} 
                            onChange={ (value:any) => {
                              setFieldValue('timeFormat', value?.value)
                            }}
                            onBlur={ (value: any) => {
                                if(!value?.value){
                                    setFieldTouched('timeFormat', true, true)
                                  }
                            }}
                            name={'timeFormat'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'System prefered'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='timeFormat' />
                          </p>
                       </div>
                 </section>                  
                     <section className='mt-5 pb-8 flex justify-between'>
                           <button type='submit' className='bg-secondary text-white text-xs py-3 px-8 rounded'>
                               Update Settings
                           </button>
                     </section>
            </Form>
          )}
    </Formik>
    </div>
  )
}

export default AccountCustomization