import React, { useEffect, useContext, useState } from "react";
import Table from "../../../../../components/table/Table";
import useLearners from "../../../../../hooks/useLearners";
import CenteredModal from "../../../../../components/Modal/CenteredModal";
import { LearnersContext } from "../../../../../context/learnersContext";
import ViewModules from "./ViewModules";
import Summary from "./Summary";
import Pagination from "../../../../../components/pagination/Pagination";
import Header from "./Header";
import Search from "../../../../../components/Inputs/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { learners } from "../../../../../api/learners";
import { convertDate } from "../../../../helpers";
import ResetPassword from "./ResetPassword";
import InviteLearner from "./InviteLearner";
import AssignLearner from "./AssignLearner";
import DownloadData from "./DownloadData";
import { learners as api } from "../../../../../api/learners";
import InactiveLearners from "./InactiveLearners";
import ActiveLearners from "./ActiveLearners";

const LearnersTable = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const orgId = user?.org?.id;
  const currentLearner = JSON.parse(sessionStorage.getItem("currentLearner"));
  const [activeTab, setActiveTab] = useState("active");
  const [learnerStatus, setLearnerStatus] = useState("active");
  const [localSearch, setLocalSearch] = useState("");

  // const [downloadData, setDownloadData] = useState([]);

  const {
    data,
    filters,
    handleCohort,
    handleLearningPath,
    handleCourse,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    handleStatusFilter,
    search,
    setSearch,
  } = useLearners();

  const {
    openModal,
    setOpenModal,
    selectedCohort,
    selectedPath,
    selectedCourse,
    setSelectedCohort,
    setPageType,
    pageType,
    openResetModal,
    setOpenResetModal,
    openInviteModal,
    setOpenInviteModal,
    openAssignModal,
    setOpenAssignModal,
  } = useContext(LearnersContext);

  // console.log(pageType);

  // console.log(data[pageType]?.meta);

  // console.log(openModal, "openModal");

  useEffect(() => {
    setSelectedCohort({});
    setPageType("profile");
  }, []);

  useEffect(() => {
    !localSearch && setSearch("");
  }, [localSearch]);

  useEffect(() => {
    !search && setLocalSearch("");
  }, [search]);

  const { data: SummaryData, isLoading } = useQuery(
    [
      "active-and-inactive-data",
      currentPage,
      activeTab,
      selectedPath?.learningPathId,
      selectedCohort?.cohortId,
      perPage,
      search,
    ],
    async () => {
      const response =
        await api.getCohortLearnersLearningPathListCoursesSummary({
          orgId,
          currentPage,
          learnerStatus: activeTab,
          learningPathId: selectedPath?.learningPathId,
          cohortId: selectedCohort?.cohortId,
          perPage,
          search,
        });
      return response;
    },
    {
      enabled: !!selectedCohort?.cohortId && !!selectedPath?.learningPathId, // Ensure query only runs when cohortId exists
      staleTime: 5 * 60 * 1000, // Data is considered fresh for 5 minutes
      cacheTime: 30 * 60 * 1000, // Cache for 30 minutes after becoming stale
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const { mutate } = useMutation(learners.DownloadLearners_Profile, {
    onSuccess: (data) => {
      // This will run when the data is successful

      // console.log("Fetched mutat:", mutation);
      toast.remove();

      const downloadData = data?.data?.map((data) => {
        return {
          name: data?.user?.name,
          lastLogin: data?.lastLogin,
          email: data?.user?.email,
          phoneNumber: data?.user?.phoneNumber,
          createdAt: convertDate(data?.user?.createdAt),
          emailVerifiedAt: convertDate(data?.user?.emailVerifiedAt),
          gender: data?.user?.gender,
          organization: data?.user?.organization?.name,
        };
      });

      if (!downloadData?.length) return toast.error("No data to download...");

      const worksheet = XLSX.utils.json_to_sheet(downloadData);

      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

      const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

      saveAs(blob, "data.csv");
      toast.success("Downloaded successfully...");
    },
    onError: (error) => {
      console.log("Error fetching data:", error);
      toast.remove();
      toast.error("Unable to download, pls try again later...");
      console.log({ error });
    },
    onMutate: () => {
      // console.log("processing");
      toast.loading("Processing");
      // console.log({ error });
    },
  });

  const tabContent = {
    active: (
      <div className="bg-white overflow-x-hidden w-full">
        <ActiveLearners
          data={SummaryData}
          isLoading={isLoading}
          perPage={perPage}
          setPerPage={setPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    ),
    inactive: (
      <div className="p-4">
        <InactiveLearners
          data={SummaryData}
          isLoading={isLoading}
          perPage={perPage}
          setPerPage={setPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    ),
  };

  return (
    <>
      <Header />
      <CenteredModal
        title={pageType === "path" ? "Courses" : "Modules & Assessments"}
        open={openModal}
        setOpen={setOpenModal}
        width={"w-[800px]"}
      >
        <ViewModules />
      </CenteredModal>
      <CenteredModal
        title={`Reset Password - ${currentLearner?.user?.name}`}
        open={openResetModal}
        setOpen={setOpenResetModal}
        width={"w-[500px]"}
      >
        {/* <ViewModules /> */}
        <ResetPassword />
      </CenteredModal>
      <CenteredModal
        title={`Invite Learner`}
        open={openInviteModal}
        setOpen={setOpenInviteModal}
        width={"w-[500px]"}
      >
        <InviteLearner />
      </CenteredModal>
      <CenteredModal
        title={`Assign Learner - ${currentLearner?.user?.name}`}
        open={openAssignModal}
        setOpen={setOpenAssignModal}
        width={"w-[500px]"}
      >
        <AssignLearner />
      </CenteredModal>
      <div className="flex justify-between gap-2 mb-5">
        <select
          value={JSON.stringify(selectedCohort)}
          onChange={handleCohort}
          className="py-[11.5px] outline-none focus:ring-0 flex-1 text-sm rounded-md shadow-md px-2"
        >
          <option value={JSON.stringify({})}>All cohorts</option>
          {filters?.map((filter, index) => (
            <option key={index} value={JSON.stringify(filter)}>
              {filter?.cohortName}
            </option>
          ))}
        </select>
        <select
          value={JSON.stringify(selectedPath)}
          onChange={handleLearningPath}
          className="py-[11.5px] outline-none focus:ring-0 flex-1 text-sm rounded-md shadow-md px-2"
        >
          <option value={JSON.stringify({})}>All Learning Paths</option>
          {selectedCohort?.learningPaths?.map((filter, index) => (
            <option key={index} value={JSON.stringify(filter)}>
              {filter?.learningPathName}
            </option>
          ))}
        </select>
        <div className="flex">
          <select
            value={JSON.stringify(selectedCourse)}
            onChange={handleCourse}
            className="py-[11.5px] outline-none focus:ring-0 flex-1 text-sm rounded-md shadow-md px-2"
          >
            <option value={JSON.stringify({})}>All Courses</option>
            {selectedPath?.courses?.map((filter, index) => (
              <option key={index} value={JSON.stringify(filter)}>
                {filter?.courseName}
              </option>
            ))}
          </select>
          <button className="bi bi-filter px-3 py-2 bg-primary rounded-xl ml-2 text-white"></button>
        </div>
      </div>
      <Summary status={pageType} />
      <div className="mt-1 px-5">
        <div className="flex items-center justify-start gap-2 mt-10">
          <Search
            onChange={setSearch}
            // onClick={() => setSearch(localSearch)}
            // value={localSearch}
            // onChange={setLocalSearch}
            width={"w-full"}
            placeholder={"Search..."}
            showButton={true}
          />

          <select
            // value={JSON.stringify(selectedCohort)}
            onChange={handleStatusFilter}
            className="py-[12px] mb-3 w-fit outline-none border border-solid border-neutral-300 focus:ring-0 text-xs rounded-md px-2"
          >
            <option selected disabled>
              Select Status
            </option>
            <option value={""}>All</option>
            <option value={"completed"}>Completed</option>
            <option value={"in progress"}>In Progress</option>
            <option value={"not started"}>Not Started</option>
            <option value={"not enrolled"}>Not Enrolled</option>
            <option value={"enrolled"}>Enrolled</option>
          </select>
          <DownloadData pageType={pageType} />
        </div>

        <div>
          {selectedCohort?.cohortId && selectedPath?.learningPathId ? (
            // Render this when both IDs are available
            <div className="border-b border-gray-300">
              <div className="flex justify-between">
                <button
                  className={`w-full px-4 py-2 text-sm font-medium ${
                    activeTab === "active"
                      ? "text-gray-800 border-b-2 border-gray-600"
                      : "text-gray-500"
                  }`}
                  onClick={() => setActiveTab("active")}
                >
                  Active Learners
                </button>
                <button
                  className={`w-full px-4 py-2 text-sm font-medium ${
                    activeTab === "inactive"
                      ? "text-gray-800 border-b-2 border-gray-600"
                      : "text-gray-500"
                  }`}
                  onClick={() => setActiveTab("inactive")}
                >
                  Inactive Learners
                </button>
              </div>
              <div>{tabContent[activeTab]}</div>
            </div>
          ) : (
            // Render this when IDs are not available
            <div className="bg-white overflow-x-hidden w-full">
              <section className="w-[calc(100vw-25vw)] px-5 py-3 overflow-x-auto">
                <Table {...data[pageType]} />
              </section>
              <Pagination
                data={data[pageType]?.meta}
                perPage={perPage}
                pageNo={currentPage}
                setPageNo={setCurrentPage}
                setPerPage={setPerPage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LearnersTable;
