import React, {useState, useEffect} from 'react'
import Button from '../../../../../../components/Buttons/Button'
import Search from '../../../../../../components/Inputs/Search'
import Courses from './components/Courses'
import DiscountCard from './components/DiscountCard'
import Calculator from './components/Calculator'
import { useQuery } from 'react-query'
import { api } from '../../../../../../api/billing'
import Pagination from '../../../../../../components/pagination/Pagination'
import Loader from '../../../../../../components/loader/Loader'
import EmptyData from '../../../../../../components/EmptyData'

const Pricing = () => {

  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: discounts,
    isLoading,
    refetch,
  } = useQuery(
    ["client discounts"],
    ({ queryKey }) => {
      return api.getBillingDiscounts({ ...queryKey[1], perPage, currentPage });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  useEffect( () => {
    refetch()
  }, [ currentPage, perPage ] )

  return (
    <div className=' p-4'>
        <header className='text-lg font-semibold flex justify-between items-center'>
            Pricing & Calculator
            <div className='flex items-center'>
                <Button text={'Generate Report'} width={'w-[160px]'} bgColor={'bg-darkBlue'} />
            </div>
        </header>
        <section className='mt-5 p-5 rounded-lg bg-white'>
           <h5 className='pb-2 mb-2 border-b border-gray-200 text-md'>
             Discount
           </h5>
            {
              isLoading ? <Loader /> :
               discounts?.data?.length ?
              <div className='grid grid-cols-3 mt-3 gap-5'>
                 {
                   discounts?.data?.map( discount => {
                      return(
                        <DiscountCard
                          key={discount?.id}
                          title={discount?.name}
                          text={discount?.description}
                          discountCode={discount?.discountCode}
                          discount={discount?.percentOff}
                          coursesRequired={discount?.numberOfCoursesRequired}
                        />
                      )
                   } )
                 }
              </div>
              :
              <EmptyData text={'No any discount avalilabe yet.'} />
            }
        </section>
        <section className='flex mt-5 justify-between'>
            <div className='w-[65%] p-3 bg-white rounded-lg'>
                <h5 className='pb-2 mb-2 border-b border-gray-200 text-md'>
                    Courses
                </h5>
                <Search placeholder={'How To Learn...'} />
                <div>
                    <Courses />
                </div>
            </div>
            <div className='w-[33%] p-3 bg-white rounded-lg'>
                <h5 className='pb-2 bi bi-calculator justify-between border-b border-gray-200 text-md'>
                    &nbsp;Credit Calculator
                </h5>
                <p className='text-xs mt-2 '>Estimate credit requirements for course enrollments</p>
                <div>
                  <Calculator />
                </div>
            </div>
        </section>
     </div>
  )
}

export default Pricing