import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { api } from "../../../../../api/email";
import SingleSelectDropdown from "../../../../../pages/Admin/components/SingleSelectDropdown";
import { ScaleLoader } from "react-spinners";

const Options = [
  { label: "TLS", value: "TLS" },
  { label: "SSL", value: "SSL" },
];

const EmailSettingStep2 = ({ formData, nextStep }) => {
  const navigate = useNavigate();
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  // Fetch meeting settings
  const { data: setting, isLoading } = useQuery(
    ["e-settings"],
    async () => {
      const response = await api.getEmailSettings({ orgId });
      
      return response.data;
    },
    {
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const [encryptionType, setEncryptionType] = useState(
    setting?.encryption || ""
  );

  const [showPassword, setShowPassword] = useState(false);

  const handleSelectedActivity = (option) => {
    setEncryptionType(option.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNext = (values) => {
    nextStep(values);
  };

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required("Display Name is required"),
    hostName: Yup.string().required("Host Name is required"),
    fromEmailAddress: Yup.string().required("Email is required"),
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const initialValues = {
    displayName: setting?.displayName || "",
    hostName: setting?.hostName || "",
    fromEmailAddress: setting?.fromEmailAddress || "",
    port: setting?.port || 0,
    username: setting?.username || "",
    password: setting?.password || "",
  };

  const saveConnectionMutation = useMutation(api.saveConnection, {
    onSuccess: (response) => {
      toast.remove();
      toast.success(response.message);
    },
    onMutate: () => {
      toast.loading("Loading...");
    },
    onError: ({ response }, variables, context) => {
      toast.remove();
      toast.error(response?.data?.message || "Something went wrong");
      variables.actions.setSubmitting(false); // Reset isSubmitting when an error occurs
    },
  });

  const handleSaveConnection = (values, actions) => {
    saveConnectionMutation.mutate(
      {
        orgId,
        payload: {
          hostName: values.hostName,
          displayName: values.displayName,
          fromEmailAddress: values.fromEmailAddress,
          port: values.port,
          username: values.username,
          encryption: encryptionType,
          password: values.password,
        },
      },
      {
        onSuccess: (values) => {
          actions.resetForm(); // Reset the form after successful mutation
          actions.setSubmitting(false);
        },
      }
    );
  };

  const handleTestConnection = async (values, actions) => {
    toast.loading("Loading...");
    let payload = {
      hostName: values.hostName,
      displayName: values.displayName,
      fromEmailAddress: values.fromEmailAddress,
      port: values.port,
      username: values.username,
      encryption: encryptionType,
      password: values.password,
    };

    try {
      const response = await api.testConnection({ orgId, payload });
      toast.remove();
      toast.success(response.message);
    } catch (error) {
      toast.remove();
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="p-10">
      <div className="flex justify-between">
        <div className="mb-16">
          <h3 className="text-2xl font-semibold text-[#091F46]">
            Use Custom Email
          </h3>
          <p className="font-medium text-sm text-[#8E8E8E]]">
            Emails will be sent with configuration below:
          </p>
        </div>
        <div>
          <button
            type="button"
            onClick={(v) => {
              handleNext(v);
              // navigate("/admin/settings/email/test-email");
            }}
            className="p-3 bg-customBlue text-white text-xs rounded-md"
          >
            Send test email
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <ScaleLoader color="#FF9100" loading={isLoading} />
        </div>
      ) : (
        <div className="">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) =>
              handleSaveConnection(values, actions)
            }
            enableReinitialize
          >
            {({ isSubmitting, values, actions }) => (
              <Form>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-20">
                  <div className="flex flex-col  mb-6">
                    <label
                      htmlFor="displayName"
                      className="mb-1 text-base font-medium text-gray600"
                    >
                      Email sender display name
                    </label>
                    <div className="flex relative ">
                      <Field
                        type={"text"}
                        id="displayName"
                        name="displayName"
                        className="input-style bg-white"
                        placeholder="Enter your displayName"
                      />
                    </div>
                    <ErrorMessage
                      name="displayName"
                      component="p"
                      className="error-style"
                    />
                  </div>
                  <div className="flex flex-col  mb-6">
                    <label
                      htmlFor="hostName"
                      className="mb-1 text-base font-medium text-gray600"
                    >
                      SMTP Server Host name
                    </label>
                    <div className="flex relative ">
                      <Field
                        type={"text"}
                        id="hostName"
                        name="hostName"
                        className="input-style bg-white"
                        placeholder="Enter your hostName"
                      />
                    </div>
                    <ErrorMessage
                      name="hostName"
                      component="p"
                      className="error-style"
                    />
                  </div>
                  <div className="flex flex-col  mb-6">
                    <label
                      htmlFor="fromEmailAddress"
                      className="mb-1 text-base font-medium text-gray600"
                    >
                      Email address to send mail from
                    </label>
                    <div className="flex relative ">
                      <Field
                        type={"text"}
                        id="fromEmailAddress"
                        name="fromEmailAddress"
                        className="input-style bg-white"
                        placeholder="Enter your fromEmailAddress"
                      />
                    </div>
                    <ErrorMessage
                      name="fromEmailAddress"
                      component="p"
                      className="error-style"
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="flex flex-col  w-1/3">
                      <label
                        htmlFor="port"
                        className="mb-1 text-base font-medium text-gray600"
                      >
                        SMTP Port
                      </label>
                      <div className="flex relative ">
                        <Field
                          type={"number"}
                          id="port"
                          name="port"
                          className="input-style bg-white"
                          placeholder="Enter your port"
                        />
                      </div>
                      <ErrorMessage
                        name="port"
                        component="p"
                        className="error-style"
                      />
                    </div>
                    <div className="w-2/3">
                      <p className="text-xs text-[#667085] font-normal">
                        Encryption
                      </p>

                      <SingleSelectDropdown
                        options={Options}
                        onSelect={handleSelectedActivity}
                        placeholder="Select encryption type"
                        placeholderColor="#A5A5A5"
                        selectedValue={encryptionType}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col  mb-6">
                    <label
                      htmlFor="username"
                      className="mb-1 text-base font-medium text-gray600"
                    >
                      SMTP Server username
                    </label>
                    <div className="flex relative ">
                      <Field
                        type={"text"}
                        id="username"
                        name="username"
                        className="input-style bg-white"
                        placeholder="Enter your username"
                      />
                    </div>
                    <ErrorMessage
                      name="username"
                      component="p"
                      className="error-style"
                    />
                  </div>
                  <div className="flex flex-col ">
                    <label
                      htmlFor="password"
                      className="mb-1 text-base font-medium text-gray600"
                    >
                      Password
                    </label>
                    <div className="flex relative">
                      <Field
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        className="input-style bg-white"
                        placeholder="Enter your password"
                      />
                      <button
                        type="button"
                        aria-label="Toggle password visibility"
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-3 text-gray-500 cursor-pointer focus:outline-none"
                      >
                        {showPassword ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="p"
                      className="error-style"
                    />
                  </div>
                </div>
                <div className="mt-5 flex gap-x-6 items-center">
                  <button
                    type="submit"
                    className={`w-full font-semibold bg-[#E8EDF4] border border-customBlue text-customBlue p-3 rounded-lg text-xs`}
                    // disabled={isSubmitting}// Disable the button while submitting
                  >
                    Save Changes
                  </button>
                  <button
                    type="button" // Use "button" type to avoid triggering form submission
                    onClick={() => handleTestConnection(values, actions)} // Pass values to handleTestConnection
                    className={`w-full font-semibold bg-customBlue text-white p-3 rounded-lg text-xs`}
                  >
                    Test Connection
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default EmailSettingStep2;
