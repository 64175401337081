import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
    ChevronRightIcon
} from "@radix-ui/react-icons";

type FilterType = {
    status: "published" | "draft" | null,
    setStatus: React.Dispatch<React.SetStateAction<"published" | "draft" | null>>,
    categories: any | [],
    setCategory: React.Dispatch<React.SetStateAction<string | null >>
    category: string | null 
}

const Filter: React.FC<FilterType> = (props) : React.ReactElement => {

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger asChild>
              <button className='btn-light bg-primaryGray border border-slate-300'>
                 Filter By <i className='bi bi-filter-square text-md ml-3'></i>
              </button>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
					<DropdownMenu.Sub>
						<DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
							Categories
							<div className="RightSlot">
								<ChevronRightIcon />
							</div>
						</DropdownMenu.SubTrigger>
						<DropdownMenu.Portal>
							<DropdownMenu.SubContent
								className="DropdownMenuSubContent"
								sideOffset={2}
								alignOffset={-5}
                                forceMount
							>
                              {
                                props.categories?.length ?
                                 props?.categories?.map( (category: any) => {
                                    return (
                                        <React.Fragment key={category?.id}>
                                             <DropdownMenu.Item 
                                                className="DropdownMenuItem"
                                                onClick={ () => props.setCategory(category?.id) }
                                              >
                                                <input 
                                                    type="checkbox"
                                                    className="h-[16px] w-[16px] mr-2"
                                                    checked={ props.category === category?.id }
                                                />
                                                {category?.name}
                                            </DropdownMenu.Item>
                                            <DropdownMenu.Separator className="DropdownMenuSeparator" />
                                       </React.Fragment>
                                    )
                                 })
                                 : null
                              }
                            { props.category &&
                                 <DropdownMenu.Item 
                                   className="DropdownMenuItem text-center text-red-500 hover:bg-red-500 hover:text-white"
                                   onClick={ () => props.setCategory(null) }
                                >
									Clear Category Filter
								</DropdownMenu.Item>}
							</DropdownMenu.SubContent>
						</DropdownMenu.Portal>
					</DropdownMenu.Sub>
                    <DropdownMenu.Separator className="DropdownMenuSeparator" />
                    <DropdownMenu.Sub>
						<DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
							Status
							<div className="RightSlot">
								<ChevronRightIcon />
							</div>
						</DropdownMenu.SubTrigger>
						<DropdownMenu.Portal>
							<DropdownMenu.SubContent
								className="DropdownMenuSubContent"
								sideOffset={2}
								alignOffset={-5}
							>
								<DropdownMenu.Item 
                                   className="DropdownMenuItem"
                                   onClick={ () => props.setStatus('draft') }
                                 >
								<input 
                                    type="checkbox"
                                    className="h-[16px] w-[16px] mr-2"
                                    checked={props.status === 'draft'}
                                /> 
                                    Draft
								</DropdownMenu.Item>
                                <DropdownMenu.Separator className="DropdownMenuSeparator" />
								<DropdownMenu.Item 
                                   className="DropdownMenuItem"
                                   onClick={ () => props.setStatus('published') }
                                >
                                 <input 
                                    type="checkbox"
                                    className="h-[16px] w-[16px] mr-2"
                                    checked={props.status === 'published'}
                                  /> 
									Published
								</DropdownMenu.Item>
                                <DropdownMenu.Separator className="DropdownMenuSeparator" />
								{ props.status &&
                                 <DropdownMenu.Item 
                                   className="DropdownMenuItem text-center text-red-500 hover:bg-red-500 hover:text-white"
                                   onClick={ () => props.setStatus(null) }
                                >
									Clear Status Filter
								</DropdownMenu.Item>}
							</DropdownMenu.SubContent>
						</DropdownMenu.Portal>
					</DropdownMenu.Sub>
					<DropdownMenu.Separator className="DropdownMenuSeparator" />
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

export default Filter;
