import React, { useEffect, useState } from 'react';
import HorizontalTab from '../../../../../../components/Tab/HorizontalTab';
import Switch from 'react-switch';
import addGroupImage from '../../../../../../assets/Add Group.png';
import toast from 'react-hot-toast';
import { api } from '../../../../../../api/learningPath';

type languageType = any 

type CohortInformationType = {
    setEnableTeams: React.Dispatch<React.SetStateAction<boolean>>,
    enableTeams: boolean
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>
    setCohortId: React.Dispatch<React.SetStateAction<string | null>>
    setStatus: React.Dispatch<React.SetStateAction<boolean>>
    cohortId: string | null
    status: boolean,
    data: any
}

const CohortInformation: React.FC<CohortInformationType> = ({ 
    enableTeams, setEnableTeams, setCurrentStep, cohortId, setCohortId, status,
    setStatus, data
}) => {

 const [useMultipleLanguages, setUseMultipleLanguages] = useState<boolean>(false);
 const [cohortName, setCohortName] = useState<string>('')
 const [frenchCohortName, setFrenchCohortName] = useState<string>("");
 const [portugueseCohortName, setPortugueseCohortName] = useState<string>("");
 const orgId = JSON.parse(sessionStorage.getItem("user")!)?.org?.id;

 useEffect( () => {
   const isMulti: boolean = data?.languages?.length > 2
   setCohortName(data?.name)
   setStatus(data?.teams?.length >= 1)
   setUseMultipleLanguages(isMulti)
   if(isMulti){
      setFrenchCohortName(
        data?.languages?.find( (language: languageType) => language?.symbol === 'fr' )?.name
      )
      setPortugueseCohortName(
        data?.languages?.find( (language: languageType) => language?.symbol === 'pr' )?.name
      )
   }
 }, [data] )

 const tabsData = [
    {
      label: `English`,
      content: (
        <div className='mt-3'>
          <label className="text-xs"> Cohort Name (English)</label>
          <input
            className="input-style bg-primaryGray mt-1"
            placeholder="Cohort Name...."
            onChange={(e) => setCohortName(e.target.value)}
            value={cohortName}
          />
        </div>
      ),
    },
    {
      label: `French`,
      content: (
        <div className='mt-3'>
          <label className="text-xs"> Cohort Name (French)</label>
          <input
            className="input-style bg-primaryGray mt-1"
            placeholder="Cohort Name...."
            onChange={(e) => setFrenchCohortName(e.target.value)}
            value={frenchCohortName}
          />
        </div>
      ),
    },
    {
      label: `Portuguese`,
      content: (
        <div className='mt-3'>
          <label className="text-xs"> Cohort Name (Portuguese)</label>
          <input
            className="input-style bg-primaryGray mt-1"
            placeholder="Cohort Name...."
            onChange={(e) => setPortugueseCohortName(e.target.value)}
            value={portugueseCohortName}
          />
        </div>
      ),
    },
  ];

  const createCohort = async () : Promise<void> => {
    if(useMultipleLanguages){
        if(!frenchCohortName || !portugueseCohortName){
            toast.error('Error! add cohort name in all languages')
            return
        }
    }
    if(!cohortName){
        toast.error('Error! cohort name is required.')
        return
    }
    try{
        toast.loading('Loading...')
        const languages: languageType[] = [
            { language: "english", symbol: "en", name: cohortName , isDefault: true,},
            { language: "french", symbol: "fr", name: frenchCohortName, isDefault: false },
            {language: "portuguese", symbol: "pr", name: portugueseCohortName, isDefault: false }
          ]

        const payload = useMultipleLanguages ? {
            id: orgId,
            cohortId: data?.id,
            data: { name: cohortName, languages }
          } : { 
               id: orgId, 
               cohortId: data?.id,
               data: {
                 name: cohortName,
                 languages: [languages[0]]
                }
              }

        let response: any = await api.updateCohort(payload)
        setCohortId(response?.data?.id)
        if (!status) setCurrentStep(2);
        else  {
            setEnableTeams(true)
        };
        toast.remove()
        toast.success(response?.message)
    } catch(error:any){
      toast.remove()
      toast.error(error?.response?.data?.message)
    }
  }
  
  return (
    <>
       <section className='border flex justify-between pr-0 px-7 border-slate-400 rounded-lg mx-3 mt-8'>
             <div className='w-[40%] py-7 flex items-center justify-center'>
                 <img src={addGroupImage} className='w-[260px] h-[260px]' alt="add group" />
             </div>
               <section className='w-[52%]'>
               <div className='rounded-e-lg bg-white p-5 px-8 pb-12'>
                  <h5 className='text-xl font-semibold'>
                    Cohort Information
                  </h5>
                  <p className='text-xs font-semibold border-b border-gray-300 pb-2 mt-1 mb-3'>
                      Give detailed Information of the cohort 
                  </p>
                  <div className="mb-3">
                        <label className="block font-semibold w-full text-sm mb-1">
                        Would you like to add cohort name in other languages?
                        </label>
                        <div className='flex items-center'>
                            <input
                            type="checkbox"
                            className="mr-2"
                            checked={useMultipleLanguages}
                            onChange={(e) => setUseMultipleLanguages(e.target.checked)}
                            />
                            <label className="text-xs">
                               Yes, I want to add multiple languages
                            </label>
                        </div>
                    </div>
                    {useMultipleLanguages ? (
                                <HorizontalTab 
                                  withPadding={false} tabsData={tabsData} 
                                />
                           ) :  <HorizontalTab  
                                    withPadding={false} tabsData={tabsData.slice(0,1)}
                                  />
                    } 
                    <div className="mt-5 text-sm justify-between flex items-center">
                        Enable Teams  
                        <Switch
                            //value={enableAffiliate}
                            height={20}
                            width={40}
                            handleDiameter={18}
                            checked={status}
                            onChange={() => setStatus(!status)}
                        />
                    </div>
                    <button onClick={createCohort} className='btn bg-primary-blue w-[90%] mx-auto text-center mt-5'>
                        Edit Cohort Information
                    </button>
              </div>
        </section>
      </section>
    </>
  )
}

export default CohortInformation