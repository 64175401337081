import React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type HeaderType = {
    setTitle: React.Dispatch<React.SetStateAction<string>>
    title: string
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Header:React.FC<HeaderType> = ({title, setTitle, setOpen}) : JSX.Element => {

 const changeTitle = (event: React.ChangeEvent<HTMLHeadingElement>) : void => {
     setTitle(event.target.innerText)
  }

  return (
    <div className='bg-primary-blue text-white mt-4 py-2 flex justify-between items-center px-3'>
     <div className='flex items-center'> 
        <button className='bg-transparent text-sm text-white'>
            Menu <i className='bi bi-chevron-down ml-2 text-white'></i>
        </button>
        <div className='w-[1px] h-[40px] bg-white ml-5'></div>
        <h3 
           contentEditable onInput={changeTitle}
          className='text-white text-sm font-semibold ml-5'
         >
             Untitled Report
        </h3>
     </div>
     <div>
        <button 
          className='text-white border text-xs rounded-lg py-2 bi bi-cursor px-5 border-white'
          onClick={() => setOpen(true)}
        >
             &nbsp;Share Report
        </button>
        <button className='btn-light pt-2 pb-2 pl-8 pr-8 ml-3 bg-[#E8EDF4]'>
             Preview
        </button>
        <button className='btn pt-2 pb-2 pl-8 pr-8 ml-3 bg-[#CC7300]'>
             Save as draft
        </button>
        <DropdownMenu.Root>
         <DropdownMenu.Trigger asChild>
        <button  className='btn pt-2 pb-2 pl-8 font-semibold pr-8 ml-3 bg-[#FF9100]' aria-label="Customise options">
             Publish <i className='bi bi-chevron-down ml-2'></i>
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
          <DropdownMenu.Item
              className="DropdownMenuItem text-left hover:text-white text-gray-900"
          >
            <i className='bi bi-printer pr-1' /> Print Report
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator" />
          <DropdownMenu.Item 
             className="DropdownMenuItem text-left hover:text-white"
             
          >
            <i className='bi bi-file-earmark-pdf pr-1' /> Save as PDF
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator" />
          <DropdownMenu.Item 
             className="DropdownMenuItem text-left hover:text-white"
             
          >
            <i className='bi bi-file-earmark-word pr-1' /> Save as Doc
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
       </DropdownMenu.Root>
     </div>
    </div>
  )
}

export default Header