import React from 'react'
import ProfileInformation from './components/ProfileInformation'
import BusinessSetup from './components/BusinessSetup'
import { useEffect } from "react";
import { Tab, initTE, } from "tw-elements";
import AccountCustomization from './components/AccountCustomization';
import AccountDemoGraphics from './components/AccountDemographics';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';
import { api as auth } from '../../../../../../api/index';
import Loader from '../../../../../../components/loader/Loader';

export type refetchType = <TPageData>(options?: (
                      RefetchOptions & RefetchQueryFilters<TPageData>
                    ) | undefined) => Promise<QueryObserverResult<any, unknown>>

const ProfileAndAccountSetup: React.FC = () : JSX.Element => {

  useEffect( () => initTE({ Tab }) , [] )

  const orgId = JSON.parse(sessionStorage.getItem('user')!)?.org?.id

  const { data: organisationProfile, isLoading, refetch } = useQuery(
    'get organisation setting' , async (): Promise<any> => {
     return await auth.getOrganisationSettings(orgId)
  }, { select: response => response?.data})

  console.log(organisationProfile);

  return (
    <div className='-mt-3'>
      <ul
        className="mb-5 mt-0 flex list-none flex-row flex-wrap border-b-2 border-gray-200 pl-0"
        role="tablist"
        data-te-nav-ref
       >
              <li role="presentation">
                    <a
                      href="#tabs-home"
                      className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[12px] font-medium uppercase leading-tight text-neutral-900 hover:isolate hover:border-transparent hover:bg-neutral-100 data-[te-nav-active]:bg-white  focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-home"
                      data-te-nav-active
                      role="tab"
                      aria-controls="tabs-home"
                      aria-selected="true"
                      > My Profile </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tabs-profile"
                      className="my-2 block data-[te-nav-active]:bg-white border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[12px]  font-medium uppercase leading-tight text-neutral-900 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-profile"
                      role="tab"
                      aria-controls="tabs-profile"
                      aria-selected="false"
                      > Business Information </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tabs-appearance"
                      className="my-2 block data-[te-nav-active]:bg-white border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[12px]  font-medium uppercase leading-tight text-neutral-900 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-appearance"
                      role="tab"
                      aria-controls="tabs-appearance"
                      aria-selected="false"
                      > Dashboard Appearance </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tabs-demographics"
                      className="my-2 block data-[te-nav-active]:bg-white border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[12px]  font-medium uppercase leading-tight text-neutral-900 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-demographics"
                      role="tab"
                      aria-controls="tabs-demographics"
                      aria-selected="false"
                      > Demographics </a>
                  </li>
        </ul>
        <div className="mb-6">
                <div
                  className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-home"
                  role="tabpanel"
                  aria-labelledby="tabs-home-tab"
                  data-te-tab-active
                >
                 <section>
                   {
                     isLoading ? <Loader /> : 
                     <ProfileInformation 
                      isClient profileInformationData={organisationProfile?.rootAccount} 
                      refetch={refetch}
                    />
                    }
                 </section>
                </div>
                <div
                  className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-profile"
                  role="tabpanel"
                  aria-labelledby="tabs-profile-tab">
                     {
                       isLoading ? <Loader /> :
                        <BusinessSetup 
                           refetch={refetch} businessInfoData={organisationProfile?.organization} 
                        /> 
                      }
                  </div>
                  <div
                  className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-appearance"
                  role="tabpanel"
                  aria-labelledby="tabs-appearance-tab">
                     { 
                       isLoading ? <Loader /> : 
                       <AccountCustomization 
                         refetch={refetch} 
                         orgAppearance={organisationProfile?.organizationAppearance}
                       /> 
                    }
                  </div>
                  <div
                  className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-demographics"
                  role="tabpanel"
                  aria-labelledby="tabs-demographics-tab">
                     { isLoading ? <Loader /> : <AccountDemoGraphics /> }
                  </div>
          </div>
    </div>
  )
}

export default ProfileAndAccountSetup