import React, { useState, useRef } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IconContext } from "react-icons";
import { AiFillCheckCircle, AiOutlineUser, AiFillAlert } from "react-icons/ai";
import { Rnd } from "react-rnd";
import { SketchPicker } from "react-color";
import iconList from "../../../../../data/icons";

const ItemType = {
  ICON: "icon",
};

const DraggableIcon = ({ icon }) => {
  const [, drag] = useDrag(() => ({
    type: ItemType.ICON,
    item: { icon },
  }));

  return (
    <div
      ref={drag}
      className="p-2 border rounded cursor-pointer hover:bg-gray-200"
    >
      <IconContext.Provider value={{ size: "24px" }}>
        {React.createElement(icon)}
      </IconContext.Provider>
    </div>
  );
};

const IconEditor = () => {
  const [elements, setElements] = useState([]);
  const [activeElementIndex, setActiveElementIndex] = useState(null);
  const cardRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleIcons, setVisibleIcons] = useState(iconList.slice(0, 50));
  const [selectedIcon, setSelectedIcon] = useState(null);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter icons based on the search query
    const filteredIcons = iconList.filter((icon) =>
      icon.name.toLowerCase().includes(query.toLowerCase())
    );
    setVisibleIcons(filteredIcons);
  };

  const [, drop] = useDrop(() => ({
    accept: ItemType.ICON,
    drop: (item, monitor) => {
      const offset = monitor.getSourceClientOffset();
      if (offset) {
        // Append new element to the array
        setElements((prevElements) => [
          ...prevElements,
          {
            component: item.icon,
            x: offset.x - 50,
            y: offset.y - 50,
            width: 50,
            height: 50,
            color: "#000",
            fontSize: 24,
            rotation: 0, // Rotation value for element
            opacity: 1,
          },
        ]);
      }
    },
  }));

  const updateElementPosition = (index, x, y) => {
    updateElementProperty(index, "position", { x, y });
  };

  const updateElementProperty = (index, key, value) => {
    if (index === null || !elements[index]) return; // Prevent accessing undefined element
    const updatedElements = [...elements];
    if (key === "position") {
      updatedElements[index].x = value.x;
      updatedElements[index].y = value.y;
    } else {
      updatedElements[index][key] = value; // Dynamically update any other property
    }
    setElements(updatedElements);
  };

  const duplicateElement = (index) => {
    const duplicatedElement = { ...elements[index] };
    setElements([...elements, duplicatedElement]);
  };

  const deleteElement = (index) => {
    if (index !== null) {
      setElements(elements.filter((_, i) => i !== index));
      setActiveElementIndex(null);
    }
  };


  const rotateElement = (index, angle) => {
    if (index !== null && elements[index]) {
      const updatedElements = [...elements]; // Clone the array
      const element = updatedElements[index]; // Access the element
      element.rotation = (element.rotation || 0) + angle; // Add angle to rotation, initialize if undefined
      setElements(updatedElements); // Update the state with the modified array
    }
  };

  console.log(selectedIcon, "icon")

  const handleElementClick = (index) => {
    setActiveElementIndex(index);
  };

  const handleDeselect = () => {
    setActiveElementIndex(null);
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar with draggable icons */}
      <div className="w-1/4 p-4 bg-gray-100 border-r">
        <h3 className="mb-4 text-lg font-bold">Icons</h3>
        <input
          type="text"
          placeholder="Search icons..."
          value={searchQuery}
          onChange={handleSearch}
          className="w-full p-2 mb-4 border rounded"
        />
         {/* {!activeElementIndex && ( */}
          <div className="grid grid-cols-3 gap-4">
            {visibleIcons.map((icon, index) => (
              <DraggableIcon
                key={index}
                icon={icon.component}
                onClick={() => setSelectedIcon(icon.component)} // Set selected icon
              />
            ))}
          </div>
        {/* )} */}
        {activeElementIndex !== null && (
          <div className="mt-4 bg-white p-4 shadow-lg rounded-lg">
            <div className="mb-2">
              <label>Font Size:</label>
              <input
                type="number"
                value={elements[activeElementIndex]?.fontSize || 24}
                onChange={(e) =>
                  updateElementProperty(
                    activeElementIndex,
                    "fontSize",
                    parseInt(e.target.value, 10)
                  )
                }
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mt-4">
              <label className="block text-base font-medium text-gray-700 mb-2">
                Icon Opacity
              </label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={elements[activeElementIndex]?.opacity}
                onChange={(e) =>
                  updateElementProperty("opacity", parseFloat(e.target.value))
                }
                className="w-full appearance-none h-2 bg-[#1F456C] rounded-full"
              />
            </div>
            <div className="mb-2">
              <label>Color:</label>
              <SketchPicker
                color={elements[activeElementIndex]?.color || "#000"}
                onChangeComplete={(color) =>
                  updateElementProperty(activeElementIndex, "color", color.hex)
                }
              />
            </div>
            <button
              onClick={() => setSelectedIcon(null)} // Deselect icon
              className="mt-4 p-2 text-red-500"
            >
              Deselect Icon
            </button>
          </div>
        )}
      </div>

      {/* Canvas */}
      <div className="flex-1 relative bg-gray-50" ref={drop}>
        {elements.map((element, index) => (
          <Rnd
            key={index}
            size={{ width: element.width, height: element.height }}
            position={{ x: element.x, y: element.y }}
            onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
            // onClick={() => setActiveElementIndex(index)}
            onClick={() => handleElementClick(index)}
            style={{
              border: activeElementIndex === index ? "1px solid blue" : "none",
              padding: "5px",
              transform: `rotate(${element.rotation}deg)`, // Rotation applied here
            }}
          >
            <div
              className="cursor-pointer"
              style={{
                fontSize: `${element.fontSize}px`,
                color: element.color,
                opacity: element?.opacity,
              }}
            >
              <element.component />
            </div>
            {/* Action Buttons */}
            {activeElementIndex === index && (
              <div
                ref={cardRef}
                className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl p-2"
              >
                {/* Duplicate Button */}
                <button
                  onClick={() => duplicateElement(index)}
                  className="m-1 p-1 text-sm rounded relative group"
                >
                  Duplicate
                </button>
                {/* Delete Button */}
                <button
                  onClick={() => deleteElement(index)}
                  className="m-1 p-1 text-sm rounded relative group text-red-500"
                >
                  Delete
                </button>
                {/* Rotate Buttons */}
                {/* <button
                  onClick={() => rotateElement(index, 15)}
                  className="m-1 p-1 text-sm rounded relative group"
                >
                  Rotate 15°
                </button>
                <button
                  onClick={() => rotateElement(index, -15)}
                  className="m-1 p-1 text-sm rounded relative group"
                >
                  Rotate -15°
                </button> 
                 */}
              </div>
            )}
          </Rnd>
        ))}

        {/* Active Element Controls */}
        {/* {activeElementIndex !== null && elements[activeElementIndex] && (
          <div className="absolute top-4 left-4 bg-white p-4 shadow-lg rounded-lg">
            <div className="mb-2">
              <label>Font Size:</label>
              <input
                type="number"
                value={elements[activeElementIndex]?.fontSize || 24}
                onChange={(e) =>
                  updateElementProperty(
                    activeElementIndex,
                    "fontSize",
                    parseInt(e.target.value, 10)
                  )
                }
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mt-4">
              <label className="block text-base font-medium text-gray-700 mb-2">
                Icon Opacity
              </label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={elements[activeElementIndex]?.opacity}
                onChange={(e) =>
                  updateElementProperty(
                    activeElementIndex,
                    "opacity",
                    parseFloat(e.target.value)
                  )
                }
                className="w-full appearance-none h-2 bg-[#1F456C] rounded-full"
              />
            </div>
            <div className="mb-2">
              <label>Color:</label>
              <SketchPicker
                color={elements[activeElementIndex]?.color || "#000"}
                onChangeComplete={(color) =>
                  updateElementProperty(activeElementIndex, "color", color.hex)
                }
              />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default IconEditor;
