/* eslint-disable no-restricted-globals */
import React, { useState, useMemo } from "react";
import { useQuery, useMutation } from "react-query";
import { api } from "../../../../../api/users";
import Button from "../../../../../components/Buttons/Button";
import Table from "../../../../../components/table/Table";
import Search from "../../../../../components/Inputs/Search";
import Loader from "../../../../../components/loader/Loader";
import BlockChainImg from "../../../../../assets/Blockchain.png";
import CenteredModal from "../../../../../components/Modal/CenteredModal";
import AddTeamModal from "./AddTeamModal";
import EditTeamModal from "./EditTeamModal";
import toast from "react-hot-toast";

const Teams = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { mutateAsync: DeleteTeam } = useMutation(api.deleteTeam);

  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [currentTeam, setCurrentTeam] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const {
    data: teams,
    isLoading,
    refetch,
  } = useQuery(
    ["Teams"],
    ({ queryKey }) => {
      return api.getTeams({ id: user?.org?.id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  const handleDelete = async (teamId) => {
    if (confirm("Are you sure you wish to delete this team")) {
      toast.loading("Processing");
      try {
        const data = await DeleteTeam({ id: user?.org?.id, teamId });
        console.log(data);
        toast.remove();
        toast.success(data?.message);
      } catch ({ response }) {
        // console.log(response);
        toast.remove();
        toast.error(response?.data?.message);
      }
    }
  };

  let columns = React.useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 +
          ((teams?.meta?.page || 1) - 1) * (teams?.meta?.perPage || perPage),
      },
      {
        title: "Team Name",
        selector: "name",
        key: "name",
      },
      {
        title: "Team Description",
        selector: "description",
        key: "description",
      },

      {
        title: "Date Added",
        selector: "createdAt",
        key: "createdAt",
        render: ({ item }) => {
          return (
            <span className="">{`${new Date(item).toLocaleDateString(
              "en-ng"
            )}`}</span>
          );
        },
      },
      {
        title: "Date Updated",
        selector: "updatedAt",
        key: "updatedAt",
        render: ({ item }) => {
          return (
            <span className="">{`${new Date(item).toLocaleDateString(
              "en-ng"
            )}`}</span>
          );
        },
      },
      {
        title: "Action",
        selector: null,
        key: null,
        render: ({ row }) => {
          return (
            <div className="flex">
              <span
                className="bi bi-pencil-square hover:cursor-pointer text-blue-500"
                onClick={() => {
                  setCurrentTeam(row);
                  handleEditModal();
                }}
              />
              <span
                className="bi bi-trash ml-2 text-red-500 hover:cursor-pointer"
                onClick={() => handleDelete(row?.id)}
              />
            </div>
          );
        },
      },
    ],
    [teams, perPage, search, currentPage]
  );

  const tableProps = { isLoading: false, columns, data: teams?.data };

  // console.log(teams);

  return (
    <React.Fragment>
      <CenteredModal
        title={"Add New Team"}
        open={openModal}
        setOpen={handleOpenModal}
      >
        <AddTeamModal handleModal={handleOpenModal} />
      </CenteredModal>
      <CenteredModal
        title={"Edit Team"}
        open={openEditModal}
        setOpen={handleEditModal}
      >
        <EditTeamModal
          currentTeam={currentTeam}
          handleModal={handleEditModal}
        />
      </CenteredModal>
      <section
        className={`p-2  ${
          teams?.data?.length < 5 ? "h-[100vh]" : ""
        }`}
      >
        <h3 className="text-md mt-2">Teams Overview</h3>
        <section className="mt-4 flex justify-between">
          <div className=" w-[350px]">
            <Search
              placeholder={"Search teams..."}
              onChange={setSearch}
            />
          </div>
          <div>
            <Button
              text={"Add New Team"}
              width={"w-[200px]"}
              renderAs="button"
              bgColor={"bg-customBlue"}
              onClick={handleOpenModal}
            />
          </div>
        </section>

        <section className="bg-white border border-gray-200 rounded-lg p-3">
          <h3 className="text-sm mb-3">Total Teams ({teams?.meta?.total})</h3>
          <section className="overflow-x-auto w-full clients-table">
            {!isLoading ? (
              !teams?.data?.length ? (
                <div className="w-full mt-12">
                  <img
                    src={BlockChainImg}
                    alt="no result"
                    className="w-[260px] block mx-auto h-[190px]"
                  />
                  <p className="text-center font-semibold text-xs mt-3 ml-3">
                    No team found.
                  </p>
                </div>
              ) : (
                <Table
                  {...tableProps}
                  style={{
                    overflowX: "auto",
                    width:
                      window.innerWidth > 500 && window.innerWidth < 1200
                        ? "130%"
                        : "115%",
                  }}
                  headerClassName={"border-t border-gray-200"}
                />
              )
            ) : (
              <Loader />
            )}
          </section>
        </section>
        {/* <section className={`mt-1 px-3 rouded-lg bg-white`}>
      <Pagination
        data={clients?.meta}
        perPage={perPage}
        pageNo={currentPage}
        setPageNo={setCurrentPage}
        setPerPage={setPerPage}
      />
</section> */}
      </section>
    </React.Fragment>
  );
};

export default Teams;
