import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ReportEditorProvider } from './hooks/ReportEditorContext'
import ViewReport from './viewReport/ViewReport'
import CreateReport from './createReport/CreateReport'
import TextEditor from './TextEditor/TextEditor'


const ReportRoute: React.FC = () : JSX.Element => {
  return (
    <React.Fragment>
       <ReportEditorProvider>
         <Routes>
            <Route path="/" element={<ViewReport />} />
            <Route path="/create" element={<CreateReport />} />
            <Route path="/editor" element={<TextEditor />} />
        </Routes>
        </ReportEditorProvider>
    </React.Fragment>
  )
}

export default ReportRoute