import React, { lazy, useState } from 'react'
import templateSample from '../../../../../../assets/template.png'
import  Search from  '../../../../../../components/Inputs/Search'

const Templates = () => {

  const [search, setSearch] = useState<string>('')

  return (
    <div className='p-2'>
        <Search
          onChange={setSearch}
          placeholder={"Search ..."}
          className={"w-full mb-3"}
          bgColor={null}
          iconColor={null}
          onClick={null}
          width={'w-full'}
          value={null}
          mb={''}
          showButton={false}
        />
       <img src={templateSample} className='w-[96%] mb-3 block mx-auto h-[130px]' alt="template name" />
    </div>
  )
}

export default Templates