import React, { useState, useRef, useEffect } from "react";
import { Rnd } from "react-rnd";
import { SketchPicker } from "react-color";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { api } from "../../../../../api/certificate";
import PreviewCertificateModal from "./PreviewCertificateModal";

const EditDefaultTemplate = () => {
  const navigate = useNavigate();
  const user = JSON.parse(window.sessionStorage.getItem("user"));

  const [certificateName, setCertificateName] = useState(""); // For the certificate name
  const [description, setDescription] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [elements, setElements] = useState([]);

  const [activeElementIndex, setActiveElementIndex] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(true); // State to control card visibility

  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [signature1, setSignature1] = useState(null);
  const [signature2, setSignature2] = useState(null);

  // ref
  const cardRef = useRef(null); // Ref to track the card container
  const logoInputRef = useRef(null);
  const inputRef = useRef(null);
  const backgroundInputRef = useRef(null);
  const signature1InputRef = useRef(null);
  const signature2InputRef = useRef(null);

  // Save data to sessionStorage
  const saveToSessionStorage = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };

  // Load data from sessionStorage
  const loadFromSessionStorage = (key) => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  };

  useEffect(() => {
    const storedBackgroundImage = loadFromSessionStorage("backgroundImage");
    const storedBackgroundColor = loadFromSessionStorage("backgroundColor");
    const storedElements = loadFromSessionStorage("elements");
    const storedLogo = loadFromSessionStorage("logo");
    const storedSignature1 = loadFromSessionStorage("signature1");
    const storedSignature2 = loadFromSessionStorage("signature2");

    if (storedBackgroundImage) setBackgroundImage(storedBackgroundImage);
    if (storedBackgroundColor) setBackgroundColor(storedBackgroundColor);
    if (storedElements) setElements(storedElements);
    // if (storedLogo) setLogo(storedLogo);
    // if (storedSignature1) setSignature1(storedSignature1);
    // if (storedSignature2) setSignature2(storedSignature2);
  }, []);

  // text element
  const addTextElement = () => {
    const textElement = {
      type: "text",
      content: "This is a new Text block. Change the text.",
      fontSize: 16,
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#000",
      x: 100,
      y: 100,
      editable: true,
      id: "para",
    };
    const updatedElements = [...elements, textElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addFooterTextElement = () => {
    const textElement = {
      type: "text",
      content: "This is a Footer Text block. Change the text.",
      fontSize: 14,
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#000",
      x: 100,
      y: 100,
      editable: true,
      id: "footer-text",
    };
    const updatedElements = [...elements, textElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addTitleElement = () => {
    const titleElement = {
      type: "text",
      content: "Certificate Title",
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "center",
      x: 100,
      y: 50,
      editable: true,
      id: "title",
    };
    const updatedElements = [...elements, titleElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addNameElement = () => {
    const nameElement = {
      type: "text",
      content: "Recipient Name",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "name",
    };
    const updatedElements = [...elements, nameElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addSignatureOneName = () => {
    const nameElement = {
      type: "text",
      content: "Signature First Name",
      fontSize: 12,
      fontStyle: "normal",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "s-name-1",
    };
    const updatedElements = [...elements, nameElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addSignatureTwoName = () => {
    const nameElement = {
      type: "text",
      content: "Signature Second Name",
      fontSize: 12,
      fontStyle: "Bold",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "s-name-2",
    };
    const updatedElements = [...elements, nameElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addCompanyName = () => {
    const nameElement = {
      type: "text",
      content: "Company Name",
      fontSize: 14,
      fontStyle: "normal",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "company-name",
    };
    const updatedElements = [...elements, nameElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addDateOfBirthElement = () => {
    const dobElement = {
      type: "text",
      content: "Recipient Date Of Birth",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "name",
    };
    const updatedElements = [...elements, dobElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addContactElement = () => {
    const contactElement = {
      type: "text",
      content: "Recipient contact number",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "name",
    };
    const updatedElements = [...elements, contactElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addAgeElement = () => {
    const ageElement = {
      type: "text",
      content: "Recipient age",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "name",
    };
    const updatedElements = [...elements, ageElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addFirstName = () => {
    const firstNameElement = {
      type: "text",
      content: "Recipient First Name",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "name",
    };
    const updatedElements = [...elements, firstNameElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addLastName = () => {
    const lastNameElement = {
      type: "text",
      content: "Recipient Last Name",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "name",
    };
    const updatedElements = [...elements, lastNameElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addEmail = () => {
    const emailElement = {
      type: "text",
      content: "Recipient email",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "name",
    };
    const updatedElements = [...elements, emailElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addGender = () => {
    const genderElement = {
      type: "text",
      content: "Recipient gender",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "name",
    };
    const updatedElements = [...elements, genderElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addNationality = () => {
    const nationalityElement = {
      type: "text",
      content: "Recipient nationality",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "name",
    };
    const updatedElements = [...elements, nationalityElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addCountry = () => {
    const countryElement = {
      type: "text",
      content: "Recipient country",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "name",
    };
    const updatedElements = [...elements, countryElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addState = () => {
    const stateElement = {
      type: "text",
      content: "Recipient state",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "state",
    };
    const updatedElements = [...elements, stateElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addCity = () => {
    const cityElement = {
      type: "text",
      content: "Recipient city",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "city",
    };
    const updatedElements = [...elements, cityElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addOcuppation = () => {
    const occupationElement = {
      type: "text",
      content: "Recipient occupation",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "occupation",
    };
    const updatedElements = [...elements, occupationElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addDepartment = () => {
    const departmentElement = {
      type: "text",
      content: "Recipient department",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "department",
    };
    const updatedElements = [...elements, departmentElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addCourseName = () => {
    const courseNameElement = {
      type: "text",
      content: "Recipient course name",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "courseName",
    };
    const updatedElements = [...elements, courseNameElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addCohort = () => {
    const cohortElement = {
      type: "text",
      content: "Recipient cohort",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "cohort",
    };
    const updatedElements = [...elements, cohortElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addLearningPath = () => {
    const learningPathElement = {
      type: "text",
      content: "learning path",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "learningPath",
    };
    const updatedElements = [...elements, learningPathElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };
  const addCourseDuration = () => {
    const courseDurationElement = {
      type: "text",
      content: "course duration",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "courseDuration",
    };
    const updatedElements = [...elements, courseDurationElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addCourseGrade = () => {
    const courseGradeElement = {
      type: "text",
      content: "course grade",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "courseGrade",
    };
    const updatedElements = [...elements, courseGradeElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addCertificateNumber = () => {
    const certificateNumberElement = {
      type: "text",
      content: "certificate number",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "certificateNumber",
    };
    const updatedElements = [...elements, certificateNumberElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addDateOfIssue = () => {
    const dateOfIssueElement = {
      type: "text",
      content: "date of issue",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "dateOfIssue",
    };
    const updatedElements = [...elements, dateOfIssueElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const addValidUntil = () => {
    const validUntilElement = {
      type: "text",
      content: "valid until",
      fontSize: 20,
      fontStyle: "italic",
      textAlign: "center",
      x: 100,
      y: 100,
      editable: true,
      id: "validUntil",
    };
    const updatedElements = [...elements, validUntilElement];
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  // end of text element

  // Image Upload
  const addLogoElement = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const updatedElements = [
        ...elements,
        {
          type: "logo",
          src: e.target.result,
          width: 100,
          height: 100,
          x: 150,
          y: 150,
          id: "logo",
        },
      ];
      setElements(updatedElements);
      saveToSessionStorage("elements", updatedElements);
      setLogo(e.target.result);
      // saveToSessionStorage("logo", e.target.result);
    };
    if (file) reader.readAsDataURL(file);
  };
  const addSignatureOne = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const updatedElements = [
        ...elements,
        {
          type: "sign-1",
          src: e.target.result,
          width: 150,
          height: 50,
          x: 50,
          y: 300,
          id: "sign-1",
        },
      ];
      setElements(updatedElements);
      saveToSessionStorage("elements", updatedElements);
      setSignature1(e.target.result);
      // saveToSessionStorage("signature1", e.target.result);
    };
    if (file) reader.readAsDataURL(file);
  };

  const addSignatureTwo = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const updatedElements = [
        ...elements,
        {
          type: "sign-2",
          src: e.target.result,
          width: 150,
          height: 50,
          x: 50,
          y: 300,
          id: "sign-2",
        },
      ];
      setElements(updatedElements);
      saveToSessionStorage("elements", updatedElements);
      setSignature2(e.target.result);
      // saveToSessionStorage("signature1", e.target.result);
    };
    if (file) reader.readAsDataURL(file);
  };

  // Function to handle background image change
  const handleBackgroundImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setBackgroundImage(e.target.result);
      saveToSessionStorage("backgroundImage", e.target.result); // Save background image to sessionStorage
    };
    if (file) reader.readAsDataURL(file);
  };

  const handleBackgroundColorChange = (color) => {
    setBackgroundColor(color.hex);
    saveToSessionStorage("backgroundColor", color.hex);
  };
  // End of image upload

  const addLine = () => {
    setElements((prev) => [
      ...prev,
      {
        type: "line",
        width: 200,
        height: 2,
        x: 100,
        y: 200,
        backgroundColor: "#000",
        id: "line",
      },
    ]);
  };

  const updateActiveElement = (key, value) => {
    const updatedElements = [...elements];
    updatedElements[activeElementIndex] = {
      ...updatedElements[activeElementIndex],
      [key]: value,
    };
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements); // Save updated elements to sessionStorage
  };

  const updateElementPosition = (index, x, y) => {
    const updatedElements = [...elements];
    updatedElements[index] = { ...updatedElements[index], x, y };
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  const updateElementSize = (index, width, height) => {
    const updatedElements = [...elements];
    updatedElements[index] = { ...updatedElements[index], width, height };
    setElements(updatedElements);
    saveToSessionStorage("elements", updatedElements);
  };

  // Function to delete an element
  const deleteElement = (index) => {
    const elements = JSON.parse(sessionStorage.getItem("elements"));
    elements.splice(index, 1);
    sessionStorage.setItem("elements", JSON.stringify(elements));
    setElements((prev) => prev.filter((_, i) => i !== index));
  };

  // Function to duplicate an element
  const duplicateElement = (index) => {
    setElements((prev) => [
      ...prev,
      { ...prev[index], x: prev[index].x + 10, y: prev[index].y + 40 },
    ]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setIsCardOpen(false); // Close the card when clicking outside
      }
    };

    if (isCardOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup the listener
    };
  }, [isCardOpen]);

  const renderElement = (element, index) => {
    if (element.type === "text") {
      return (
        <Rnd
          key={index}
          size={{ width: "auto", height: "auto" }}
          position={{ x: element.x, y: element.y }}
          onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
          onClick={() => {
            setActiveElementIndex(index);
            setIsCardOpen(true); // Re-open the card when clicking on an element
            // Delay to ensure the input is focused before setting the cursor
            setTimeout(() => {
              if (inputRef.current) {
                const range = document.createRange();
                const selection = window.getSelection();
                range.selectNodeContents(inputRef.current);
                range.collapse(false); // Set the cursor to the end
                selection.removeAllRanges();
                selection.addRange(range);
              }
            }, 5000); // Adding a 0ms timeout to allow the focus event to complete
          }}
        >
          <div
            className="cursor-pointer"
            style={{
              fontSize: `${element.fontSize}px`,
              color: element.color,
              fontWeight: element.fontWeight,
              fontStyle: element.fontStyle,
              textAlign: element.textAlign,
              // border: activeElementIndex === index ? "1px solid blue" : "none",
              padding: "5px",
            }}
            contentEditable={element.editable}
            suppressContentEditableWarning={true}
            ref={inputRef}
            onInput={(e) => {
              // Store the current caret position
              const selection = window.getSelection();
              const range = selection.getRangeAt(0);
              const caretOffset = range.startOffset;

              // Update the element content
              updateActiveElement("content", e.currentTarget.textContent);

              // Restore caret position after update
              const newRange = document.createRange();
              const newSelection = window.getSelection();
              newRange.setStart(e.currentTarget.firstChild, caretOffset);
              newRange.collapse(true);
              newSelection.removeAllRanges();
              newSelection.addRange(newRange);
            }}

            // onInput={(e) =>
            //   updateActiveElement("content", e.currentTarget.textContent)
            // }
          >
            {element.content}
          </div>
          {isCardOpen && activeElementIndex === index && (
            <div
              ref={cardRef}
              className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl"
            >
              <button
                onClick={() => duplicateElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                {/* Tooltip for Duplicate Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Duplicate
                </div>
              </button>

              <button
                onClick={() => deleteElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                {/* Tooltip for Delete Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Delete
                </div>
              </button>
            </div>
          )}
        </Rnd>
      );
    } else if (element.type === "logo") {
      return (
        <Rnd
          key={index}
          size={{ width: element.width, height: element.height }}
          position={{ x: element.x, y: element.y }}
          onResizeStop={(e, direction, ref, delta, position) => {
            updateElementSize(index, ref.offsetWidth, ref.offsetHeight);
            updateElementPosition(index, position.x, position.y);
          }}
          onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
          onClick={() => {
            setActiveElementIndex(index);
            setIsCardOpen(true); // Re-open the card when clicking on an element
          }}
        >
          <img
            src={element.src}
            style={{
              width: "100%",
              height: "100%",
              // border: activeElementIndex === index ? "1px solid blue" : "none",
            }}
            alt="logo"
          />
          {isCardOpen && activeElementIndex === index && (
            <div
              ref={cardRef}
              className="absolute right-0 top-20 flex bg-white border rounded-lg shadow-2xl"
            >
              <button
                onClick={() => duplicateElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                {/* Tooltip for Duplicate Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Duplicate
                </div>
              </button>

              <button
                onClick={() => deleteElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                {/* Tooltip for Delete Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Delete
                </div>
              </button>
            </div>
          )}
        </Rnd>
      );
    } else if (element.type === "sign-1") {
      return (
        <Rnd
          key={index}
          size={{ width: element.width, height: element.height }}
          position={{ x: element.x, y: element.y }}
          onResizeStop={(e, direction, ref, delta, position) => {
            updateElementSize(index, ref.offsetWidth, ref.offsetHeight);
            updateElementPosition(index, position.x, position.y);
          }}
          onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
          onClick={() => {
            setActiveElementIndex(index);
            setIsCardOpen(true); // Re-open the card when clicking on an element
          }}
        >
          <img
            src={element.src}
            style={{
              width: "100%",
              height: "100%",
              // border: activeElementIndex === index ? "1px solid blue" : "none",
            }}
            alt="Signature 1"
          />
          {isCardOpen && activeElementIndex === index && (
            <div
              ref={cardRef}
              className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl"
            >
              <button
                onClick={() => duplicateElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                {/* Tooltip for Duplicate Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Duplicate
                </div>
              </button>

              <button
                onClick={() => deleteElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                {/* Tooltip for Delete Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Delete
                </div>
              </button>
            </div>
          )}
        </Rnd>
      );
    } else if (element.type === "sign-2") {
      return (
        <Rnd
          key={index}
          size={{ width: element.width, height: element.height }}
          position={{ x: element.x, y: element.y }}
          onResizeStop={(e, direction, ref, delta, position) => {
            updateElementSize(index, ref.offsetWidth, ref.offsetHeight);
            updateElementPosition(index, position.x, position.y);
          }}
          onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
          onClick={() => {
            setActiveElementIndex(index);
            setIsCardOpen(true); // Re-open the card when clicking on an element
          }}
        >
          <img
            src={element.src}
            style={{
              width: "100%",
              height: "100%",
              border: activeElementIndex === index ? "1px solid blue" : "none",
            }}
            alt="Signature 2"
          />
          {isCardOpen && activeElementIndex === index && (
            <div
              ref={cardRef}
              className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl"
            >
              <button
                onClick={() => duplicateElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                {/* Tooltip for Duplicate Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Duplicate
                </div>
              </button>

              <button
                onClick={() => deleteElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                {/* Tooltip for Delete Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Delete
                </div>
              </button>
            </div>
          )}
        </Rnd>
      );
    } else if (element.type === "line") {
      return (
        <Rnd
          key={index}
          size={{ width: element.width, height: element.height }}
          position={{ x: element.x, y: element.y }}
          onResizeStop={(e, direction, ref, delta, position) => {
            updateElementSize(index, ref.offsetWidth, ref.offsetHeight);
            updateElementPosition(index, position.x, position.y);
          }}
          onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
          onClick={() => {
            setActiveElementIndex(index);
            setIsCardOpen(true); // Re-open the card when clicking on an element
          }}
        >
          <div
            style={{
              backgroundColor: element.backgroundColor,
              width: "100%",
              height: "100%",
              border: activeElementIndex === index ? "1px solid blue" : "none",
            }}
          />
          {isCardOpen && activeElementIndex === index && (
            <div
              ref={cardRef}
              className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl"
            >
              <button
                onClick={() => duplicateElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                {/* Tooltip for Duplicate Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Duplicate
                </div>
              </button>

              <button
                onClick={() => deleteElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                {/* Tooltip for Delete Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Delete
                </div>
              </button>
            </div>
          )}
        </Rnd>
      );
    }

    return null;
  };

  const createPayload = async () => {
    const nameElement = elements.find((el) => el.id === "name");
    const titleElement = elements.find((el) => el.id === "title");
    const paraElement = elements.find((el) => el.id === "para");
    const logoElement = elements.find((el) => el.id === "logo");
    const signatureOneNameElement = elements.find((el) => el.id === "s-name-1");
    const signatureTwoNameElement = elements.find((el) => el.id === "s-name-2");
    const footerElement = elements.find((el) => el.id === "footer-text");
    const companyElement = elements.find((el) => el.id === "company-name");

    const payload = {
      name: certificateName,
      description: description,
      createdBy: user?.userId,
      jsonBody: JSON.stringify({
        name: nameElement ? nameElement : null,
        title: titleElement ? titleElement : null,
        paragraph: paraElement ? paraElement : null,
        logo: logo ? logo : null,
        backimage: backgroundImage,
        bgColor: backgroundColor,
        signature1: signature1,
        signature2: signature2,
        signature_1_name: signatureOneNameElement
          ? signatureOneNameElement
          : null,
        signature_2_name: signatureTwoNameElement
          ? signatureTwoNameElement
          : null,
        company_name: companyElement ? companyElement.content : null,
        footer_name: footerElement ? footerElement.content : null,
        recipient_first_name: "",
        recipient_last_name: "",
        recipient_email: "",
        recipient_contact: "",
        recipient_gender: "",
        recipient_age: "",
        recipient_dob: "",
        recipient_nationality: "",
        recipient_country: "",
        recipient_state: "",
        recipient_city: "",
        recipient_occupation: "",
        recipient_department: "",
        course_name: "",
        cohort: "",
        learning_path: "",
        course_duration: "",
        course_grade: "",
        certificate_number: "",
        date_of_issue: "",
        valid_until: "",
        elements: elements,
      }),
      status: "active",
    };

    try {
      toast.loading("Loading...");
      const response = await api.createCertificate({
        orgId: user?.org?.id,
        payload,
      });
      toast.remove();
      toast.success(response.message);
      navigate("/admin/certificate/certificates");
      sessionStorage.removeItem("elements");
      sessionStorage.removeItem("backgroundImage");
    } catch (error) {
      toast.remove();
      toast.error(`Error sending email certificate to the backend: ${error}`);
    }
  };

  return (
    <div className="w-full  p-5 sm:p-10">
      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => {
            navigate(-1);
            sessionStorage.removeItem("elements");
            sessionStorage.removeItem("backgroundImage");
          }}
          className={`flex items-center justify-center gap-1  font-semibold 
                       bg-customBlue
                     text-white py-2 px-6 rounded-lg text-sm`}
        >
          <i className="bi bi-arrow-left text-white "></i>
          Back
        </button>
      </div>
      <div>
        <div className="mb-4">
          <label className="block text-base font-medium text-gray-400 mb-2">
            Certificate Name
          </label>
          <input
            type="text"
            value={certificateName}
            onChange={(e) => setCertificateName(e.target.value)}
            placeholder="Enter certificate name"
            className="w-full border rounded-lg  py-3 px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primaryLight focus:border-transparent"
          />
        </div>
        <div className="mb-4">
          <label className="block text-base font-medium text-gray-400 mb-2">
            Description
          </label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter  description"
            className="w-full border rounded-lg  py-3 px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primaryLight focus:border-transparent"
          />
        </div>
      </div>
      <div className="flex gap-4 justify-end mt-10">
        <button
          className="px-2 py-2 bg-white text-primary border border-primary hover:bg-primary hover:text-white rounded mb-6"
          onClick={() => {
            setOpenPreviewModal(true);
          }}
        >
          <i class="bi bi-eye-fill mr-1"></i>
          Preview
        </button>
        <button
          className="px-4 py-2 bg-customBlue text-white rounded mb-6"
          onClick={createPayload}
        >
          Create Certificate
        </button>
      </div>

      <div className="flex gap-10">
        <div className="w-full border border-gray-300 p-2 rounded-lg">
          <div
            className="relative h-screen w-full"
            style={{
              backgroundColor: backgroundImage
                ? "transparent"
                : backgroundColor,
              backgroundImage: backgroundImage
                ? `url(${backgroundImage})`
                : "none",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {elements?.map((element, index) => renderElement(element, index))}
          </div>
        </div>
        <div className="w-2/5 border rounded-lg shadow-lg bg-gray-200 p-2 h-fit">
          <div className="flex mb-6">
            <div className="space-x-4">
              {/* Fixed Button */}
              <button
                className={`${
                  activeButton === "fixed"
                    ? "bg-blue-700"
                    : "bg-blue-500 hover:bg-blue-700"
                } text-white py-2 px-4 rounded`}
                onClick={() =>
                  setActiveButton(activeButton === "fixed" ? null : "fixed")
                }
              >
                Fixed
              </button>

              {/* Placeholder Button */}
              <button
                className={`${
                  activeButton === "placeholder"
                    ? "bg-green-700"
                    : "bg-green-500 hover:bg-green-700"
                } text-white py-2 px-4 rounded`}
                onClick={() =>
                  setActiveButton(
                    activeButton === "placeholder" ? null : "placeholder"
                  )
                }
              >
                Other Placeholder
              </button>
            </div>
          </div>
          {activeButton === "fixed" && (
            <div className=" grid grid-cols-3 gap-4  ">
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addTextElement}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-paragraph text-3xl"></i>
                    <span>Para</span>
                  </div>
                </button>
              </div>
              <div className="col-span-2">
                <button
                  className={`  2xl:w-40 h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded transition-shadow duration-300 shadow-lg 
               hover:shadow-xl `}
                  onClick={() => {
                    backgroundInputRef.current.click();
                  }}
                >
                  <div className="flex flex-col">
                    <i className="bi bi-image text-3xl"></i>
                    <span>Background Image</span>
                  </div>
                </button>

                <input
                  type="file"
                  ref={backgroundInputRef}
                  className="hidden"
                  onChange={handleBackgroundImageChange}
                />
              </div>

              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addTitleElement}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Title</span>
                  </div>
                </button>
              </div>

              <div className="col-span-2">
                <button
                  className=" flex  items-center text-base px-4 py-2 bg-white text-black rounded h-16"
                  onClick={() => signature1InputRef.current.click()}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-image text-3xl"></i>
                    <span className="text-base"> Upload Signature 1</span>
                  </div>
                </button>

                <input
                  type="file"
                  ref={signature1InputRef}
                  className="hidden"
                  onChange={addSignatureOne}
                />
              </div>

              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addNameElement}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Name</span>
                  </div>
                </button>
              </div>
              <div className="col-span-2">
                <button
                  className=" flex  items-center text-base px-4 py-2 bg-white text-black rounded h-16"
                  onClick={() => {
                    signature2InputRef.current.click();
                  }}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-image text-3xl"></i>
                    <span className="text-base"> Upload Signature 2</span>
                  </div>
                </button>

                <input
                  type="file"
                  ref={signature2InputRef}
                  className="hidden"
                  onChange={addSignatureTwo}
                />
              </div>
              <div>
                <button
                  className="flex  items-center text-base px-4 py-2 bg-white text-black rounded h-16"
                  onClick={() => {
                    logoInputRef.current.click();
                  }}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-image text-3xl"></i>
                    <span>Logo</span>
                  </div>
                </button>

                <input
                  type="file"
                  ref={logoInputRef}
                  onChange={addLogoElement}
                  className="hidden"
                />
              </div>

              <div className="col-span-2">
                <button
                  className="col-span-2 h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={() => setShowColorPicker(!showColorPicker)}
                >
                  Background Color
                </button>
                {showColorPicker && (
                  <SketchPicker
                    color={backgroundColor}
                    onChange={handleBackgroundColorChange}
                  />
                )}
              </div>

              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addLine}
                >
                  Line
                </button>
              </div>

              <div className="col-span-2">
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addSignatureOneName}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Signature 1 Name</span>
                  </div>
                </button>
              </div>
              <div className="">
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addFooterTextElement}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>F Text</span>
                  </div>
                </button>
              </div>
              <div className="col-span-2">
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addSignatureTwoName}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Signature 2 Name</span>
                  </div>
                </button>
              </div>

              <div className="col-span-2">
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addCompanyName}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Company Name</span>
                  </div>
                </button>
              </div>
            </div>
          )}
          {activeButton === "placeholder" && (
            <div className="flex flex-wrap gap-4">
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addFirstName}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>First Name</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addLastName}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Last Name</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addDateOfBirthElement}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Date Of Birth</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addContactElement}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Contact</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addAgeElement}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Age</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addEmail}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Email</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addGender}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Gender</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addNationality}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Nationality</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addCountry}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Country</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addState}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>State</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addCity}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>City</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addOcuppation}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Occupation</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addDepartment}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Department</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addCourseName}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Course Name</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addCohort}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Cohort</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addLearningPath}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Learning Path</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addCourseDuration}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Course Duration</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addCourseGrade}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Course Grade</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addCertificateNumber}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Certificate Number</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addDateOfIssue}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Date Of Issue</span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  className="h-16 flex justify-center items-center text-base px-4 py-2 bg-white text-black rounded"
                  onClick={addValidUntil}
                >
                  <div className="flex flex-col">
                    <i class="bi bi-fonts text-3xl"></i>
                    <span>Valid Until</span>
                  </div>
                </button>
              </div>
            </div>
          )}

          <div className="mt-6">
            {activeElementIndex !== null &&
              elements[activeElementIndex]?.type === "text" && (
                <div>
                  <div>
                    <label className="block text-base font-medium text-gray-700 mb-2">
                      Font Size
                    </label>
                    <input
                      type="number"
                      placeholder="Font Size"
                      value={elements[activeElementIndex].fontSize}
                      onChange={(e) =>
                        updateActiveElement("fontSize", e.target.value)
                      }
                      className="px-2 py-1 rounded-md w-full"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-base font-medium text-gray-700 mb-2">
                      Font Color
                    </label>
                    <input
                      type="color"
                      placeholder="Font Color"
                      value={elements[activeElementIndex].color}
                      onChange={(e) =>
                        updateActiveElement("color", e.target.value)
                      }
                      className="px-2 py-1 rounded-md w-full"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-base font-medium text-gray-700 mb-2">
                      Font Weight
                    </label>

                    <select
                      value={elements[activeElementIndex].fontWeight}
                      onChange={(e) =>
                        updateActiveElement("fontWeight", e.target.value)
                      }
                      className="px-2 py-1 rounded-md w-full"
                    >
                      <option value="normal">Normal</option>
                      <option value="bold">Bold</option>
                    </select>
                  </div>

                  <div className="mt-4">
                    <label className="block text-base font-medium text-gray-700 mb-2">
                      Font Style
                    </label>
                    <select
                      value={elements[activeElementIndex].fontStyle}
                      onChange={(e) =>
                        updateActiveElement("fontStyle", e.target.value)
                      }
                      className="px-2 py-1 rounded-md w-full"
                    >
                      <option value="normal">Normal</option>
                      <option value="italic">Italic</option>
                    </select>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <PreviewCertificateModal
        openPreviewModal={openPreviewModal}
        setOpenPreviewModal={setOpenPreviewModal}
      />
    </div>
  );
};

export default EditDefaultTemplate;
