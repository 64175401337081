import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { learners as api } from "../../../../../../api/learners";
import { useQuery } from "react-query";

import { ScaleLoader } from "react-spinners";
import EmptyPlaceHolder from "../../../../../../components/EmptyPlaceHolder";
import Pagination from "../../../../../../components/pagination/Pagination";
import Table from "../../../../../../components/table/Table";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

const OverallHistory = () => {
  const activeCohort = JSON.parse(sessionStorage.getItem("active-cohort"));
  const { id } = useParams();

  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const debouncedSearch = useMemo(() => debounce(setSearch, 300), []);

  const { data: history, isLoading } = useQuery(
    ["overall-data", search],
    async () => {
      const response = await api.getLearnerOverAllCohortInfo({
        learnerId: id,
        cohortId: activeCohort?.cohort?.id,
        search
      });
      return response.data;
    },
    {
      staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      cacheTime: 30 * 60 * 1000,
      select: (data) => data?.learningPaths,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 
          
      },
      {
        title: "Learning Path  Name",
        selector: "name",
        key: "name",

        render: ({ row }) => (
          <span> {row?.name}</span>
        
        ),
      },
      {
        title: "Start Date",
        selector: null,
        key: "startDate",
        render: ({ row }) => (
          <span className="">
            {new Date(row?.startDate).toLocaleDateString(
              "en-ng"
            )}
          </span>
        ),
      },
      {
        title: "End Date",
        selector: null,
        key: "endDate",
        render: ({ row }) => (
          <span className="">
            {new Date(row?.endDate).toLocaleDateString(
              "en-ng"
            )}
          </span>
        ),
      },
    ],
    []
  );

  const tableProps = {
    isLoading: isLoading,
    columns,
    data: history || [],
  };

  return (
    <div>
      OverallHistory
      <section className="text-left pb-3 px-5">
        <div className="flex items-center justify-between mt-2 mb-4">
          <h4 className="text-sm mt-3 font-semibold">
            All ({history?.length})
          </h4>
          {/* <div className="w-[300px]">
            <input
              type="search"
              className="input-style bg-primaryGray w-[300px] mt-4"
              placeholder="&#128269; Search learners...."
              onChange={(event) => debouncedSearch(event.target.value)}
            />
          </div> */}
        </div>
        <section>
          <Table
            {...tableProps}
            style={{
              overflowX: "auto",
            }}
            headerClassName={"border-t bg-gray-100"}
          />
        </section>
        {/* <section className="mt-1 px-3 rounded-lg bg-white">
          <Pagination
            data={history?.meta}
            perPage={perPage}
            pageNo={currentPage}
            setPageNo={setCurrentPage}
            setPerPage={setPerPage}
          />
        </section> */}
      </section>
    </div>
  );
};

export default OverallHistory;
