import React, { useState } from 'react';
import Step from './components/Step';
import CohortInformation from './components/CohortInformation';
import { v4 } from 'uuid';
import { cohortInvitation } from './components/templates/cohortInvitation';
import { teamInvitation } from './components/templates/teamInvitation';
import { welcomePage } from './components/templates/welcomePage';
import AddTeams from './components/AddTeams';
import AddLearningPath from './components/AddLearningPath';
import AddLearnersToCohort from './components/AddLearnersToCohort';
import CohortInvitationEmail from './components/CohortInvitationEmail';
import TeamInvitation from './components/TeamInvitation';
import CohortWelcomeMessage from './components/CohortWelcomeMessage';

const CreateCohort: React.FC = () => {

 const [currentStep, setCurrentStep] = useState<number>(1)
 const [enableTeams, setEnableTeams] = useState<boolean>(false)
 const [cohortId, setCohortId] = useState<string | null>(null)
 const [teams, setTeams] = useState<{ id: string, name: string }[]>([ { id: v4 (), name: '' } ])
 const [team, setTeam] = useState(null)
 const [status, setStatus] = useState<boolean>(true)
 const [enableLeaderBoard, setEnableLeaderBoard] = useState<boolean>(true)
 const [ mapLearnerMethod, setMapLearnerMethod ] = useState<number>(1)
 const [value, setValue] = useState<number>(1)
 const [selectedTeams, setSelectedTeams] = useState<any[]>([])
 const [selectedJourney, setSelectedJourney] = useState(null)
 const [learners, setLearners] = useState([])
 const [defaultLearners, setDefaultLearners] = useState([])
 const [checked, setChecked] = useState<boolean>(true)
 const [duplicateLearners, setDuplicateLearners] = useState([])
 const [invalidLearners, setInvalidLearners] = useState([])
 const [content, setContent] = useState<string>(cohortInvitation)
 const [teamInvitationContent, setTeamInvitationContent] = useState<string>(teamInvitation)
 const [welcomePageContent, setWelcomePageContent] = useState<string>(welcomePage)

 const increment = () : void => {
    setValue(value + 1 )
 }

 const decrement = () : void =>  {
    if(value > 1 ) {
        setValue(value - 1 )
    }
 }

 const back = () => {
    if( currentStep > 1 && currentStep <= 6){
     setCurrentStep(currentStep - 1)
    }
 }

  return (
    <div className='p-5'>
        <header className='flex justify-between'>
          <nav className='mt-1 flex items-center'>
                Step {currentStep} of 6 &nbsp;&nbsp; &nbsp;&nbsp; 
                <Step color={ currentStep >= 1 ? 'bg-primary' : null } /> 
                <Step color={ currentStep >= 2 ? 'bg-primary' : null } /> 
                <Step color={ currentStep >= 3 ? 'bg-primary' : null } />
                <Step color={ currentStep >= 4 ? 'bg-primary' : null } />
                <Step color={ currentStep >= 5 ? 'bg-primary' : null } />
                <Step color={ currentStep >= 6 ? 'bg-primary' : null } />
           </nav>
           <button onClick={back} className='btn bg-primary-blue pl-8 pr-8'>
              Back
           </button>
        </header>
         { (currentStep === 1 && !enableTeams) &&
            <CohortInformation 
            enableTeams={enableTeams} setEnableTeams={setEnableTeams}
            setCurrentStep={setCurrentStep} cohortId={cohortId}
            setCohortId={setCohortId} status={status} setStatus={setStatus}
        />}
        {
                (currentStep === 1 && enableTeams) &&
                 <AddTeams 
                     setCurrentStep={setCurrentStep} status={status} cohortId={cohortId}
                     setStatus={setStatus} teams={teams} setTeams={setTeams}
                     team={team} setTeam={setTeam} enableLeaderBoard={enableLeaderBoard}
                     setEnableLeaderBoard={setEnableLeaderBoard} value={value}
                     mapLearnerMethod={mapLearnerMethod} increment={increment}
                     setMapLearnerMethod={setMapLearnerMethod} decrement={decrement}
                     selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams}
                />
         }
         {
            currentStep === 2 &&
            <AddLearningPath 
               setCurrentStep={setCurrentStep}
               selectedJourney={selectedJourney}
               setSelectedJourney={setSelectedJourney}
               cohortId={cohortId}
            />
         }
         {
            currentStep === 3 &&
            <AddLearnersToCohort
               setCurrentStep={setCurrentStep} cohortId={cohortId} learners = {learners}
               setLearners={setLearners} defaultLearners ={defaultLearners}
               setDefaultLearners={setDefaultLearners} checked={checked}
               setChecked={setChecked} duplicateLearners={duplicateLearners}
               setDuplicateLearners={setDuplicateLearners} invalidLearners={invalidLearners}
               setInvalidLearners={setInvalidLearners}
            />
         }
         {
                currentStep === 4 &&
                 <CohortInvitationEmail 
                    setCurrentStep={setCurrentStep}
                    cohortId={cohortId}
                    content={content}
                    setContent={setContent}
                />
              }
              {
                currentStep === 5 &&
                 <TeamInvitation
                    setCurrentStep={setCurrentStep}
                    cohortId={cohortId}
                    content={teamInvitationContent}
                    setContent={setTeamInvitationContent}
                />
              }
              {
                currentStep === 6 &&
                 <CohortWelcomeMessage
                    setCurrentStep={setCurrentStep} 
                    cohortId={cohortId}
                    content={welcomePageContent}
                    setContent={setWelcomePageContent}
                />
              }
    </div>
  )
}

export default CreateCohort