import React from 'react'
import managerIcon from '../../../../../../assets/flat-color-icons_manager.png'

const UserSelect = ({name, selected, onSelect}) => {
  return (
    <div className={`flex ${ 'justify-between' } items-center border  mb-3 border-gray-200 rounded-lg py-2 px-3`}>
         <div className='flex items-center'>
            <span className={`bi bi-check-square hover:cursor-pointer ${ 
                               selected ? 'text-primary' : 'text-gray-600' 
                               } text-lg`}
             onClick={onSelect}
             >
            </span>
            <p className='text-xs pl-5'>
                {name}
            </p>
         </div>
           <img src={managerIcon} className='w-[20px] h-[20px]' alt="manager" />
    </div>
  )
}

export default UserSelect