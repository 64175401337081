import createApiClient from "./api";

const client = createApiClient();

export const api = {
  testConnection: ({ orgId, payload }) =>
    client
      .post(`admin/org/${orgId}/emails/test-connection`, payload)
      .then(({ data }) => data),

  saveConnection: ({ orgId, payload }) =>
    client
      .post(`admin/org/${orgId}/emails/save-connection`, payload)
      .then(({ data }) => data),
  sendTestEmail: ({ orgId, payload }) =>
    client
      .post(`admin/org/${orgId}/emails/send-test-email`, payload)
      .then(({ data }) => data),

  getAllEmailTemplates: ({ orgId, search, perPage, currentPage }) =>
    client
      .get(
        `admin/org/${orgId}/emails/templates?search=${search}&perPage=${perPage}&page=${currentPage}`
      )
      .then(({ data }) => data),

  createEmailTemplate: ({ orgId, payload }) =>
    client
      .post(`admin/org/${orgId}/emails/templates`, payload)
      .then(({ data }) => data),

  getEmail: ({ orgId, templateId }) =>
    client
      .get(`admin/org/${orgId}/emails/templates/${templateId}`)
      .then(({ data }) => data),
  getEmailSettings: ({ orgId, templateId }) =>
    client
      .get(`admin/org/${orgId}/emails/connection-settings`)
      .then(({ data }) => data),

  deleteTemplate: ({ orgId, templateId }) =>
    client
      .delete(`admin/org/${orgId}/emails/templates/${templateId}`)
      .then(({ data }) => data),
  updateEmailTemplate: ({ orgId, templateId, payload }) =>
    client
      .patch(`admin/org/${orgId}/emails/templates/${templateId}`, payload)
      .then(({ data }) => data),
};
