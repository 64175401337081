import React, { useRef, useEffect } from "react";
import EmailEditor from "react-email-editor";

const CustomEmailEditor = ({ onReady, onExportHtml }) => {
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      if (onExportHtml) {
        onExportHtml(html, design);
      } else {
        console.log("exportHtml", html);
      }
    });
  };

  // Ensure ref exists before calling onReady
  // useEffect(() => {
  //   if (emailEditorRef.current && onReady) {
  //     onReady();
  //   }
  // }, [onReady]);

  // useEffect(() => {
  //   if (emailEditorRef.current) {
  //     console.log("EmailEditor ref is available:", emailEditorRef.current);
  //     if (onReady) {
  //       onReady();
  //     }
  //   } else {
  //     console.error("EmailEditor ref is still null");
  //   }
  // }, [onReady]);

  return (
    <div className="border rounded-lg p-2">
      <div className="flex justify-end mb-10">
        <button onClick={exportHtml} className="p-3 bg-customBlue text-white text-xs rounded-md">
          Save email
        </button>
      </div>
      <EmailEditor ref={emailEditorRef} onLoad={onReady} />
    </div>
  );
};

export default CustomEmailEditor;
