import React, { useRef } from "react";
import Loader from "../loader/Loader";
import "./table.css";

function Table({ columns, data, isLoading, headerClassName, style }: any) {
  const table: any = useRef();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <table ref={table} style={style} className={"Parent_Style"}>
        <thead className={headerClassName}>
          <tr>
            {columns?.map((header: any) => (
              <th key={header.key}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row: any, index: number) => (
            <tr key={index}>
              {columns?.map((column: any) => {
                const item = row[column.selector];
                return (
                  <td key={column.key}>
                    {column.render ? column.render({ item, row, index }) : item}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default Table;
