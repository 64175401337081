import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import CustomDropdown from '../../../../../../../components/dropdown/CustomDropdown'
import { useMutation, useQuery } from 'react-query'
import { api as auth } from '../../../../../../../api'
import toast from 'react-hot-toast'
import { refetchType } from '../ProfileAndAccountSetup'

type initialValuesType = {
   name: string,
   email: string,
   number: string,
   country: string,
   state: string,
   address: string,
   size: string | number,
   type: string,
   paymentType: 'pre-paid' | 'post-paid',
}

type businessInfoDataType = {
    name: string
    email: string,
    phoneNumber: string,
    address: string,
    paymentType: 'pre-paid' | 'post-paid' ,
    type: string,
    size: string,
    country: string,
    state: string,
}

type statesType = {
   name: string, state_code: string
}

type BusinessSetupType = {
  businessInfoData: businessInfoDataType,
  refetch: refetchType
}

const BusinessSetup: React.FC<BusinessSetupType> = ({businessInfoData, refetch}) => {

  const initialValues: initialValuesType = {
    name: businessInfoData?.name ,
    email: businessInfoData?.email,
    number: businessInfoData?.phoneNumber,
    country: businessInfoData?.country, 
    address: businessInfoData?.address,
    size: businessInfoData?.size,
    state: businessInfoData?.state,
    type: businessInfoData?.type,
    paymentType: businessInfoData?.paymentType,
  }

  const { data: countriesList, isLoading, } = useQuery(
    "get countries", auth.getCountries, { select : res => res?.data, refetchOnMount: false }
  );

  const validationSchema = Yup.object<Yup.AnyObject, {}>().shape({    
    name: Yup.string().required('Error! name field is required.'),
    email: Yup.string().email('Error! Invalid email address')
           .required('Error! email address is required.'),
    number: Yup.string().required('Error! phone number is required')
            .max(20,'Error! phone number is too long maximum is 20 digits'),
    country: Yup.string().required('Error! country field is required.'),
    address: Yup.string().required('Error! address field is required.'),
    size: Yup.string().required('Error! business size field is required.'),
    type: Yup.string().required('Error! business type field is required.'),
    state: Yup.string().required('Error! state field is required.'),
  });

  const updateOrganisationProfileMutation = useMutation(auth.updateOrganisationProfile) 
  const org: any = JSON.parse(sessionStorage.getItem('user')!)

  const createBusinessInformation = async (values: initialValuesType, actions:any) : Promise<void> => {
    actions.setSubmitting(false);
    try{
      toast.loading('Loading...')
      const payload = {
        orgId: org?.org?.id,
        data: {
          country: values.country, organizationAddress: values.address,
          organizationEmail: values.email, organizationName: values.name,
          paymentType: values.paymentType, phoneNumber: values.number,
          size: values.size, state: values.state, type: values.type,
          userId: org?.userId
        }
      }
      const response = await updateOrganisationProfileMutation.mutateAsync(payload)
      toast.remove()
      toast.success(response?.message)
      refetch()
    }catch(error: any){
      toast.remove()
      toast.error(error?.response?.data?.message)
    }
  };

  return (
    <div>  
        <h5 className='text-lg font-semibold'>
          Business Information
        </h5>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={createBusinessInformation}
        >
          {({ values, setFieldError, setFieldValue, setFieldTouched }) => (
            <Form>
                 <section className='grid grid-cols-2 gap-5 mt-4'>
                       <div>
                        <label htmlFor="name" className='text-xs'>
                           Business Name <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' placeholder='Adeshina & sons'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='name' />
                          </p>
                       </div>
                       <div>
                        <label htmlFor="address" className='text-xs'>
                           Business Address (Street, city, ZIP)
                           <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='address' as='textarea' className="input-style bg-primaryGray" 
                             name='address'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='address' />
                          </p>
                       </div>
                 </section>
                 <section className='grid grid-cols-2 gap-5 mt-4'>
                     <div>
                        <label htmlFor="country" className='text-xs'>
                           Country <span className='text-lg text-red-500'>*</span>
                        </label>
                          <CustomDropdown
                            options={ isLoading ? [] :
                              countriesList?.map( (country: any) => {
                                 return { label: country?.name, value: country?.name }
                              })
                            }
                            value={{ label: values.country, value: values.country }} 
                            onChange={ (value:any) => {
                              setFieldValue('country', value?.value)
                              setFieldValue('state', '')
                            }}
                            onBlur={ (value: any) => {
                                if(!value?.value){
                                    setFieldTouched('country', true, true)
                                  }
                            }}
                            name={'country'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'Select Country'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='country' />
                          </p>
                       </div>
                       <div>
                        <label htmlFor="state" className='text-xs'>
                           State <span className='text-lg text-red-500'>*</span>
                        </label>
                          <CustomDropdown
                            options={
                                isLoading ? [] :
                                 countriesList?.find( (country: any) => {
                                    return country?.name === values.country
                                  })?.states?.map( (state: statesType) => {
                                    return { label: state.name, value: state.name }
                                  }) 
                            }
                            value={{ label: values.state, value: values.state }} 
                            onChange={ (value:any) => {
                              setFieldValue('state', value?.value)
                              if(value?.value){
                                setFieldTouched('state', false, false)
                                 setFieldError('state', '')
                              }
                            }}
                            onBlur={ (value: any) => {
                                if(!value?.value || values?.state){
                                    setFieldTouched('state', true, true)
                                  }
                                  else{
                                    setFieldTouched('state', false, false)
                                     setFieldError('state', '')
                                  }
                            }}
                            name={'state'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'Select State'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='state' />
                          </p>
                       </div>
                 </section>
                   <section className='grid grid-cols-2 gap-5 mt-4'>
                   <div>
                        <label htmlFor="number" className='text-xs'>
                           Business Contact Number <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='number' className="input-style bg-primaryGray" 
                             name='number' placeholder='+234 8185787677'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='number' />
                          </p>
                       </div>  
                      <div>
                        <label htmlFor="email" className='text-xs'>
                           Business Email Address <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='email' type='email' className="input-style bg-primaryGray" 
                             name='email' placeholder='Adeshina002@gmail.com'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='email' />
                          </p>
                       </div>
                   </section>
                   <section className='grid grid-cols-2 gap-5 mt-4'>
                       <div>
                        <label htmlFor="type" className='text-xs'>
                           Business Type <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='type' className="input-style bg-primaryGray" 
                             name='type' placeholder='Technology & Consulting'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='type' />
                          </p>
                       </div>
                       <div>
                        <label htmlFor="size" className='text-xs'>
                          Business Size <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='size' className="input-style bg-primaryGray" 
                             name='size' placeholder='20 or 50....'
                             type='number'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='size' />
                          </p>
                       </div>
                 </section>
                 <section className='grid grid-cols-2 gap-5 mt-4'>
                       <div>
                        <label htmlFor="paymentType" className='text-xs'>
                           Payment paymentType <span className='text-lg text-red-500'>*</span>
                        </label>
                        <CustomDropdown
                            options={
                                ['pre-paid', 'post-paid'].map( (type: string) => {
                                   return { label: type, value: type }
                                } ) 
                            }
                            value={{ label: values.paymentType, value: values.paymentType }} 
                            onChange={ (value:any) => {
                              setFieldValue('paymentType', value?.value)
                            }}
                            name={'paymentType'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            onBlur={null}
                            placeholder={'Select State'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='paymentType' />
                          </p>
                       </div>
                  </section>
                     <section className='mt-5 pb-12 flex'>
                           <button type='submit' className='bg-secondary text-white text-xs py-3 pl-12 pr-12 rounded'>
                               Update Business Information
                           </button>
                     </section>
            </Form>
          )}
    </Formik>
    </div>
  )
}

export default BusinessSetup