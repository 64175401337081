import React, { useState, useMemo } from "react";
import Table from "../../../../../../../components/table/Table";
import Loader from "../../../../../../../components/loader/Loader";
import { Link } from "react-router-dom";
import pathImage from '../../../../../../../assets/path.png';

type LearningPathType = {
   perPage: number,
   paths: any,
   isLoading: boolean,
}

const LearningPath: React.FC<LearningPathType> = ({perPage, isLoading, paths}) => {

  let columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }: {index:number}) =>
          index +
          1 +
          ((parseInt(paths?.meta?.page) || 1) - 1) *
            (parseInt(paths?.meta?.perPage) || perPage),
      },
      {
        title: "Image",
        selector: "imagePath",
        key: "imagePath",
        render: ({ item } : any) => {
          return <img 
           className="w-[40px] h-[40px] rounded-full object-cover ml-2" alt="learning path image"
           src={item || pathImage} 
         />;
        },
      },
      {
        title: "Title",
        selector: "name",
        key: "name",
      },
      {
        title: "Status",
        selector: "status",
        key: "status",
        render: ({ item }: any) => {
          if (item === "published") {
            return <span className="block w-[80px] border border-green-500 rounded-full text-center py-1 text-green-500 capitalize">{item}</span>;
          } else
            return <span className="block w-[80px] py-1 border border-primary rounded-full text-center text-primary capitalize">{item}</span>;
        },
      },
      {
        title: "Type",
        selector: "type",
        key: "type",
        render: ({ item } : any) => {
          return (
            <span
              className={`block w-[80px]`}
            >
              {item === 'program' ? 'program' : 'single path'}
            </span>
          );
        },
      },
      {
        title: "Multi Language",
        selector: null,
        key: null,
        render: ({ row } : any) => {
          return <span 
          className={ row?.languages?.length > 1 ? 'bi bi-check-circle text-primary-green text-sm pl-3' :
             'bi bi-x-octagon text-red-500 text-sm pl-3'
          }
         />
        },
      },
      {
        title: "Date Created",
        selector: "createdAt",
        key: "createdAt",
        render: ({ item } : any) => {
          return (
            <span className="">{`${new Date(item).toLocaleDateString(
              "en-ng"
            )}`}</span>
          );
        },
      },
      {
        title: "Date Updated",
        selector: "updatedAt",
        key: "updatedAt",
        render: ({ item }: any) => {
          return (
            <span className="">{`${new Date(item).toLocaleDateString(
              "en-ng"
            )}`}</span>
          );
        },
      },
      {
        title: "Action",
        selector: null,
        key: "actions",
        render: ({ row }: any) => {         
          return <Link
                   className='bi bi-pencil-square text-primary-blue text-lg'
                   to={`/admin/learning-path/${
                    row?.type === 'program' ? 'edit-program' : 'edit-path'}/${row?.id}`
                }
                   onClick={ () => sessionStorage.setItem('active-path', JSON.stringify(row)) }
                  />
        },
      }
    ],
    []
  );

  const tableProps = { isLoading, columns, data: paths?.data };

  return (
    <div>
      <section className="mt-0">
        {isLoading ? <Loader /> : <Table {...tableProps} />}
      </section>
    </div>
  );
};

export default LearningPath;
