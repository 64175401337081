import React, { useEffect, useState } from "react";
import { activeCohorts } from "./data";
import Table from "../../../../../components/table/Table";
import Progress from "../../../../../components/progress/Progress";
import Search from "../../../../../components/Inputs/Search";
import { dashboardApi } from "../../../../../api/dashboard";
import { useQuery } from "react-query";
import { orgId } from "../../../../../utils/org";
import Pagination from "../../../../../components/pagination/Pagination";
import EmptyData from "../../../../../components/EmptyData";
import { formatCurrency } from "../../billing/site-admin/pages/summary/Summary";
import Loader from "../../../../../components/loader/Loader";

const Clients = () => {
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: clients,
    isLoading,
    refetch,
  } = useQuery(
    ["get clients dashboard overview"],
    () => {
      return dashboardApi.getOrganisations({ perPage, currentPage, search });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [currentPage, perPage, search, isLoading, setSearch]);

  let columns = React.useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 +
          ((clients?.meta?.page || 1) - 1) *
            (clients?.meta?.perPage || perPage),
      },
      {
        title: "Client Name",
        selector: "orgName",
        key: "orgName",
        render: ({ item, row }) => {
          return <span className="">{item}</span>;
        },
      },
      {
        title: "Cohorts",
        selector: "numberOfCohorts",
        key: "numberOfCohorts",
        render: ({ item }) => {
          return (
            <span className="pl-3">
              {formatCurrency(item, false) || "-------"}
            </span>
          );
        },
      },
      {
        title: "Learners",
        selector: "numberOfParticipants",
        key: "numberOfParticipants",
        render: ({ item }) => {
          return <span className="pl-5"> {item} </span>;
        },
      },
      {
        title: "Daily Active Users",
        selector: "dailyActiveUsed",
        key: "dailyActiveUsed",
        render: ({ item }) => {
          return <span className="pl-2">{item}</span>;
        },
      },
      {
        title: "Av. Engagement",
        selector: "avgEnrollmentCount",
        key: "avgEnrollmentCount",
        render: ({ item }) => {
          return <span className="pl-2">{item}</span>;
        },
      },
      {
        title: "Credit Used",
        selector: "creditUsed",
        key: "creditUsed",
        render: ({ item }) => {
          return <span className="">{formatCurrency(item, false)}</span>;
        },
      },
      {
        title: "Payments",
        selector: "totalAmount",
        key: "totalAmount",
        render: ({ item }) => {
          return <span className="">{formatCurrency(item)}</span>;
        },
      },
    ],
    [1, 20, 20]
  );

  const tableProps = { isLoading, columns, data: clients?.data };

  if (isLoading) return <Loader />;

  return (
    <div className="bg-white p-3 rounded-lg shadow">
      <h5 className="text-md items-center font-medium pb-2 border-b border-b-gray-300 flex justify-between">
        Clients Overview ({clients?.meta?.total})
        <Search
          className={"w-[200px]"}
          placeholder={"Search Clients"}
          mb="mb-0"
          onChange={setSearch}
          onBlur={() => refetch()}
        />
      </h5>
      {clients?.data?.length ? (
        <Table {...tableProps} />
      ) : (
        <EmptyData text={"There is no any active cohort available yet."} />
      )}
      <Pagination
        data={clients?.meta}
        perPage={perPage}
        pageNo={currentPage}
        setPageNo={setCurrentPage}
        setPerPage={setPerPage}
      />
    </div>
  );
};

export default Clients;
