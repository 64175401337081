import React, { useEffect, useState } from 'react'
import logo from '../../../../assets/logo.png'
import Stepper, { menusType } from './components/Stepper'
import ProfileInformation from './components/ProfileInformation'
import BusinessSetup from './components/BusinessSetup'
import AccountCustomization from './components/AccountCustomization'
import AccountDemographics from './components/AccountDemographics'
import Confirmation from './components/Confirmation'

const AccountSetup: React.FC = () => {

 const steps = [
        { index: 1, header: 'Profile Information', title: 'Enter your data', active: true   },
        { index: 2, header: 'Business Setup', title: 'Enter your business information', active: false  },
        { index: 3, header: 'Customization', title: 'Align to your preference' , active: false },
        { 
            index: 4, header: 'Account Demographics', active: false,
            title: 'Select fields that you want to appear in learners signup' 
        },
        { index: 5, header: 'Confirmation', title: 'Confirm your data input', active: false  },
    ]

   const [menus, setMenus] = useState<menusType[]>(steps)
   const [currentStep, setCurrentStep] = useState<number>(1)

   useEffect( () : void => {
      document.querySelectorAll('*').forEach( (element: any) => {
         element.scroll({ top: 0, behaviour: 'smooth'})
      })
   }, [currentStep])

  return (
    <section className='p-8'>
       <img src={logo} alt="logo" className='w-[200px] h-[50px]' />
       <section className='p-3'>
          <h1 className='mt-5 font-semibold text-xl'>Account Setup</h1>
       </section>
       <section className='md:flex p-5 pt-3 md:px-12 xl:px-16 justify-between'>
        <div className='md:w-[20%]'>
          <Stepper 
             menus={menus} setMenus={setMenus}
             currentStep={currentStep}
             setCurrentStep={setCurrentStep}
            />
        </div>
        <div className='md:w-[73%] pt-3'>
           { currentStep === 1 && <ProfileInformation isClient setCurrentStep={setCurrentStep} /> }
           { currentStep === 2 && <BusinessSetup setCurrentStep={setCurrentStep} /> }
           { currentStep === 3 && <AccountCustomization setCurrentStep={setCurrentStep} /> }
           { currentStep === 4 && <AccountDemographics setCurrentStep={setCurrentStep} /> }
           { currentStep === 5 && <Confirmation /> }
        </div>
       </section>
    </section>
  )
}

export default AccountSetup