import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ViewCourses from './pages/viewCourses/ViewCourses'
import CreateCourse from './pages/createCourse/CreateCourse'
import EditCourse from './pages/editCourse/EditCourse'


const Courses = () => {
  return (
    <div>
        <Routes>
            <Route path="/" element={<ViewCourses />} />
            <Route path="/create" element={<CreateCourse />} />
            <Route path="/edit/:id" element={<EditCourse />} />
        </Routes>
    </div>
  )
}

export default Courses