import React, { useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { api } from "../../../../api/admin-management";
import NewUserModal from "./NewUserModal";
import Pagination from "../../../../components/pagination/Pagination";
import ConfirmModal from "../../../../components/Modal/ConfirmModal";

import { useNavigate } from "react-router";
import Search from "../../../../components/Inputs/Search";
import { ScaleLoader } from "react-spinners";

import moment from "moment";

const AdminManagement = () => {
  const navigate = useNavigate(); 
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const user = JSON.parse(window.sessionStorage.getItem("user"));
  const orgId = user?.org?.id;
  const accountType = user?.role?.slug;
  const isClientAdmin = accountType === "client-admin";
 
  const {
    data: admins,
    isLoading,
    refetch,
  } = useQuery(
    ["all-admins", search, perPage, currentPage],
    async () => {
      const response = await api.getAllAdmins({
        ...(isClientAdmin ? { orgId } : {}),
        search,
        perPage,
        currentPage,
      });

      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const deleteAdmin = async () => {
    if (!selectedUser) return;
    try {
      const response = await api.deleteAdmin({
        adminId: selectedUser.id,
      });
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-[#091F46] mb-3">
          Admin Management
        </h3>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => {
              setOpenNewUserModal(true);
              setSelectedUser(null);
            }}
            className="p-3 bg-customBlue text-white text-xs rounded-md"
          >
            Add New User
          </button>
        </div>
      </div>

      <div className="p-4 md:p-6 overflow-auto ">
        <div className="flex justify-between mb-3">
          <h3 className="text-lg font-semibold text-[#091F46] ">Admins</h3>
          <div className=" flex gap-6 justify-end">
            <Search
              onChange={setSearch}
              placeholder={"Search ..."}
              className={"w-fit"}
            />
          </div>
        </div>

        <div className="bg-white p-3 rounded-lg shadow-sm">
          {isLoading ? (
            <div className="mt-10 flex justify-center items-center">
              <ScaleLoader color="#FF9100" />
            </div>
          ) : (
            <div className="mt-4 ">
              <div className="">
                <table className="table-container">
                  <thead className="table-header">
                    <tr>
                      <th className="table-head text-left">No</th>
                      <th className="table-head text-left">Name</th>
                      <th className="table-head">role</th>
                      <th className="table-head">Email</th>
                      <th className="table-head">Create at</th>
                      <th className="table-head">Last Updated</th>
                    </tr>
                  </thead>

                  <tbody className="table-body text-xs">
                    {admins?.length > 0 ? (
                      admins.map((admin, index) => (
                        <tr key={index}>
                          <td className="table-data">{index + 1}</td>
                          <td className="table-data text-left">{admin.name}</td>
                          <td className="table-data text-left">
                            {admin.role?.name}
                          </td>
                          <td className="table-data text-left">
                            {admin.email}
                          </td>

                          <td className="table-data ">
                            {moment(admin.createdAt).format(
                              "YYYY/MM/DD, h:mm a"
                            )}
                          </td>
                          <td className="table-data ">
                            {moment(admin.updatedAt).format(
                              "YYYY/MM/DD, h:mm a"
                            )}
                          </td>

                          <td className="table-data flex gap-4 justify-center px-2">
                            <i
                              className="bi bi-pencil-fill cursor-pointer"
                              onClick={() => {
                                setSelectedUser(admin);
                                setOpenNewUserModal(true);
                              }}
                            ></i>
                            <i
                              className="bi bi-trash3-fill  text-red-500 cursor-pointer"
                              onClick={() => {
                                setSelectedUser(admin);
                                setDeleteModal(true);
                              }}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="table-data" colSpan="12">
                          <p className="text-center">No Data Available</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {deleteModal && (
                    <ConfirmModal
                      title="Delete Admin"
                      description={`Are you sure you want to delete ‘‘${selectedUser?.name}”?`}
                      onYes={deleteAdmin}
                      onClose={() => setDeleteModal(false)}
                    />
                  )}
                </table>
              </div>
            </div>
          )}
          <section className={`mt-1 px-3 rouded-lg bg-white`}>
            <Pagination
              data={admins?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
          </section>
        </div>
      </div>

      <NewUserModal
        openNewUserModal={openNewUserModal}
        setOpenNewUserModal={setOpenNewUserModal}
        refetch={refetch}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    </div>
  );
};

export default AdminManagement;
