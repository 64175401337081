import React, { Suspense, useState } from 'react'
import Header from './components/Header'
import Graphics from './components/Graphics'
import Data from './components/Data'
import Photos from './components/Photos'
import Contents from './components/Contents'
import Brand from './components/Brand'
import Slides from './components/Slides'
import Templates from './components/Templates'
import Page from './components/Page'
import ShareReportModal from './components/ShareReportModal'
import { v4 } from 'uuid'
import ConfirmModal from '../../../../../components/Modal/ConfirmModal'
import Text from './components/Text'
import { useReportEditor } from '../hooks/ReportEditorContext'
import undoIcon from '../../../../../assets/svgs/redo.svg'
import redoIcon from '../../../../../assets/svgs/icon.svg'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import Loader from '../../../../../components/loader/Loader'

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend );

type TabType = {
    icon: string
    text: string
}
type endViewType = 'slides' | 'templates'

export type tableData = {
  body: (string | number)[][]
  headers: string[]
}

const CreateReport: React.FC = () : JSX.Element => {

    const navTabs: TabType[] = [
        { icon: 'bi-fonts', text: 'Text' },
        { icon: 'bi-back', text: 'Graphics' },
        { icon: 'bi-noise-reduction', text: 'Data' },
        { icon: 'bi-image', text: 'Photos' },
        { icon: 'bi-border-width', text: 'Contents' },
        { icon: 'bi-fullscreen', text: 'Brand' },
     ];
    const [tableData, setTableData] = useState<tableData>({
      body: [
        [1, 'Label 1', 10, 20, 30, 40, 50, 60, 70],
        [2, 'Label 2', 15, 25, 35, 45, 55, 65, 75],
        [3, 'Label 3', 20, 30, 40, 50, 60, 70, 80],
      ],
      headers: ['S/N', 'Labels', 'Series 1', 'Series 2', 'Series 3', 'Series 4', 'Series 5', 'Series 6', 'Series 7']
    })
    const [activeTab, setActiveTab] = useState<TabType>(navTabs[0])
    const [title, setTitle] = useState<string>('Untitled Report')
    const [endView, setEndView] = useState<endViewType>('slides')
    const [pages, setPages] = useState<any[]>([ { id: v4(), Page } ])
    const [open, setOpen] = useState<boolean>(false)
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState<boolean>(false)
   
    const changeActiveTab = (tab: TabType) => {
       setActiveTab(tab)
    }

    const addNewPage = () => {
      if(pages) setPages?.([...pages, { id: v4(), Page: Page }])
     }

  return (
    <section>
      <Suspense
       fallback={
       <div className='flex justify-center items-center h-screen'>
         <Loader />
      </div>
       }
      >
         <ShareReportModal open={open} setOpen={setOpen} />
      </Suspense>
      {openDeleteConfirmModal && <ConfirmModal
         title='Confirm Pages Deletion'
         description='Are you sure you want to delete all pages?'
         onYes={() => {
          setPages?.([])
          setOpenDeleteConfirmModal(false)
         }}
         onClose={() => setOpenDeleteConfirmModal(false)}
      />}
        <Header title={title} setOpen={setOpen} setTitle={setTitle} />
        <section className='flex justify-between pt-1'>
        <section className='w-[18%] bg-white'>
             <nav className='h-[40px] px-2 content-center grid grid-cols-2 gap-4 border-b border-slate-300'> 
                <span className={` text-xs pl-2 hover:cursor-pointer ${ endView === 'slides' ?
                      'text-primary-blue font-semibold border-b-2 border-primary-blue' :
                       'text-slate-900'
                      }`}
                 onClick={ () => setEndView('slides') }
                > 
                 Slides 
                </span>
                <span className={` text-xs hover:cursor-pointer ${ endView === 'templates' ?
                      'text-primary-blue font-semibold border-b-2 border-primary-blue' :
                       'text-slate-900'
                      }`}
                 onClick={ () => setEndView('templates') }
                >
                  Templates
                </span>
              </nav>
              <section className='p-2'>
                 {  
                    endView === 'slides' ? 
                     <Slides setPages={setPages} pages={pages} />
                      :
                     <Templates />
                 }
              </section>
            </section>
            <section className='w-[55%]'>
                <section className='px-3 h-[500px]'>
                  <nav className='h-[40px] bg-white px-2 flex items-center gap-4 border-b border-slate-300'> 
                    <span className='bi text-lg mr-4 hover:cursor-pointer'>
                      <img src={undoIcon} alt='undo' />
                    </span>
                    <span className='bi text-lg mr-4 hover:cursor-pointer'>
                      <img src={redoIcon} alt='redo' />
                    </span>
                    <span 
                      className='bi bi-trash text-lg mr-4 hover:cursor-pointer' 
                      onClick={() => setOpenDeleteConfirmModal(true)}
                    />
                    <span className='bi bi-copy text-lg mr-4 hover:cursor-pointer' />
                    <button
                      className='block p-2 hover:bg-gray-100 rounded-lg'
                      onClick={addNewPage}
                      >
                      <i className='bi bi-file-earmark-plus text-gray-600'></i>
                    </button>
                  </nav>
                  {
                    pages.map( (Page, index) => {
                      return <Page.Page
                              key={Page.id} pageIndex={index + 1}
                              setPages={setPages}
                              newPage={Page}
                              pages={pages}
                           />
                    })
                  }
                </section>
            </section>
            <section className='w-[27%] bg-white'>
                <nav className='h-[40px] content-center grid grid-cols-6 border-b border-slate-300'> 
                  {
                    navTabs.map( tab => {
                     return <span  
                        className={`text-slate-950 hover:bg-slate-300 ${
                            activeTab.text === tab.text ? 'bg-white' : 'bg-slate-200'
                          }  hover:cursor-pointer text-[11px] h-full flex flex-col items-center`}
                         onClick={ () => changeActiveTab(tab) }
                        >
                          <i className={`bi ${tab.icon} text-lg text-slate-700`} />
                         {tab.text}
                      </span>
                    })
                  }
                </nav>
                <section className='p-3 mt-'>
                    { activeTab.text === navTabs[0].text &&
                      <Text />
                    }
                    { activeTab.text === navTabs[1].text &&
                      <Graphics />
                    }
                    { activeTab.text === navTabs[2].text &&
                      <Data 
                        tableData={tableData} setTableData={setTableData} 
                      />
                    }
                    { activeTab.text === navTabs[3].text &&
                      <Photos />
                    }
                    { activeTab.text === navTabs[4].text &&
                      <Contents />
                    }
                    { activeTab.text === navTabs[5].text &&
                      <Brand />
                    }
                </section>
            </section>
        </section>
    </section>
  )
}

export default CreateReport
