import React from 'react'

const ResourceLoader = ({cancelUpload}: any) => {
  return (
    <div style={{zIndex: 1000000}} className=' px-3 w-[250px] h-[auto] rounded fixed right-5 bottom-5'>
         <div className='items-center mt-1 flex bg-green-200 border border-green-700 rounded-full py-1 px-2'>
             <span className='text-xs text-green-700 font-semibold'>Processing file...</span>
             <div className="loader w-[50px] bg-gray-100 rounded-full ml-2"></div>
             <button 
                 className='bg-white px-1 py-[2px] text-red-500 rounded-full bi bi-x-lg text-xs ml-5'
                 onClick={ cancelUpload }
              />
         </div>
    </div>
  )
}

export default ResourceLoader