import React, { useState, useRef } from "react";
import { Rnd } from "react-rnd";
import { useReportEditor } from "../../hooks/ReportEditorContext";
const Text = () => {
  const {
    elements,
    setElements,
    addElement,
    activeElementIndex,
    setActiveElementIndex,
  } = useReportEditor();

  const [isCardOpen, setIsCardOpen] = useState(false);

  // Add element with specific style
  // const addElement = (type) => {
  //   const newElement = {
  //     content:
  //       type === "heading"
  //         ? "Heading Text"
  //         : type === "subheading"
  //         ? "Subheading Text"
  //         : "Body Text",
  //     fontSize: type === "heading" ? 32 : type === "subheading" ? 24 : 16,
  //     color: "#000",
  //     fontWeight:
  //       type === "heading" ? "bold" : type === "subheading" ? "600" : "normal",
  //     fontStyle: "normal",
  //     fontFamily: "calibri",
  //     textAlign: "left",
  //     letterSpacing: 0,
  //     lineHeight: 1.5,
  //     opacity: 1,
  //     x: 50,
  //     y: 50,
  //     editable: true,
  //   };
  //   setElements([...elements, newElement]);
  //   setActiveElementIndex(elements.length);
  // };

  const updateElementPosition = (index, x, y) => {
    const updatedElements = [...elements];
    updatedElements[index].x = x;
    updatedElements[index].y = y;
    setElements(updatedElements);
  };

  const updateActiveElement = (key, value) => {
    if (activeElementIndex !== null) {
      const updatedElements = [...elements];
      updatedElements[activeElementIndex][key] = value;
      setElements(updatedElements);
    }
  };

  return (
    <div className="text-slate-900">
      {/* Toolbar */}
      <div className="p-4 border-r">
        <div className="flex flex-col justify-start">
          <div className="flex items-center gap-3 mb-4">
            <div>
              <i className="bi bi-plus-lg text-[25px]"></i>
            </div>
            <button
              className="bg-white text-[#1D1F2C] text-[25px] font-black w-fit"
              onClick={() => addElement("heading")}
            >
              Add Heading
            </button>
          </div>
          <div className="flex gap-3 mb-4">
            <div>
              <i className="bi bi-plus-lg text-[18px]"></i>
            </div>
            <button
              className="mb-4  bg-white text-[#1D1F2C] text-[18px] font-medium w-fit"
              onClick={() => addElement("subheading")}
            >
              Add Subheading
            </button>
          </div>
          <div className="flex gap-3">
            <div>
              <i className="bi bi-plus-lg text-[14px]"></i>
            </div>
            <button
              className="mb-4  bg-white text-[#1D1F2C] text-[14px] font-light w-fit"
              onClick={() => addElement("body")}
            >
              Add Body Text
            </button>
          </div>
        </div>

        {/* Toolbar for editing the active element */}
        <div className="mt-6">
          {activeElementIndex !== null && elements[activeElementIndex] && (
            // {activeElementIndex !== null && (
            <div>
              {/* Font Size */}
              <div>
                <label className="block text-base font-medium text-gray-700 mb-2">
                  Font Size
                </label>
                <input
                  type="number"
                  value={elements[activeElementIndex]?.fontSize}
                  onChange={(e) =>
                    updateActiveElement("fontSize", e.target.value)
                  }
                  className="px-2 py-1 rounded-md w-full"
                />
              </div>

              {/* Font Color */}
              <div className="mt-4">
                <label className="block text-base font-medium text-gray-700 mb-2">
                  Font Color
                </label>
                <input
                  type="color"
                  value={elements[activeElementIndex]?.color}
                  onChange={(e) => updateActiveElement("color", e.target.value)}
                  className="px-2 py-1 rounded-md w-full"
                />
              </div>

              {/* Font Weight */}
              <div className="mt-4">
                <label className="block text-base font-medium text-gray-700 mb-2">
                  Font Weight
                </label>
                <select
                  value={elements[activeElementIndex]?.fontWeight}
                  onChange={(e) =>
                    updateActiveElement("fontWeight", e.target.value)
                  }
                  className="px-2 py-1 rounded-md w-full"
                >
                  <option value="normal">Normal</option>
                  <option value="bold">Bold</option>
                </select>
              </div>

              {/* Font Style */}
              <div className="mt-4">
                <label className="block text-base font-medium text-gray-700 mb-2">
                  Font Style
                </label>
                <select
                  value={elements[activeElementIndex]?.fontStyle}
                  onChange={(e) =>
                    updateActiveElement("fontStyle", e.target.value)
                  }
                  className="px-2 py-1 rounded-md w-full"
                >
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </div>

              {/* Font Family */}
              <div className="mt-4">
                <label className="block text-base font-medium text-gray-700 mb-2">
                  Font Family
                </label>
                <select
                  value={elements[activeElementIndex]?.fontFamily}
                  onChange={(e) =>
                    updateActiveElement("fontFamily", e.target.value)
                  }
                  className="px-2 py-1 rounded-md w-full"
                >
                  <option value="calibri">Calibri</option>
                  <option value="arial">Arial</option>
                  <option value="arial black">Arial Black</option>
                  <option value="times new roman">Times New Roman</option>
                  <option value="georgia">Georgia</option>
                </select>
              </div>

              {/* Text Alignment */}
              <div className="mt-4">
                <label className="block text-base font-medium text-gray-700 mb-2">
                  Text Alignment
                </label>
                <div className="flex space-x-2">
                  <button
                    onClick={() => updateActiveElement("textAlign", "left")}
                    className="bg-gray-300 px-3 py-1 rounded"
                  >
                    <i class="bi bi-justify-left"></i>
                  </button>
                  <button
                    onClick={() => updateActiveElement("textAlign", "center")}
                    className="bg-gray-300 px-3 py-1 rounded"
                  >
                    <i class="bi bi-text-center"></i>
                  </button>
                  <button
                    onClick={() => updateActiveElement("textAlign", "right")}
                    className="bg-gray-300 px-3 py-1 rounded"
                  >
                    <i class="bi bi-justify-right"></i>
                  </button>
                  <button
                    onClick={() => updateActiveElement("textAlign", "justify")}
                    className="bg-gray-300 px-3 py-1 rounded"
                  >
                    <i class="bi bi-justify"></i>
                  </button>
                </div>
              </div>

              {/* Letter Spacing */}
              <div className="mt-4">
                <label className="block text-base font-medium text-gray-700 mb-2">
                  Letter Spacing
                </label>
                <input
                  type="range"
                  min="0"
                  max="10"
                  step="0.1"
                  value={elements[activeElementIndex]?.letterSpacing || 0} // Fallback to 0
                  onChange={(e) =>
                    updateActiveElement(
                      "letterSpacing",
                      parseFloat(e.target.value)
                    )
                  }
                   className="w-full appearance-none h-2 bg-[#1F456C] rounded-full"
                />
              </div>

              {/* Line Height */}
              <div className="mt-4">
                <label className="block text-base font-medium text-gray-700 mb-2">
                  Line Height
                </label>
                <input
                  type="range"
                  min="1"
                  max="3"
                  step="0.1"
                  value={elements[activeElementIndex]?.lineHeight || 1.5} // Fallback to 1.5
                  onChange={(e) =>
                    updateActiveElement(
                      "lineHeight",
                      parseFloat(e.target.value)
                    )
                  }
                   className="w-full appearance-none h-2 bg-[#1F456C] rounded-full"
                />
              </div>

              {/* Text Opacity */}
              <div className="mt-4">
                <label className="block text-base font-medium text-gray-700 mb-2">
                  Text Opacity
                </label>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={elements[activeElementIndex]?.opacity}
                  onChange={(e) =>
                    updateActiveElement("opacity", e.target.value)
                  }
                  className="w-full appearance-none h-2 bg-[#1F456C] rounded-full"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Text;
