import React, { useState } from 'react'
import CourseInformation from './components/CourseInformation'
import CourseContent from './components/CourseContent'

export type stepType = (1 | 2)

const CreateCourse: React.FC = (): React.ReactElement => {
  
 const [step, setStep] = useState<stepType>(1)

  return (
    <div className='p-5'>
          { step === 1 ? 
           <CourseInformation setStep={setStep} /> :
           <CourseContent setStep={setStep} />
        }
    </div>
  )
}

export default CreateCourse