import React from "react";
import toast from "react-hot-toast";
import AccessLogo from "../../../../../../assets/svgs/access-logo.svg";

import { useQuery } from "react-query";
import { learners as api } from "../../../../../../api/learners";
import { useParams } from "react-router-dom";

const LearnerProfileSection = () => {
  const { id } = useParams();

  const { data: profileData, isLoading } = useQuery(
    ["profile-data"],
    async () => {
      const response = await api.getLearnerProfile(id);
      return response.data;
    },
    {
      staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      cacheTime: 30 * 60 * 1000,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  return (
    <div className="card-container">
      <div className="flex justify-center">
        <div className="flex flex-col items-center justify-center w-16 h-16 rounded-full border border-gray-300 shadow-sm">
          <i className="bi bi-person-fill text-4xl text-gray-600"></i>
        </div>
      </div>

      <hr className="my-4" />
      <div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i className="bi bi-calendar-range text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">Name</p>
            <p className="font-normal text-xs text-[#667085]">
              {profileData?.name}
            </p>
          </div>
        </div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i className="bi bi-envelope text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">E-mail</p>
            <p className="font-normal text-xs text-[#667085]">
              {profileData?.email}
            </p>
          </div>
        </div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i className="bi bi-telephone text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">Phone Number</p>
            <p className="font-normal text-xs text-[#667085]">
              {profileData?.phoneNumber}
            </p>
          </div>
        </div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i className="bi bi-buildings-fill text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">State</p>
            <p className="font-normal text-xs text-[#667085]">
              {profileData?.state}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">Country</p>
            <p className="font-normal text-xs text-[#667085]">
              {profileData?.country}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnerProfileSection;
