import React from "react";
import achievementCertificate from "../assets/images/Blue Minimalist Certificate Of Achievement - template.png";
import appreciationCertificate from "../assets/images/White Simple Certificate of Appreciation.png";
import goldCertificate from "../assets/images/Blue and Gold Simple Certificate Template.png";
import achievementPlainCertificate from "../assets/images/Blue Minimalist Certificate Of Achievement.png";
import appreciationPlainCertificate from "../assets/images/Blue and Gold Simple Certificate.png";
import { useNavigate } from "react-router";
import certificatesData from "../assets/utils/defaultCertificatesData.json"

const DefaultCertificatesTemplate = () => {
  const navigate = useNavigate();
  // const [perPage, setPerPage] = useState(10);
  // const [search, setSearch] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);


  return (
    <div className="grid grid-cols-1 gap-12 md:grid-cols-2 xl:grid-cols-3">
      {certificatesData?.certificatesData?.map((certificate, index) => (
        <div
          key={index}
          className="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer max-h-90 w-60 md:w-80"
          onClick={() => {
            sessionStorage.setItem("backgroundImage", JSON.stringify(certificate.backimage));
            sessionStorage.setItem("elements", JSON.stringify(certificate.elements))}}
        >
          <img
            src={certificate.image}
            alt={`certificate${index}`}
            className="object-cover w-full max-h-42"
          />
          <div className="w-full p-2 bg-white dark:bg-gray-800">
            <p className="mb-2 text-xl font-medium text-gray-800 dark:text-white">
              {certificate.title}
            </p>
            <p className="font-light text-gray-400 dark:text-gray-300 text-md">
              {certificate.description}
            </p>
          </div>
          <div
            className="flex justify-end p-1"                        
          >
            <div className="flex gap-2">
              <i
                className="bi bi-pencil-fill cursor-pointer"
                onClick={() => {
                  navigate(
                    `/admin/certificate/update-template/${certificate.id}`
                  );
                }}
              ></i>
              {/* <i className="bi bi-eye cursor-pointer"></i> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DefaultCertificatesTemplate;
