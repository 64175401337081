import createApiClient from "./api";

const client = createApiClient();

export const api = {

  getLearners: ({ orgId, search, perPage, currentPage }) =>
    client
      .get(
        `/admin/learners/profile?orgId=${orgId}&perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}`
      )
      .then(({ data }) => data),

      createOrganisationTeams: ({orgId, payload}) => client
      .post(`/admin/org/${orgId}/org-teams/multi`, payload)
      .then(({ data }) => data),

      getTeams: ({ orgId, search, perPage, currentPage }) =>
      client
        .get(
          `/admin/org/${orgId}/org-teams?perPage=${perPage}${
              search ? `&search=${search}` : ""
            }&page=${currentPage}`
        )
        .then(({ data }) => data),

      getTeamSummary: ({ orgId }) =>
          client
            .get(
              `/admin/org/${orgId}/org-teams/summary`
            )
            .then(({ data }) => data),

      assignOrganisationTeamsToCohort: ({orgId, payload}) => client
      .post(`/admin/org/${orgId}/org-teams/assign-cohort`, payload)
      .then(({ data }) => data),

      assignLearnersToTeam: ({orgId, payload}) => client
      .post(`/admin/org/${orgId}/org-teams/move-learners`, payload)
      .then(({ data }) => data),

      getTeamsLearners: ({ orgId, search, perPage, currentPage, teamId }) =>
        client
          .get(
            `/admin/org/${orgId}/org-teams/get/${teamId}?perPage=${perPage}${
                search ? `&search=${search}` : ""
              }&page=${currentPage}`
          )
          .then(({ data }) => data),
       
        addTeamInvitationEmail: ({orgId, payload, cohortId}) => client
        .patch(`/admin/org/${orgId}/cohorts/${cohortId}/welcome-email`, payload)
        .then(({ data }) => data),

        addCohortInvitationEmail: ({orgId, payload, cohortId}) => client
        .patch(`/admin/org/${orgId}/cohorts/${cohortId}/invite-email`, payload)
        .then(({ data }) => data),

        addWelcomePage: ({orgId, payload, cohortId}) => client
        .patch(`/admin/org/${orgId}/cohorts/${cohortId}/message`, payload)
        .then(({ data }) => data),

        addMultiLanguageWelcomeMessage: ({orgId, payload, cohortId}) => client
        .patch(`/admin/org/${orgId}/cohorts/${cohortId}`, payload)
        .then(({ data }) => data),
    };
