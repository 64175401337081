// src/pages/Admin/pages/report/createReport/components/Chart.tsx
import React, { useEffect, useRef } from 'react';
import { Chart as ChartJS } from 'chart.js/auto';

interface ChartProps {
  config: any;
  width: number;
  height: number;
}

const ChartComponent: React.FC<ChartProps> = ({ config, width, height }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<ChartJS | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;

    // Destroy existing chart
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Create new chart
    const ctx = chartRef.current.getContext('2d');
    if (ctx) {
      chartInstance.current = new ChartJS(ctx, {
        ...config,
        options: {
          ...config.options,
          responsive: true,
          maintainAspectRatio: false,
        }
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [config]);

  return (
    <div style={{ width: `${width}px`, height: `${height}px`, position: 'relative' }}>
      <canvas 
        ref={chartRef}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default ChartComponent;