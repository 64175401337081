import React from 'react'
import CenteredModal from '../../../../../components/Modal/CenteredModal'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import CustomDropdown from '../../../../../components/dropdown/CustomDropdown'
import { api as auth } from '../../../../../api' 
import toast from 'react-hot-toast'
import { demographyType } from './AccountDemographics'
import Switch from 'react-switch'

type CreateDemographicType = {
    openModal: boolean,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
    refetch: any,
    activeDemography?: (demographyType | null),
    setActiveDemography?: React.Dispatch<React.SetStateAction<demographyType | null>>
}

type initialValuesType = {
    fieldName: string,
    category: string,
    type: string,
    validation: string,
    ismandatory: boolean,
  }

const CreateDemoGraphics: React.FC<CreateDemographicType> = ({
   openModal, setOpenModal, refetch, activeDemography=null,
   }) => {

    const orgId: string = sessionStorage.getItem('org-id')!
    const isEdit: boolean = activeDemography !== null
    
    const initialValues: initialValuesType = {
      fieldName: isEdit ? activeDemography?.fieldName! : '',
      category: isEdit ? activeDemography?.fieldCategory! : '',
      type: isEdit ? activeDemography?.fieldType! : '',
      validation: isEdit ? activeDemography?.fieldValidation! : '',
      ismandatory: isEdit ? activeDemography?.isMandatory! : false,
    }

    const validationSchema = Yup.object<Yup.AnyObject, {}>().shape({    
        fieldName: Yup.string().required('Error! field name field is required.'),
        category: Yup.string().required('Error! category field is required.'),
        type: Yup.string().required('Error! field type is required.'),
    });

  const inputTypes: string[] = [
    "text", "password", "email", "number", "password Confirmation",
    "tel", "url",  "date",
    "time", "datetime-local", "month", "week",
    "color","checkbox", "radio", "file", "range",
  ];

  const fieldCategories: string[] = [
    "Personal Information",
    "Contact Information",
    "Household Information",
    "Employment Information",
    "Health Information",
    "Financial Information",
    "Emergency Contact",
    "Survey or Custom Fields",
    "Consent & Legal Information"
  ];
  
  const createDemoGraphics = async(value: initialValuesType, action: any) : Promise<void> => {
    action.setSubmitting(false);
    try{
      toast.loading('Loading...')
      const payload = {
        orgId, payload: {
          fieldCategory: value.category,
          fieldName: value.fieldName,
          fieldType: value.type,
          isMandatory: value.ismandatory,
        }
      }
      let response: any;
      if(activeDemography === null){
         response = await auth.createDemoGraphics(payload)  
      }
      else response = await auth.updateDemoGraphics({...payload, id: activeDemography?.id })
      toast.remove()
      toast.success(response?.message)
      refetch()
      setOpenModal(false)
    }catch(error: any){
      toast.remove()
      toast.error(error?.response?.data?.message)
    }
  }

  return (
    <>
       <CenteredModal
          title={
            <h5 className='text-md font-semibold bi bi-pencil-square'> 
                &nbsp;New Demographic
           </h5>
          }
        bgColor={'bg-white'}
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[550px] h-auto overflow-y-auto'}
        headerClassName={'flex justify-between px-3 pt-2 sticky'}
        withCloseBtn
        closeButtonLabel={ isEdit ? 'Edit' : 'Create'}
        btnBgColor={'bg-secondary'}
        onClose={ () : void => {
         const createDemographicsButton = document.querySelector('#create-demographic') as HTMLButtonElement
          createDemographicsButton.click()
        }}
      >  
         <section className='text-left px-3'>
              <p className='text-sm'>
                Add new demographic and set up your account
              </p>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={createDemoGraphics}
         >
            {({ values, setFieldError, setFieldValue, setFieldTouched }) => (
                <Form>
                    <section className='grid grid-cols-1 gap-5 mt-1'>
                       <div>
                        <label htmlFor="fieldName" className='text-xs'>
                           Field Name <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='fieldName' className="input-style bg-primaryGray" 
                             name='fieldName'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='fieldName' />
                          </p>
                       </div>
                 </section>
                 <section className='grid grid-cols-1 gap-5 mt-1'>
                       <div>
                        <label htmlFor="category" className='text-xs'>
                           Category <span className='text-lg text-red-500'>*</span>
                        </label>
                        <CustomDropdown
                            options={ fieldCategories.map( (category: string) => {
                                 return { label: category, value: category }
                              })
                            }
                            value={{ label: values.category, value: values.category }} 
                            onChange={ (value:any) => {
                              setFieldValue('category', value?.value)
                            }}
                            onBlur={ (value: any) => {
                                if(!value?.value){
                                    setFieldTouched('category', true, true)
                                  }
                            }}
                            name={'category'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'Select Category'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='category' />
                          </p>
                       </div>
                 </section>
                 <section className='grid grid-cols-1 gap-5 mt-1'>
                       <div>
                        <label htmlFor="type" className='text-xs'>
                           Field Type <span className='text-lg text-red-500'>*</span>
                        </label>
                        <CustomDropdown
                            options={ inputTypes.map( (type: string) => {
                                 return { label: type, value: type }
                              })
                            }
                            value={{ label: values.type, value: values.type }} 
                            onChange={ (value:any) => {
                              setFieldValue('type', value?.value)
                            }}
                            onBlur={ (value: any) => {
                                if(!value?.value){
                                    setFieldTouched('type', true, true)
                                  }
                            }}
                            name={'type'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'Select Type'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='type' />
                          </p>
                       </div>
                 </section>
                 <section className='grid hidden grid-cols-1 gap-5 mt-1'>
                       <div>
                        <label htmlFor="validation" className='text-xs'>
                          Field Validation (optional) <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='validation' className="input-style bg-primaryGray" 
                             name='validation'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='validation' />
                          </p>
                       </div>
                 </section>
                 <section className='flex text-xs items-center mt-2 justify-between'>
                    Mandatory Field
                    <div>
                      <Switch
                          height={20}
                          width={40}
                          handleDiameter={18}
                          checked={values.ismandatory}
                          onChange={() => setFieldValue('ismandatory',!values.ismandatory)}
                        /> 
                    </div>
                 </section>
                 <button type='submit' className='hidden' id='create-demographic' />
                </Form>      
              )}  
            </Formik>
         </section>
      </CenteredModal>
    </>
  )
}

export default CreateDemoGraphics