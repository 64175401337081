import React, { useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { ScaleLoader } from "react-spinners";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../../../api/certificate";
import Pagination from "../../../../../components/pagination/Pagination";
import ConfirmModal from "../../zoom-integration/components/ConfirmModal";
import MenuItem from "../../../../../components/dropdown/MenuItem";
import VerticalMenu from "../../../../../components/dropdown/VerticalMenu";
import Search from "../../../../../components/Inputs/Search";
import ViewCertificate from "./ViewCertificate";

const AllCertificates = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;
  const [deleteModal, setDeleteModal] = useState(false);
  const [openViewCertificateModal, setOpenViewCertificateModal] =
    useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [certificateId, setCertificateId] = useState(null)

  const {
    data: certificates,
    isLoading,
    refetch,
  } = useQuery(
    ["get-all-certificates", search, perPage, currentPage],
    async () => {
      const response = await api.getAllCertificates({
        orgId,
        search,
        perPage,
        currentPage,
      });
            return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const deleteCertificate = async () => {
    if (!selectedCertificate) return;
    try {
      const response = await api.deleteCertificate({
        orgId,
        certificateId: selectedCertificate.template.id,
      });
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="">
    <div className="flex justify-end mb-3">
      {/* <h3 className="text-lg font-semibold text-[#091F46]">
        Certificate Summary
      </h3> */}
      <div className=" flex justify-end mt-5">
        <Search
          onChange={setSearch}
          placeholder={"Search ..."}
          className={"w-fit"}
        />
      </div>
    </div>
    <div className="bg-white p-3 rounded-lg shadow">
      {isLoading ? (
        <div className="mt-10 flex justify-center items-center">
          <ScaleLoader color="#FF9100" />
        </div>
      ) : (
        <div className="mt-4 ">
          <div className="overflow-x-auto">
            <table className="table-container">
              <thead className="table-header">
                <tr>
                  <th className="table-head text-left">ID</th>
                  <th className="table-head text-left">Name</th>
                  <th className="table-head text-left">Description</th>
                  {/* <th className="table-head text-left">Cohort</th> */}
                  <th className="table-head text-left">Downloads</th>
                  <th className="table-head text-left">Created By</th>
                  <th className="table-head text-left">Created at</th>
                  <th className="table-head text-left">Last Modified</th>
                  <th className="table-head text-left">Status </th>
                  <th className="table-head text-left">Action</th>
                </tr>
              </thead>

              <tbody className="table-body text-xs">
                {certificates?.length > 0 ? (
                  certificates?.map((certificate, index) => (
                    <tr key={index}>
                      <td className="table-data text-left">{index + 1}</td>
                      <td className="table-data text-left">
                        {certificate.template.name}
                      </td>
                      <td className="table-data text-left">
                        {certificate.template.description}
                      </td>
                      {/* <td className="table-data text-left">
                          cohort name
                        </td> */}
                      <td className="table-data text-center">
                        {certificate.numberOfDownloads}
                      </td>
                      <td className="table-data text-left">
                        {certificate.template?.user?.name}
                      </td>
                      <td className="table-data text-left">
                        {moment(certificate.template.createdAt).format(
                          "YYYY/MM/DD, h:mm a"
                        )}
                      </td>

                      <td className="table-data ">
                        {moment(certificate.template.updatedAt).format(
                          "YYYY/MM/DD, h:mm a"
                        )}
                      </td>
                      <td className="table-data ">
                        {certificate.template.status}
                      </td>

                      <td className="table-data flex justify-center px-2">
                        {/* <span className="flex justify-center"> */}
                        <VerticalMenu>
                          <div className="w-fit flex flex-col gap-2">
                            <MenuItem
                              icon={
                                <i className="bi bi-pencil-fill cursor-pointer"></i>
                              }
                              label="Edit"
                              onClick={() => {
                                navigate(
                                  `/admin/certificate/update-certificate/${certificate.template.id}`
                                );
                              }}
                            />
                            <MenuItem
                              icon={
                                <i className="bi bi-eye cursor-pointer"></i>
                              }
                              label="View"
                              onClick={() => {
                                setCertificateId(certificate.template.id)
                                setOpenViewCertificateModal(
                                  true
                                );
                              }}
                            />

                            <MenuItem
                              icon={
                                <i className="bi bi-trash3-fill  text-red-500 cursor-pointer"></i>
                              }
                              label="Delete"
                              onClick={() => {
                                setSelectedCertificate(certificate);
                                setDeleteModal(true);
                              }}
                            />
                          </div>
                        </VerticalMenu>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="table-data" colSpan="12">
                      <p className="text-center">No Data Available</p>
                    </td>
                  </tr>
                )}
              </tbody>
              
            </table>
          </div>
        </div>
      )}
    </div>
    <ViewCertificate
                openViewCertificateModal={openViewCertificateModal}
                setOpenViewCertificateModal={setOpenViewCertificateModal}
                certificateId={certificateId}
              />
              {deleteModal && (
                <ConfirmModal
                  title="Delete Certificate"
                  description={`Are you sure you want to delete ‘‘${selectedCertificate?.template.name}”?`}
                  onYes={deleteCertificate}
                  onClose={() => setDeleteModal(false)}
                />
              )}
    <section className={`mt-1 px-3 rouded-lg bg-white`}>
      <Pagination
        data={certificates?.meta}
        perPage={perPage}
        pageNo={currentPage}
        setPageNo={setCurrentPage}
        setPerPage={setPerPage}
      />
    </section>
  </div>
  );
};

export default AllCertificates;
