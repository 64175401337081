import React from 'react'
import successIcon from '../../../assets/success-check.png'
import CenteredModal from '../../../components/Modal/CenteredModal'

type ConfirmationType = {
    openModal: boolean,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
    title?: string,
    text?: string,
    footer?: React.ReactNode
    width?: string
    footerButtonsAlignment?: 'between' | 'center' | 'left' | 'end'
}

const Confirmation = ({ 
    openModal, setOpenModal, title, text, footer, width='w-[600px]', footerButtonsAlignment='between'
 }: ConfirmationType) => {

  return (
    <CenteredModal
        title={null}
        bgColor={'bg-white'}
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={`h-auto ${width} overflow-y-auto`}
        headerClassName={'hidden'}
        withCloseBtn={false}
        closeButtonLabel={null}
        btnBgColor={'bg-secondary'}
        onClose={null}
      >  
        <img src={successIcon} className='w-[70px] mx-auto block h-[70px]' alt="icon"/>
        <h5 className='text-lg pt-3 pb-1 font-bold'>
            {title}
        </h5>
        <p className='text-center text-sm'>{text}</p>
        <div className={`flex py-5 mt-4 justify-${footerButtonsAlignment}`}>
            {footer}
        </div>
      </CenteredModal>
  )
}

export default Confirmation