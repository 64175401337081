import React, { useEffect, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup'
import { Link } from 'react-router-dom';
import Switch from 'react-switch'
import { stepType } from '../CreateCourse';
import ResourceLoader from '../../../../../../../components/loader/ResourceLoader';
import { createResource } from '../../../../../../../api/uploadResourse';
import toast from 'react-hot-toast';
import { errorResponse } from '../../../../../../../components/utils/errorResponse';
import HorizontalTab from '../../../../../../../components/Tab/HorizontalTab';
import { api as courses } from '../../../../../../../api/courses';
import { useQuery } from 'react-query';
import CustomDropdown from '../../../../../../../components/dropdown/CustomDropdown';

type CourseInformationType = {
  setStep : React.Dispatch<React.SetStateAction<stepType>>
  type?: 'edit' | null,
  courseInfo?: any
}

export type languageType = {
    language: "english" | "french" | "portuguese",
    symbol: "en" | "fr" | "pt",
    name: string | undefined, 
    description: string | undefined, 
    isDefault: boolean,
}

type initialValuesType = {
    title: string, 
    description: string,
    category: string,
    tag: string,
    code: string,
    status: ("draft" | "published"),
    title_f?: string,
    description_f?: string,
    title_p?: string,
    description_p?: string,
  }

const CourseInformation: React.FC<CourseInformationType> = (props) : React.ReactElement => {
    
    const validationSchema = Yup.object<Yup.AnyObject, {}>().shape({    
        title: Yup.string().required('Error! field title field is required.'),
        description: Yup.string().required('Error! description field is required.'),
        category: Yup.string().required('Error! category is required.'),
        tag: Yup.string().required('Error! tag is required.'),
        code: Yup.string().required('Error! course code is required.'),
       // startDate: Yup.string().required('Error! start date is required.'),
       // endDate: Yup.string().required('Error! end date is required.')
    });

    const { data: categories, isLoading } = useQuery(
      ["get categories"],
      () => {
        return courses.getCategories({ perPage: 10000 });
      },
      {
        cacheTime: 10000,
        staleTime: 10000,
        refetchOnWindowFocus: true,
        select: (data: any) => data?.data,
      }
    );

    const { data: creditsCodes, isLoading: creditsCodesLoading } = useQuery(
      ["get credit codes"],
      () => {
        return courses.getCreditCodes({ perPage: 10000 });
      },
      {
        cacheTime: 10000,
        staleTime: 10000,
        refetchOnWindowFocus: true,
        select: (data: any) => data?.data,
      }
    );

    const { type, courseInfo } = props
    const isEdit = type === 'edit'

    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [logo, setLogo] = useState<string>('')
    const [useMultipleLanguages, setUseMultipleLanguages] = useState<boolean>(
      isEdit ? courseInfo?.languages?.length > 1 : false
    ); 
    const [courseLoading, setCourseLoading] = useState<boolean>(false)

    const getLanguageData = (symbol : 'en' | 'fr' | 'pr', key: string) : string => {
      return isEdit ?  
         courseInfo?.languages?.length > 1 ? 
           courseInfo?.languages?.find( (language: any) => {
             return language?.symbol === symbol
           })[key] : '' : ''
    }

    useEffect( () => {
       if(isEdit) setLogo(courseInfo?.imagePath)
    }, [courseInfo])

    const initialValues: initialValuesType = {
      title: isEdit ? courseInfo?.name : '', 
      description: isEdit ? courseInfo?.description : '',
      title_f: getLanguageData('fr', 'name'),
      description_f: getLanguageData('fr', 'description'),
      title_p: getLanguageData('pr','name'),
      description_p: getLanguageData('pr', 'description'),
      category: isEdit ? courseInfo?.categoryId :  '',
      tag: isEdit ? courseInfo?.courseTag : '',
      code: isEdit ? courseInfo?.courseCode : '',
      status: isEdit ? courseInfo?.status : 'published',
    }

    const uploadCourseImage = async ( event: React.ChangeEvent<HTMLInputElement> ) : Promise<void> => {
             setIsUploading(true)
             try{
                const file: File =  event?.target?.files![0]; 
             if (file) {
                const formData: FormData = new FormData();
                formData.append("file", file);
                const response = await createResource.uploadResource(formData)
                toast.success(response?.data?.message)
                setLogo(response?.data?.data)
                setIsUploading(false)
             }  
             } catch(error){
                setIsUploading(false)
                errorResponse(error)
             }
     }

    const createCourse = async (values: initialValuesType, action: any): Promise<any> => {
        action?.setSubmitting(false)
        if(useMultipleLanguages){
          if(!values.title_f || !values.title_p || !values.description_f || !values.description_p){
            toast.error(`Error! please add title and description for all languages.`)
            return
          } 
        }
        if(!logo){
          toast.error('Course Image is Required.')
          return
        }

            const defaultLanguage: languageType  = {
              description: values.description, name: values.title,
              language: 'english', symbol: 'en', isDefault: true
            }

            const languages: languageType[] = useMultipleLanguages ?
              [
                defaultLanguage,
              {
                description: values.description_f, name: values.title_f,
                language: 'french', symbol: 'fr', isDefault: false
              },
              {
                description: values.description_p, name: values.title_p,
                language: 'portuguese', symbol: 'pt', isDefault: false
              } 
              ] : 
              [defaultLanguage] 
              const payload = {
                categoryId: values.category,
                points: 30,
                price: 30,
                status: values.status,
                courseCode: values.code,
                duration: 500,
                imagePath: logo,
                moduleOrder: "set",
                image: "null",
                isBranded: false,
                courseTag: values.tag,
                repeatModule: false,
                languages,
              }
          try{
                toast.loading('Loading...')
                setCourseLoading(true)
                let response: any = null
                if(isEdit) response = await courses.updateCourse(payload, courseInfo?.id)
                else response = await courses.createCourse(payload)
                toast.remove()
                setCourseLoading(false)
                toast.success(response?.message)
                sessionStorage.setItem('active-course', JSON.stringify(response?.data))
                props.setStep(2);
            } catch(error:any){
              toast.remove()
              setCourseLoading(false)
              toast.error(error?.response?.data?.message)
            }
    }

    const supportedLanguages: ('English' | 'French' | 'Portuguese')[] = ['English', 'French', 'Portuguese']

    const tabsData: { label: string, content: React.ReactElement }[] = supportedLanguages.map( 
       (language: string) => { 
        return {
        label: language,
        content: (
            <>
                <section className='grid grid-cols-1 gap-5 mt-1'>
                    <div>
                        <label
                         htmlFor={language.startsWith('E') ? "title" : `title_${
                          language.toLocaleLowerCase().slice(0,1)
                         }`}
                         className='text-xs'
                        >
                          Course Title {language.startsWith('E') ? "" : `(${ language })`}
                          <span className='text-lg text-red-500'>*</span>
                        </label>
                        <Field 
                            id={language.startsWith('E') ? "title" : `title_${
                              language.toLocaleLowerCase().slice(0,1)
                             }`}
                             className="input-style bg-primaryGray" 
                            name={language.startsWith('E') ? "title" : `title_${
                              language.toLocaleLowerCase().slice(0,1)
                             }`}
                            placeholder='Type course title here...'
                        />
                        <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage 
                                name={language.startsWith('E') ? "title" : `title_${
                                 language.toLocaleLowerCase().slice(0,1)
                               }`} 
                            />
                        </p>
                    </div>
                  </section>
                  <section className='grid grid-cols-1 gap-5 mt-1'>
                      <div>
                          <label htmlFor={language.startsWith('E') ? "description" : `description_${
                          language.toLocaleLowerCase().slice(0,1)
                         }`}
                           className='text-xs'
                           >
                            Course Description 
                            {language.startsWith('E') ? "" : ` (${language })`}
                            <span className='text-lg text-red-500'>*</span>
                          </label>
                          <Field 
                              id={language.startsWith('E') ? "description" : `description_${
                                language.toLocaleLowerCase().slice(0,1)
                               }`}
                              className="input-style pt-5 pb-5 bg-primaryGray" 
                              name={language.startsWith('E') ? "description" : `description_${
                                language.toLocaleLowerCase().slice(0,1)
                               }`} 
                              placeholder='Type course description here...'
                              as='textarea'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                              <ErrorMessage 
                                   name={language.startsWith('E') ? "description" : `description_${
                                           language.toLocaleLowerCase().slice(0,1)
                                     }`}
                              />
                          </p>
                      </div>
                </section>
            </>
          )
       }}
     )

  return (
    <>  { isUploading && <ResourceLoader /> }
         <header className='flex justify-between'>
            <h3 className='text-md font-semibold'>
               Create New Course
            </h3>
            <div className='flex items-center'>
             <Link to={'/admin/course/'} className='btn-light pl-8 pr-8 bg-slate-300'>
               Back
            </Link>
              <button className='btn ml-5 bg-primary-blue'
               disabled={courseLoading}
               onClick={ () => {
                 const submitBtn = document.querySelector('#create-course-btn') as HTMLButtonElement
                  submitBtn.click()
               }}
              >
                 { courseLoading ? 'Loading...' : 'Save & Continue'}
              </button>
             </div>
         </header>
         <section className='border flex justify-between pr-0 px-7 border-slate-400 rounded-lg mx-3 mt-8'>
             <div className='w-[40%] py-7'>
                <div>
                 <h5 className='text-sm'>
                    Upload Course Media
                 </h5>
                 <input 
                    type="file" className='hidden' id='course-image'
                    onChange={uploadCourseImage}  accept="image/*" 
                  />
                  <div className='w-[90%] flex-col flex items-center justify-center mt-3 mx-auto border-2 border-dotted border-slate-300 h-[200px] rounded-lg'>
                     <label htmlFor="course-image" className='btn bg-primary-blue'>
                         Upload Image
                     </label>
                     <p className='text-xs text-center px-8 pt-4'>
                         Select clear image of your course.
                         Please note that GIF files will not animate.
                     </p>
                 </div>
                 {logo &&
                    <div className='border-2 w-[90%] mx-auto rounded-lg p-5 mt-3 border-dotted border-slate-300'>
                         <h5 className='flex justify-end '>
                            <i className='bi bi-trash text-red-500 text-sm mr-1' />
                         </h5>
                         <img src={logo} alt="course image" className='w-[200px h-[100px] block mx-auto' />
                    </div>
                  }
                </div>
             </div>
              <div className='rounded-e-lg w-[58%] bg-white p-5 px-8'>
                  <h5 className='text-lg font-semibold'>
                    Course Information
                  </h5>
                  <p className='text-xs font-semibold border-b border-gray-300 pb-2 mt-1 mb-5'>
                      Give detailed Information about the course   
                  </p>
                <div className="mb-3">
                <label className="block font-semibold w-full text-sm mb-1">
                  Would you like to add course details in other languages?
                </label>
                 <div className='flex items-center'>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={useMultipleLanguages}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUseMultipleLanguages(e.target.checked)}
                  />
                  <label className="text-xs">
                    Yes, I want to add multiple languages
                  </label>
                 </div>
               </div>
                 <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={createCourse}
                >
                    {({ values, setFieldError, setFieldValue, setFieldTouched, errors }) => (
                        <Form>
                          {useMultipleLanguages ? (
                                <HorizontalTab withPadding={false} tabsData={tabsData} />
                           ) :  <HorizontalTab  withPadding={false} tabsData={tabsData.slice(0,1)} />
                          }
                         <section className='grid grid-cols-1 gap-5 mt-1'>
                            <div>
                                <label htmlFor="category" className='text-xs'>
                                  Course Category <span className='text-lg text-red-500'>*</span>
                                </label>
                                <CustomDropdown
                                  options={ categories?.map( (category: any) => {
                                      return { label: category?.name, value: category?.id  }
                                    })
                                  }
                                  value={
                                    { 
                                       label: categories?.find( (category:any) => {
                                           return category?.id === values?.category
                                       })?.name, 
                                       value: values.category 
                                    }
                                  } 
                                  onChange={ (value:any) => {
                                    setFieldValue('category', value?.value)
                                  }}
                                  onBlur={ (value: any) => {
                                       setFieldTouched('category', true, true)
                                      if(value?.value){
                                          setFieldError('category', '')
                                        }
                                  }}
                                  name={'category'}
                                  className={'bg-primaryGray select-custom'}
                                  width={'w-full'}
                                  placeholder={'Select Category'}
                                />
                                { errors.category &&
                                  <p className='mt-2 text-red-500 text-sm'>
                                    <ErrorMessage name='category' />
                                </p>}
                            </div>
                         </section>
                         <section className='grid grid-cols-1 gap-5 mt-1'>
                            <div>
                                <label htmlFor="tag" className='text-xs'>
                                  Course Tag <span className='text-lg text-red-500'>*</span>
                                </label>
                                <Field 
                                    id='tag' className="input-style bg-primaryGray" 
                                    name='tag' placeholder='Type course tag here...'
                                />
                                <p className='mt-2 text-red-500 text-sm'>
                                    <ErrorMessage name='tag' />
                                </p>
                            </div>
                         </section>
                         <section className='grid grid-cols-1 gap-5 mt-1'>
                            <div>
                                <label htmlFor="code" className='text-xs'>
                                  Course Code <span className='text-lg text-red-500'>*</span>
                                </label>
                                <CustomDropdown
                                  options={ creditsCodes?.map( (code: any) => {
                                      return { label: code?.creditCode, value: code?.id  }
                                    })
                                  }
                                  value={
                                    { 
                                       label: creditsCodes?.find( (code:any) => {
                                           return code?.id === values?.code
                                       })?.creditCode, 
                                       value: values.code
                                    }
                                  } 
                                  onChange={ (value:any) => {
                                    setFieldValue('code', value?.value)
                                  }}
                                  onBlur={ (value: any) => {
                                          setFieldTouched('code', true, true)
                                  }}
                                  name={'code'}
                                  className={'bg-primaryGray select-custom'}
                                  width={'w-full'}
                                  placeholder={'Select code'}
                                />
                              { errors.code &&                               
                                <p className='mt-2 text-red-500 text-sm'>
                                    <ErrorMessage name='code' />
                                </p>}
                            </div>
                         </section>
                         <div className='flex mt-3 items-center justify-between pr-3'>
                             <span className='text-sm'>
                                Status (published or draft)
                             </span>
                             <Switch
                                height={20}
                                width={40}
                                handleDiameter={18}
                                checked={values.status === 'published'}
                                onChange={ () : void => {
                                    setFieldValue('status', values.status === 'published' ? 'draft' : 'published')
                                }}
                            />
                         </div>
                         <button className='hidden' id='create-course-btn' type='submit' />
                    </Form>)}
                </Formik>
             </div>
         </section>
    </>
  )
}

export default CourseInformation