import createApiClient from "./api";

const client = createApiClient();

export const api = {

  getSports: ({ orgId, search, perPage, currentPage }) =>
    client
      .get(
        `/admin/org/${orgId}/org-sports?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}`
      )
      .then(({ data }) => data),

      createSports: ({ orgId, data }) => client.post(`/admin/org/${orgId}/org-sports`, data)
                                   .then(({ data }) => data),

     updateSports: ({ orgId, data, id }) => client.patch(`/admin/org/${orgId}/org-sports/${id}`, data)
        .then(({ data }) => data),

      getQuiz: ({ orgId, search, perPage, currentPage }) =>
        client
          .get(
            `/admin/org/${orgId}/quizzes?perPage=${perPage}${
                search ? `&search=${search}` : ""
              }&page=${currentPage}`
          )
      .then(({ data }) => data),

      createQuiz: ({ orgId, data }) => client.post(`/admin/org/${orgId}/quizzes`, data)
      .then(({ data }) => data),

      updateQuiz: ({ orgId, data, id }) => client.patch(`/admin/org/${orgId}/quizzes/${id}`, data)
      .then(({ data }) => data),

      getQuizes: () => {
        // External API to get countries and their states
        const fetchCountries = async () => {
          const response = await fetch('https://pollapi.9ijakids.com/quiz/partner/fetch-quiz/c331d766-fe1f-4826-b63c-805cec637c5e');
          if (!response.ok) {
            throw new Error('Failed to fetch states');
          }
          return response.json();
        };
        return fetchCountries();
      },
};
