import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { ScaleLoader } from "react-spinners";

import toast from "react-hot-toast";
import { api } from "../../../../../api/certificate";

import CenteredModal from "../../../../../components/Modal/CenteredModal";

const ViewCertificate = ({
  openViewCertificateModal,
  setOpenViewCertificateModal,
  certificateId,
}) => {
  const user = JSON.parse(window.sessionStorage.getItem("user"));

  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [elements, setElements] = useState([]);
  const [activeElementIndex, setActiveElementIndex] = useState(null);
  const [isCardOpen, setIsCardOpen] = useState(true); // State to control card visibility
  const [logo, setLogo] = useState(null);
  const [signature1, setSignature1] = useState(null);
  const [signature2, setSignature2] = useState(null);

  const { data: certificate, isLoading } = useQuery(
    ["a-certificate", certificateId],
    async () => {
      const response = await api.getCertificate({
        orgId: user?.org?.id,
        certificateId,
      });
      const { jsonBody } = response?.data;
      const elementsRes = JSON.parse(jsonBody);

      setBackgroundImage(elementsRes?.backimage);
      setBackgroundColor(elementsRes?.bgColor);
      setElements(elementsRes?.elements);
      setLogo(elementsRes?.logo);
      setSignature1(elementsRes?.signature1);
      setSignature1(elementsRes?.signature2);

      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      enabled: !!certificateId, // only call the endpoint when certificateId is available
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  return (
    <CenteredModal
      title={""}
      hasHeader={false}
      open={openViewCertificateModal}
      setOpen={setOpenViewCertificateModal}
      width={"w-1/2"}
      height={"h-full"}
    >
      {isLoading ? (
        <div className="mt-10 flex justify-center items-center">
          <ScaleLoader color="#FF9100" />
        </div>
      ) : (
        <div
          className="relative border border-gray-300 h-screen"
          style={{
            backgroundColor: backgroundImage ? "transparent" : backgroundColor,
            backgroundImage: backgroundImage
              ? `url(${backgroundImage})`
              : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {elements?.map((element, index) => (
            <div
              key={index}
              style={{
                position: "absolute",
                top: element.y,
                left: element.x,
                width: element.width,
                height: element.height,
              }}
            >
              {element.type === "text" ? (
                <div
                  style={{
                    fontSize: `${element.fontSize}px`,
                    color: element.color,
                    fontWeight: element.fontWeight,
                    fontStyle: element.fontStyle,
                    textAlign: element.textAlign,
                  }}
                >
                  {element.content}
                </div>
              ) : element.type === "logo" ? (
                <img
                  src={element.src}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  alt="logo"
                />
              ) : // Add more element types as needed
              null}
            </div>
          ))}
        </div>
      )}
    </CenteredModal>
  );
};

export default ViewCertificate;
