import React, { useState, useEffect } from 'react'
import leftAngle from '../../../../../../assets/svgs/left-angle.svg'
import columnBarChartImg from '../../../../../../assets/column bar chart.png'
import columnStackedBarChartImg from '../../../../../../assets/stacked column bar chart.png'
import horizontalBarChartImg from '../../../../../../assets/horizontal bar chart.png'
import horizontalStackedBarChartImg from '../../../../../../assets/horizontal stacked.png'
import pieChartImg from '../../../../../../assets/pie chart.png'
import donutChartImg from '../../../../../../assets/donut.png'
import donutHalfChartImg from '../../../../../../assets/half donut.png'
import lineChartImg from '../../../../../../assets/line.png'
import progressChartImg from '../../../../../../assets/progress bar.png'
import dotChartImg from '../../../../../../assets/dots.png'
import areaChartImg from '../../../../../../assets/area-chart.png'
import pyramidChartImg from '../../../../../../assets/pyramid.png'
import BarchartInputTable from './BarchartInputTable'
import { useReportEditor } from '../../hooks/ReportEditorContext'
import { tableData } from '../CreateReport'

type chart = {
    id: string
    title: string
    image: string
}

type chartType = {
    barchart: chart[],
    piechart: chart[],
    otherchart: chart[]
}

type ChartActiveTab = 'settings' | 'chart data' | 'import data' 

type selectedChartType = null | 'barchart' | 'piechart' | 'otherchart'

type ChartsWidgetProps = {
    tableData: tableData
    setTableData: React.Dispatch<React.SetStateAction<tableData>>
}
const ChartsWidget: React.FC<ChartsWidgetProps> = ({tableData, setTableData}): JSX.Element => {

 const charts: chartType = {
    barchart: [
        {
            id: '1',
            title: 'Column Bar Chart    ',
            image: columnBarChartImg
        },
        {
            id: '2',
            title: 'Stacked Column Bar Chart',
            image: columnStackedBarChartImg
        },
        {
            id: '3',
            title: 'Horizontal Bar Chart',
            image: horizontalBarChartImg
        },
        {
            id: '4',
            title: 'Stacked Horizontal Bar Chart',
            image: horizontalStackedBarChartImg
        },
        {
            id: '5',
            title: 'Progress Chart',
            image: progressChartImg
        }
     ],
     piechart: [
        {
            id: '1',
            title: 'Pie Chart',
            image: pieChartImg
        },
        {
            id: '2',
            title: 'Donut Chart',
            image: donutChartImg
        },
        {
            id: '3',
            title: 'Half Donut Chart',
            image: donutHalfChartImg
        }
     ],
     otherchart: [
        {
            id: '1',
            title: 'Dot Chart',
            image: dotChartImg
        },
        {
            id: '2',
            title: 'Area Chart',
            image: areaChartImg
        },
        {
            id: '3',
            title: 'Pyramid Chart',
            image: pyramidChartImg
        },
        {
            id: '4',
            title: 'Line Chart',
            image: lineChartImg
        }
     ]
 }

 const [selectedChart, setSelectedChart] = useState<selectedChartType>(null)
 const [openTable, setOpenTable] = useState<boolean>(false)
 const { addElement, elements, setElements } = useReportEditor();
 const chartTableTabs: ChartActiveTab[] = ['chart data', 'import data', 'settings']
 const [activeTab, setActiveTab] = useState<ChartActiveTab>('chart data')

 useEffect(() => {
   if (selectedChart === 'barchart') {
     const updatedElements = elements.map((element: any) => {
       if (element.type === 'chart') {
         return {
           ...element,
           chartConfig: {
             ...element.chartConfig,
             data: {
               ...element.chartConfig.data,
               labels: tableData.body.map(row => row[1]),
               datasets: [{
                 ...element.chartConfig.data.datasets[0],
                 data: tableData.body.map(row => Number(row[2])) // Using Series 1 data
               }]
             }
           }
         }
       }
       return element
     })
     setElements(updatedElements)
   }
 }, [tableData])

 const handleChartClick = (chartType: string) => {
    if (chartType === 'Column Bar Chart') {
      const chartConfig = {
        type: 'bar',
        data: {
          labels: tableData.body.map(row => row[1]),
          datasets: [{
            label: tableData.headers[2], // Series 1 label
            data: tableData.body.map(row => Number(row[2])), // Series 1 data
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(54, 162, 235, 0.5)',
              'rgba(255, 206, 86, 0.5)',
              'rgba(75, 192, 192, 0.5)',
              'rgba(153, 102, 255, 0.5)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Sample Bar Chart'
            }
          }
        }
      };

      addElement('chart', '', chartConfig);
      setOpenTable(true)
      setSelectedChart('barchart')
    }
  };

  return (
    <div>
        <header className='flex border-b border-slate-300 pb-2 items-center mt-5'>
           <img src={leftAngle} className='w-4 h-4' alt="left-angle" />
           <span className='text-sm pl-5'>Charts & Graphs</span>
        </header>
        {
         !openTable && (
            <h6 className='text-sm text-slate-950 font-medium mt-5'>Bar Charts</h6>
         )
        }
            {
                (openTable && selectedChart === 'barchart') ? (
                    <section>
                        <div className='grid grid-cols-3 mt-3 gap-1'>
                            {
                                chartTableTabs.map((tab) => (
                                    <button key={tab} 
                                    className={`text-xs py-2 text-slate-950 px-1 capitalize font-medium ${
                                               activeTab === tab ? 'text-slate-800 bg-[#BFD1E3]' 
                                               : 'bg-white text-slate-950'}`
                                             }
                                    onClick={() => setActiveTab(tab)}
                                    >
                                        {tab}
                                    </button>
                                ))
                            }
                        </div>
                        <BarchartInputTable
                            tableData={tableData}
                            setTableData={setTableData}
                        />
                        <section className='mt-2 flex justify-center'>
                            <button 
                              className='btn-light bi bi-plus-circle pt-2 text-[10px] pb-2 bg-slate-200'
                              onClick={() => {
                                // Create empty strings based on number of series (headers - 2)
                                const numSeries = tableData.headers.length - 2;
                                const emptyValues = Array(numSeries).fill('');
                                
                                setTableData({
                                    ...tableData,
                                    body: [...tableData.body, [
                                        tableData.body.length + 1, 'label', ...emptyValues
                                    ]]
                                })
                              }}
                            >
                                &nbsp;Add Row
                            </button>
                            <button 
                              className='btn-light bi bi-plus-circle pt-2 text-[10px] ml-3 pb-2 bg-slate-200'
                              onClick={() => {
                                setTableData({
                                  ...tableData,
                                  headers: [...tableData.headers, `Series ${(tableData.headers.length - 2 ) + 1}`],
                                  body: tableData.body.map(row => [...row, ''])
                                })
                              }}
                            >
                                &nbsp;Add Series
                            </button>
                        </section>
                    </section>
                ) : 
                <section className='mt-3 grid grid-cols-3 gap-3'>
                   { charts.barchart.map((chart) => (
                    <div 
                      key={chart.id} 
                      className='hover:cursor-pointer hover:scale-110 transition-all duration-300 w-full h-[80px] bg-white rounded-md'>
                        <img 
                          src={chart.image} 
                          alt={chart.title} 
                          onClick={() => handleChartClick(chart.title.trim())}
                          className='w-[100px] h-[70px]'
                         />
                    </div>
                ))}
                </section>
            }
        <h6 className='text-sm text-slate-950 font-medium mt-5'>Pie Charts</h6>
        <section className='mt-3 grid grid-cols-3 gap-3'>
            {
                charts.piechart.map((chart) => (
                    <div key={chart.id} className='hover:cursor-pointer hover:scale-110 transition-all duration-300 w-full h-[80px] bg-white rounded-md'>
                        <img 
                          src={chart.image} 
                          alt={chart.title} 
                          className='w-[100px] h-[70px]'
                         />
                    </div>
                ))
            }
        </section>
        <h6 className='text-sm text-slate-950 font-medium mt-5'>Other Charts</h6>
        <section className='mt-3 grid grid-cols-3 gap-3'>
            {
                charts.otherchart.map((chart) => (
                    <div key={chart.id} className='hover:cursor-pointer hover:scale-110 transition-all duration-300 w-full h-[80px] bg-white rounded-md'>
                        <img 
                          src={chart.image} 
                          alt={chart.title} 
                          className='w-[100px] h-[70px]'
                         />
                    </div>
                ))
            }
        </section>
    </div>
  )
}

export default ChartsWidget