import React, { createContext, useContext, useState } from "react";
import { Chart as ChartJS } from 'chart.js/auto';

// Create the context
const ReportEditorContext = createContext();

// Custom hook to use the context
export const useReportEditor = () => useContext(ReportEditorContext);

// Provider component
export const ReportEditorProvider = ({ children }) => {
  const [elements, setElements] = useState([]);
  const [activeElementIndex, setActiveElementIndex] = useState(null);


  const addElement = (type, imageUrl = '', chartConfig = null) => {
    const newElement = {
      type: type,
      content: type === "heading"
        ? "Heading Text"
        : type === "subheading"
        ? "Subheading Text"
        : type === "image"
        ? ""
        : type === "chart"
        ? ""
        : "Body Text",
      fontSize: type === "heading" ? 32 : type === "subheading" ? 24 : 16,
      color: "#000",
      fontWeight: type === "heading" ? "bold" : type === "subheading" ? "600" : "normal",
      fontStyle: "normal",
      fontFamily: "calibri",
      textAlign: "right",
      letterSpacing: 0,
      lineHeight: 1.5,
      opacity: 1,
      x: 50,
      y: 50,
      editable: type !== "image" && type !== "chart",
      imageUrl: type === "image" ? imageUrl : null,
      chartConfig: type === "chart" ? chartConfig : null,
      width: (type === "image" || type === "chart") ? 400 : "auto",
      height: (type === "image" || type === "chart") ? 300 : "auto",
    };
    setElements([...elements, newElement]);
    setActiveElementIndex(elements.length);
  };


  const updateElementPosition = (index, x, y) => {
    const updatedElements = [...elements];
    updatedElements[index].x = x;
    updatedElements[index].y = y;
    setElements(updatedElements);
  };

  const updateActiveElement = (key, value) => {
    if (activeElementIndex !== null) {
      const updatedElements = [...elements];
      updatedElements[activeElementIndex][key] = value;
      setElements(updatedElements);
    }
  };

  const duplicateElement = (index) => {
    const elementToDuplicate = elements[index];
    const duplicatedElement = {
      ...elementToDuplicate,
      x: elementToDuplicate.x + 20,
      y: elementToDuplicate.y + 20,
    };
    setElements([...elements, duplicatedElement]);
  };

  const deleteElement = (index) => {
    const updatedElements = elements.filter((_, i) => i !== index);
    setElements(updatedElements);
    setActiveElementIndex(updatedElements.length > 0 ? null : updatedElements.length - 1);
  };

  return (
    <ReportEditorContext.Provider value={{ elements, setElements,activeElementIndex,
        addElement,
        updateElementPosition,
        updateActiveElement,
        duplicateElement,
        deleteElement,
        setActiveElementIndex, }}>
      {children}
    </ReportEditorContext.Provider>
  );
};


