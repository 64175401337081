import React, { useState } from 'react'
import profileIcon from '../../../../../assets/profile-add.png'
import { demographyType } from './AccountDemographics'
import ConfirmModal from './ConfimationModal'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { api as auth } from '../../../../../api' 

const Confirmation = () => {

  const profileInformation = JSON.parse(sessionStorage.getItem('profile-information')!)
  const businessInformation = JSON.parse(sessionStorage.getItem('buisness-setup')!)
  const accountCustomization = JSON.parse(sessionStorage.getItem('account-customization')!)
  const demographics = JSON.parse(sessionStorage.getItem('account-demographics')!)
  const orgId = JSON.parse(sessionStorage.getItem('user')!) 
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

  const saveChanges = async () : Promise<void> => {
    try{
      toast.loading('Loading...')
      const response: any = await auth.setupConfirmation({
        id: orgId?.user_id,
        orgId: orgId?.org?.id
      })
      toast.remove()
      toast.success(response?.message)
      setShowConfirmationModal(true)
    }catch(error: any){
      toast.remove()
      toast.error(error?.response?.data?.message)
    }
  } 
   
  return (
    <div>
       {showConfirmationModal && 
         <ConfirmModal
           openModal={showConfirmationModal}
           setOpenModal={setShowConfirmationModal}
           title='Successful!'
           text={`You have successfully created an account, you can 
                 now select what you’d love to do next from the options listed below.`
               }
          footer={
            <>
              <Link
                className={`py-3 px-3 text-center bg-secondary text-white rounded-md text-xs`}
                to={'/'}
              >
                Invite Other Admin users
              </Link>
              <Link
                className={`py-3 px-3 text-center bg-secondary text-white rounded-md text-xs`}
                to={'/'}
              >
                Set Up Cohort
              </Link>
              <Link
                className={`py-3 px-3 text-center bg-secondary text-white rounded-md text-xs`}
                to={'/'}
              >
                Top Up Credit
              </Link>
              <Link
                className={`py-3 px-3 text-center bg-secondary text-white rounded-md text-xs`}
                to={'/admin/dashboard'}
              >
                Proceed to dashboard
              </Link>
            </>
          }
         />
       }
        <h5 className='text-lg font-semibold'>
           Confirmation
        </h5>
        <p className='text-sm font-light'>
           Confirm your data input to successfully set up your account
        </p>
        <header className='py-5 pb-2 flex justify-center items-center'>
        <div className={`w-[100px] h-[100px] ${
            !profileInformation.photo ? 'bg-slate-200' : ''
          } rounded-full flex justify-center items-center`}>
            <img src={ profileInformation.photo ? profileInformation.photo : profileIcon} 
              className='w-[80px] rounded-full h-[80px]' alt="profile icon"
            />
        </div>
        </header>
        <section>
          <h5 className='text-sm font-semibold'>
            Profile Information
          </h5>
            <section className='grid grid-cols-2 gap-5 mt-2'>
                       <div>
                        <label htmlFor="name" className='text-xs'>
                           Full Name  
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={profileInformation?.name}
                          />
                       </div>
                       <div>
                        <label htmlFor="email" className='text-xs'>
                           Email Address  
                        </label>
                         <input 
                             id='email' type='email' className="input-style bg-primaryGray" 
                             name='email' readOnly value={profileInformation?.email}
                          />
                       </div>
            </section>
            <section className='grid grid-cols-2 gap-5 mt-4'>
                       <div>
                        <label htmlFor="name" className='text-xs'>
                          Phone Number 
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={profileInformation?.number}
                          />
                       </div>
                       <div>
                        <label htmlFor="email" className='text-xs'>
                           Role
                        </label>
                         <input 
                             id='email' type='email' className="input-style bg-primaryGray" 
                             name='email' readOnly value={profileInformation?.role}
                          />
                       </div>
            </section>
        </section>
        <section>
          <h5 className='text-sm mt-8 font-semibold'>
            Business Information
          </h5>
            <section className='grid grid-cols-2 gap-5 mt-2'>
                       <div>
                        <label htmlFor="name" className='text-xs'>
                           Business Name 
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={businessInformation?.name}
                          />
                       </div>
                       <div>
                        <label htmlFor="email" className='text-xs'>
                          Business  Address  
                        </label>
                         <textarea 
                             id='text' className="input-style bg-primaryGray" 
                             name='text' readOnly value={businessInformation?.address}
                          />
                       </div>
            </section>
            <section className='grid grid-cols-2 gap-5 mt-4'>
                       <div>
                        <label htmlFor="name" className='text-xs'>
                          County 
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={businessInformation?.country}
                          />
                       </div>
                       <div>
                        <label htmlFor="email" className='text-xs'>
                           State
                        </label>
                         <input 
                             id='email' type='email' className="input-style bg-primaryGray" 
                             name='email' readOnly value={businessInformation?.state}
                          />
                       </div>
            </section>
            <section className='grid grid-cols-2 gap-5 mt-4'>
                       <div>
                        <label htmlFor="name" className='text-xs'>
                          Business Contact Number
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={businessInformation?.number}
                          />
                       </div>
                       <div>
                        <label htmlFor="email" className='text-xs'>
                          Business Email Address
                        </label>
                         <input 
                             id='email' type='email' className="input-style bg-primaryGray" 
                             name='email' readOnly value={businessInformation?.email}
                          />
                       </div>
            </section>
            <section className='grid grid-cols-2 gap-5 mt-4'>
                       <div>
                        <label htmlFor="name" className='text-xs'>
                          Business Type
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={businessInformation?.type}
                          />
                       </div>
                       <div>
                        <label htmlFor="email" className='text-xs'>
                          Business Size
                        </label>
                         <input 
                             id='email' type='email' className="input-style bg-primaryGray" 
                             name='email' readOnly value={businessInformation?.size}
                          />
                       </div>
            </section>
        </section>
        <section>
          <h5 className='text-sm mt-8 font-semibold'>
            Brand Cusomization
          </h5>
          <div className='flex mt-5'>
              <div>
                <p className='text-xs mb-3 underline'>Desktop Logo</p>
                 <img
                     src={accountCustomization?.logo} 
                    alt="desktop logo" className='w-[100px] h-[60px]' 
                />
              </div>
              <div className='pl-8'>
              <p className='text-xs mb-3 underline'>Mobile Logo</p>
                 <img
                     src={accountCustomization?.logo} 
                    alt="desktop logo" className='w-[70px] h-[50px]' 
                />
              </div>
          </div>
        </section>
        <section>
              <h4 className='text-sm font-semibold mt-8'>Brand Colors</h4>
                      <div className='mt-3 grid py-5 grid-cols-3 gap-5'>
                            <div className='flex items-center'>
                                <input type="color" id='theme-color' value={accountCustomization?.theme} />
                                <label htmlFor="theme-color" className='pl-2 text-xs'>
                                    Theme
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <input type="color" id='body-color' value={accountCustomization?.bodyColor}  />
                                <label htmlFor="body-color" className='pl-2 text-xs'>
                                    Body Background Color
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <input type="color" id='body-text' value={accountCustomization?.bodyTextColor}  />
                                <label htmlFor="body-text" className='pl-2 text-xs'>
                                    Primary Text Color
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <input type="color" id='button-color' value={accountCustomization?.primaryButtonColor}  />
                                <label htmlFor="button-color" className='pl-2 text-xs'>
                                    Primary Button Color
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <input type="color" id='p-body-color' value={accountCustomization?.primaryButtonTextColor}  />
                                <label htmlFor="p-body-color" className='pl-2 text-xs'>
                                    Primary Button text Color
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <input type="color" id='s-body' value={accountCustomization?.secondaryButtonColor}  />
                                <label htmlFor="s-body" className='pl-2 text-xs'>
                                    Secondary Button Color
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <input type="color" id='s-body-text' value={accountCustomization?.secondaryButtonTextColor}  />
                                <label htmlFor="s-body-text" className='pl-2 text-xs'>
                                    Secondary Button Text Color
                                </label>
                            </div>
                      </div>
        </section>
        <section>
              <h4 className='text-sm font-semibold mt-8'>Brand Text</h4>
              <section className='grid grid-cols-2 gap-5 mt-4'>
                    <div>
                        <label htmlFor="name" className='text-xs'>
                          Font Family
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={accountCustomization?.fontFamily}
                          />
                       </div>
                    <div>
                        <label htmlFor="name" className='text-xs'>
                          Font Style
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={accountCustomization?.fontStyle}
                        />
                    </div>
            </section>
            <section className='grid grid-cols-2 gap-5 mt-4'>
                    <div>
                        <label htmlFor="name" className='text-xs'>
                          Font Size
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={accountCustomization?.normalFont}
                          />
                       </div>
          </section>
        </section>
        <section>
              <h4 className='text-sm font-semibold mt-8'>Platform Setting</h4>
              <section className='grid grid-cols-2 gap-5 mt-4'>
                    <div>
                        <label htmlFor="name" className='text-xs'>
                          TimeZone
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={accountCustomization?.timeZone}
                          />
                       </div>
                    <div>
                        <label htmlFor="name" className='text-xs'>
                            Language
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={accountCustomization?.language}
                        />
                    </div>
            </section>
            <section className='grid grid-cols-2 gap-5 mt-4'>
                    <div>
                        <label htmlFor="name" className='text-xs'>
                           Date Format
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={accountCustomization?.dateFormat}
                          />
                       </div>
                       <div>
                        <label htmlFor="name" className='text-xs'>
                           Time Format
                        </label>
                         <input 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' readOnly value={accountCustomization?.timeFormat}
                          />
                       </div>
          </section>
        </section>
        {demographics?.length &&
        <section>
              <h4 className='text-sm font-semibold mt-8'>Account demographics</h4>
              <div className='p-3 rounded border border-gray-200 mt-4'>
              <div className='flex border-b border-slate-200 pb-2 mb-4 justify-between'>
                         <div className='flex'>
                             <span className='text-xs mr-8'>
                               Field Name
                            </span>
                            <span className='text-xs'>
                               Category
                            </span>
                         </div>
                        <div className='flex'>
                           <span className='text-xs bg-slate-100 rounded-full w-[80px] text-center mr-5 pt-1'>
                             Mandatory 
                           </span>
                           <span className='text-xs w-[110px]'>Type</span>
                        </div>
                     </div>
              {
                demographics?.length ?
                 demographics?.map( (demography: demographyType)  => {
                   return ( 
                     <div key={demography?.id} className='flex border-b border-slate-200 pb-2 mb-4 justify-between'>
                         <div className='flex'>
                             <span className='text-xs mr-8'>
                               {demography?.fieldName}
                            </span>
                            <span className='text-xs'>
                               ({demography?.fieldCategory})
                            </span>
                         </div>
                        <div className='flex'>
                           <span className='text-xs bg-slate-100 rounded-full w-[80px] text-center mr-5 pt-1'>
                             {demography?.isMandatory ? 'mandatory' : 'optional' }
                           </span>
                           <span className='text-xs w-[110px]'>{demography?.fieldType}</span>
                        </div>
                     </div>
                   )
                 } )
                : null
               }
              </div>
        </section>
        }
        <section className='mt-8 flex justify-end'>
               <button onClick={saveChanges} className='bg-secondary text-white text-sm py-2 px-8 rounded'>
                     Save Changes
               </button>
         </section>
    </div>
  )
}

export default Confirmation