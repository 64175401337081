import React, {useEffect, useState} from 'react'
import Button from '../../../../../../../components/Buttons/Button'
import Courses from './components/Courses'
import Search from '../../../../../../../components/Inputs/Search'
import DiscountCard from '../../../client-admin/pricing/components/DiscountCard'
import { api } from '../../../../../../../api/billing'
import { useQuery } from 'react-query'
import EmptyData from '../../../../../../../components/EmptyData'
import Loader from '../../../../../../../components/loader/Loader'
import CreateDiscount from './components/CreateDiscount'

const PricingAndDiscount = () => {

  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false)
  const [activeDiscount, setActiveDiscount] = useState(null)

  const {
    data: discounts,
    isLoading,
    refetch,
  } = useQuery(
    ["client discounts"],
    ({ queryKey }) => {
      return api.getBillingDiscounts({ ...queryKey[1], perPage, currentPage });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  useEffect( () => {
    refetch()
  }, [ currentPage, perPage ] )

  return (
    <div className=' p-4'>
        <header className='text-lg font-semibold flex justify-between items-center'>
            Pricing & Discount
            <div className='flex items-center'>
                <Button text={'Generate Report'} width={'w-[160px]'} bgColor={'bg-darkBlue'} />
            </div>
        </header>
        <section className='mt-3 rounded-lg p-3 bg-white'>
            <h5 className='pb-3 flex justify-between items-center border-b font-medium border-gray-200 text-md'>
                  Courses
                  <Search className={'w-[300px]'} placeholder={'Search course'} />
            </h5>
            <Courses />
        </section>
        <section className='mt-3 rounded-lg pt-2 p-3 py-5 bg-white'>
            <h5 className='pb-2 border-b font-medium flex justify-between items-center border-gray-200 text-md'>
                  Course Quantity Discounts
                  <Button 
                     text={'Create Discount'} 
                     width={'w-[200px]'} 
                     onClick={ () => setOpenModal(true) }
                  />
            </h5>
             <CreateDiscount 
               openModal={openModal}
               setOpenModal={setOpenModal}
               refetch={refetch}
               activeDiscount={activeDiscount}
               setActiveDiscount={setActiveDiscount}
             />
            {
              isLoading ? <Loader /> :
               discounts?.data?.length ?
              <div className='grid grid-cols-3 mt-3 gap-5'>
                 {
                   discounts?.data?.map( discount => {
                      return(
                        <DiscountCard
                          key={discount?.id}
                          title={discount?.name}
                          text={discount?.description}
                          discountCode={discount?.discountCode}
                          discount={discount?.percentOff}
                          contentEditable
                          handleEdit={ () => {
                            setActiveDiscount(discount)
                            setOpenModal(true)
                          } }
                          coursesRequired={discount?.numberOfCoursesRequired}
                        />
                      )
                   } )
                 }
              </div>
              :
              <EmptyData text={'No any discount avalilabe yet.'} />
            }
        </section>
    </div>
  )
}

export default PricingAndDiscount