import React, { useEffect, useState } from "react";
import { activeCohorts } from "./data";
import Table from "../../../../../components/table/Table";
import Progress from "../../../../../components/progress/Progress";
import Search from "../../../../../components/Inputs/Search";
import { dashboardApi } from "../../../../../api/dashboard";
import { useQuery } from "react-query";
import Pagination from "../../../../../components/pagination/Pagination";
import EmptyData from "../../../../../components/EmptyData";
import { formatCurrency } from "../../billing/site-admin/pages/summary/Summary";
import Loader from "../../../../../components/loader/Loader";

const ActiveCohorts = () => {
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const orgId = JSON.parse(sessionStorage.getItem("user"))?.org?.id;

  const {
    data: cohorts,
    isLoading,
    refetch,
  } = useQuery(
    ["client dashboard active cohorts"],
    () => {
      return dashboardApi.getClientActiveCohorts({
        orgId,
        perPage,
        currentPage,
        search,
      });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [currentPage, perPage, search, isLoading, setSearch]);

  let columns = React.useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) => index + 1, //+
        //((clients?.meta?.page || 1) - 1) * (clients?.meta?.perPage || perPage ),
      },
      {
        title: "Cohort",
        selector: "cohortName",
        key: "cohortName",
        render: ({ item, row }) => {
          return <span className="">{item}</span>;
        },
      },
      {
        title: "Learners",
        selector: "numberOfLearners",
        key: "numberOfLearners",
        render: ({ item }) => {
          return (
            <span className="pl-3">
              {formatCurrency(item, false) || "-------"}
            </span>
          );
        },
      },
      {
        title: "Completed",
        selector: "numberOfCompletedLearners",
        key: "numberOfCompletedLearners",
        render: ({ item }) => {
          return <span className="pl-5"> {item} </span>;
        },
      },
      {
        title: "Active",
        selector: "numberOfActiveLearners",
        key: "numberOfActiveLearners",
        render: ({ item }) => {
          return <span className="pl-2">{item}</span>;
        },
      },
      {
        title: "Progress",
        selector: "progressPercentage",
        key: "progressPercentage",
        render: ({ item, row }) => {
          return (
            <span className="" data-tooltip-id={row?.cohortId}>
              <Progress
                withTooltip
                id={row?.cohortId}
                width={80}
                value={parseInt(item)}
              />
            </span>
          );
        },
      },
      {
        title: "Credit Used",
        selector: "creditUsed",
        key: "creditUsed",
        render: ({ item }) => {
          return <span className="">{formatCurrency(item, false)}</span>;
        },
      },
    ],
    [1, 20, 20]
  );

  const tableProps = { isLoading, columns, data: cohorts?.data };

  if (isLoading) return <Loader />;

  return (
    <div className="bg-white p-3 rounded-lg shadow">
      <h5 className="text-md items-center font-medium pb-2 border-b border-b-gray-300 flex justify-between">
        Active Cohorts ({cohorts?.meta?.total})
        <Search
          className={"w-[200px]"}
          placeholder={"Search Cohort"}
          mb="mb-0"
          onChange={setSearch}
          onBlur={() => refetch()}
        />
      </h5>
      {cohorts?.data?.length ? (
        <Table {...tableProps} />
      ) : (
        <EmptyData text={"There is no any active cohort available yet."} />
      )}
      <Pagination
        data={cohorts?.meta}
        perPage={perPage}
        pageNo={currentPage}
        setPageNo={setCurrentPage}
        setPerPage={setPerPage}
      />
    </div>
  );
};

export default ActiveCohorts;
