import React, { useState } from 'react'
import leftAngle from '../../../../../../assets/svgs/left-angle.svg'
import sortBy from '../../../../../../assets/svgs/sort icon.svg'
import GearIcon from '../../../../../../assets/svgs/gear icon.svg'
import Search from '../../../../../../components/Inputs/Search'

const DataWidget: React.FC<any> = () : JSX.Element => {

  const [search, setSearch] = useState<string>('')

  return (
    <div>
        <header className='flex border-b border-slate-300 pb-2 items-center mt-5'>
           <img src={leftAngle} className='w-4 h-4' alt="left-angle" />
           <span className='text-sm pl-5'>Data Widget</span>
        </header>
        <section className='mt-3'>
            <Search
                onChange={setSearch}
                placeholder={"Search data widget..."}
                className={"w-full mb-3"}
                bgColor={null}
                iconColor={null}
                onClick={null}
                width={'w-full'}
                value={null}
                mb={''}
                showButton={false}
              />
        </section>
        <section className='flex justify-between mt-1'>
          <button className='btn-light border pt-2 pb-2 flex justify-center items-center border-slate-200'>
             <img src={sortBy} alt="sort by" /> &nbsp; Sort by
          </button>
          <button className='btn-light border pt-2 pb-2 flex justify-center items-center border-slate-200'>
             <img src={GearIcon} alt="sort by" /> &nbsp; Settings
          </button>
        </section>
    </div>
  )
}

export default DataWidget