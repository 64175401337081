import React, { useState, useEffect, useRef } from "react";
import { v4 } from "uuid";
import { useReportEditor } from "../../hooks/ReportEditorContext";
import { Rnd } from "react-rnd";
import ChartComponent from './Chart';

type PageType = {
  style?: React.CSSProperties;
  pageIndex?: number;
  setPages?: React.Dispatch<React.SetStateAction<any[]>>;
  newPage?: any;
  pages?: any[];
};

const Page: React.FC<PageType> = ({
  style,
  pageIndex,
  setPages,
  newPage,
  pages,
}) => {
  const [showActions, setShowActions] = useState<boolean>(false);
  const actionsRef = useRef<HTMLDivElement>(null);
  const {
    elements,
    setElements,
    activeElementIndex,
    setActiveElementIndex,
    updateElementPosition,
    updateActiveElement,
    duplicateElement,
    deleteElement,
  } = useReportEditor();
  const [isCardOpen, setIsCardOpen] = useState(false);

 
  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        actionsRef.current &&
        !actionsRef.current.contains(event.target as Node)
      ) {
        setShowActions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addNewPage = () => {
    if (pages) setPages?.([...pages, { id: v4(), Page: Page }]);
  };

  return (
    <div style={style}>
      <h6 className="text-xs mt-2"> Page {pageIndex || ""} </h6>
      <div className="relative">
        <section
          className="mt-1 h-[450px] overflow-y-auto bg-white border border-slate-300 p-3 relative overflow-hidden"
          onMouseEnter={() => setShowActions(true)}
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          {elements.map((element: any, index: number) => (
            <Rnd
              key={index}
              size={{ 
                width: element.type === 'image' || element.type === 'chart' ? element.width : 'auto', 
                height: element.type === 'image' || element.type === 'chart' ? element.height : 'auto' 
              }}
              position={{ x: element.x, y: element.y }}
              onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
              resizable={element.type === 'image' || element.type === 'chart'}
              onResize={(e, direction, ref, delta, position) => {
                if (element.type === 'image' || element.type === 'chart') {
                  const updatedElements = [...elements];
                  updatedElements[index] = {
                    ...updatedElements[index],
                    width: ref.offsetWidth,
                    height: ref.offsetHeight,
                    x: position.x,
                    y: position.y
                  };
                  setElements(updatedElements);
                }
              }}
              minWidth={50}
              minHeight={50}
              bounds="parent"
              onClick={() => {
                setActiveElementIndex(index);
                setIsCardOpen(true);
              }}
            >
              {element.type === 'chart' ? (
                <div style={{ 
                  width: '100%', 
                  height: '100%',
                  border: activeElementIndex === index ? "1px solid blue" : "none",
                }}>
                  <ChartComponent 
                    config={element.chartConfig}
                    width={element.width}
                    height={element.height}
                  />
                  {activeElementIndex === index && (
                    <>
                      <div className="absolute w-2 h-2 bg-blue-500 top-0 left-0 cursor-nw-resize" />
                      <div className="absolute w-2 h-2 bg-blue-500 top-0 right-0 cursor-ne-resize" />
                      <div className="absolute w-2 h-2 bg-blue-500 bottom-0 left-0 cursor-sw-resize" />
                      <div className="absolute w-2 h-2 bg-blue-500 bottom-0 right-0 cursor-se-resize" />
                    </>
                  )}
                </div>
              ) : element.type === 'image' ? (
                <div style={{ 
                  width: '100%', 
                  height: '100%',
                  border: activeElementIndex === index ? "1px solid blue" : "none",
                }}>
                  <img
                    src={element.imageUrl}
                    alt="Report element"
                    style={{
                      width: '100%',
                      height: '100%',
                      opacity: element.opacity,
                      objectFit: 'contain'
                    }}
                  />
                  {/* Resize handles for better visibility */}
                  {activeElementIndex === index && (
                    <>
                      <div className="absolute w-2 h-2 bg-blue-500 top-0 left-0 cursor-nw-resize" />
                      <div className="absolute w-2 h-2 bg-blue-500 top-0 right-0 cursor-ne-resize" />
                      <div className="absolute w-2 h-2 bg-blue-500 bottom-0 left-0 cursor-sw-resize" />
                      <div className="absolute w-2 h-2 bg-blue-500 bottom-0 right-0 cursor-se-resize" />
                    </>
                  )}
                </div>
              ) : (
                <div
                  className="cursor-pointer"
                  style={{
                    fontSize: `${element.fontSize}px`,
                    color: element.color,
                    fontWeight: element.fontWeight,
                    fontStyle: element.fontStyle,
                    textAlign: element.textAlign,
                    fontFamily: element.fontFamily,
                    letterSpacing: `${element.letterSpacing}px`,
                    lineHeight: element.lineHeight,
                    opacity: element.opacity,
                    padding: "5px",
                    border: activeElementIndex === index ? "1px solid blue" : "none",
                  }}
                  contentEditable={element.editable}
                  suppressContentEditableWarning={true}
                >
                  {element.content}
                </div>
              )}
              {/* Action Buttons */}
              {isCardOpen && activeElementIndex === index && (
                <div
                  ref={cardRef}
                  className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl"
                >
                  {/* Duplicate Button */}
                  <button
                    onClick={() => duplicateElement(index)}
                    className="m-1 p-1 text-sm rounded relative group"
                  >
                    <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                    <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                      Duplicate
                    </div>
                  </button>

                  {/* Delete Button */}
                  <button
                    onClick={() => deleteElement(index)}
                    className="m-1 p-1 text-sm rounded relative group"
                  >
                    <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                    <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                      Delete
                    </div>
                  </button>
                </div>
              )}
            </Rnd>
          ))}

          {/* <div id="placeholder1" style={{ position: 'relative' }}>
              <TextPlaceHolder id="placeholder1" />
            </div>
            <div id="placeholder2" style={{ position: 'relative' }}>
              <TextPlaceHolder id="placeholder2" />
            </div> */}
          </section>
          {showActions && (
            <div ref={actionsRef} className='absolute top-1/2 -right-12 -translate-y-1/2 bg-white shadow-lg rounded-lg p-2'>
               <button
                className='block p-2 hover:bg-gray-100 rounded-lg'
                onClick={addNewPage}
                >
                <i className='bi bi-file-earmark-plus text-gray-600'></i>
              </button>
              <button className='block p-2 hover:bg-gray-100 rounded-lg'>
                <i className='bi bi-copy text-gray-600'></i>
              </button>
              <button
                className='block p-2 hover:bg-gray-100 rounded-lg'
                 onClick={() => {
                  if(pages) setPages?.([...pages].filter(page => page.id !== newPage.id))
                 }}   
              >
                <i className='bi bi-trash text-red-600'></i>
              </button>
              <button 
                className='block p-2 hover:bg-gray-100 rounded-lg'
                onClick={() => {
                  if(pages) {
                    const currentIndex = pages.findIndex(page => page.id === newPage.id);
                    if (currentIndex > 0) {
                      const newPages = [...pages];
                      const temp = newPages[currentIndex];
                      newPages[currentIndex] = newPages[currentIndex - 1];
                      newPages[currentIndex - 1] = temp;
                      if(pages) setPages?.(newPages);
                      // Add transition class to affected pages
                      document.getElementById(newPage.id)?.classList.add('transition-transform', 'duration-300');
                      document.getElementById(pages[currentIndex - 1].id)?.classList.add('transition-transform', 'duration-300');
                    }
                  }
                }}
              >
                <i className='bi bi-caret-up text-gray-600'></i>
              </button>
              <button 
                className='block p-2 hover:bg-gray-100 rounded-lg'
                onClick={() => {
                  if(pages) {
                    const currentIndex = pages.findIndex(page => page.id === newPage.id);
                    if (currentIndex < pages.length - 1) {
                      const newPages = [...pages];
                      const temp = newPages[currentIndex];
                      newPages[currentIndex] = newPages[currentIndex + 1];
                      newPages[currentIndex + 1] = temp;
                      if(pages) setPages?.(newPages);
                      // Add transition class to affected pages
                      document.getElementById(newPage.id)?.classList.add('transition-transform', 'duration-300');
                      document.getElementById(pages[currentIndex + 1].id)?.classList.add('transition-transform', 'duration-300');
                    }
                  }
                }}
              >
                <i className='bi bi-caret-down text-gray-600'></i>
              </button>
            </div>
          )}
        </div>
    </div>
  );
};

export default Page;
