import React from 'react'
import Button from '../../../../../components/Buttons/Button'


const Index = () => {
  return (
    <>
       <section className='flex items-center justify-center'>
            <div className='py-12 w-[70%] mt-[100px]  rounded'>
                 <h5 className='text-md text-center'>
                    Let’s get started, {
                        JSON.parse(sessionStorage.getItem('user'))?.name
                    }.
                 </h5>
                 <h2 className='text-xl mt-3 font-semibold capitalize text-primary text-center'>
                     Create your first feedback template
                 </h2>
                 <p className='text-center w-1/2 mx-auto mt-3 text-sm mb-4'>
                    Get started by creating a feedback template &
                    use in your learning path or courses
                 </p>
                  <div className='flex justify-center'>
                    <Button 
                      bgColor={'bg-customBlue'}
                      text={'Create Feedback Template'}
                      width={'w-[300px]'}
                      renderAs='link'
                      to='/admin/feedback/create-feedback-template'
                  />
                  </div>
            </div>
       </section>
    </>
  )
}

export default Index