import React from "react";
import toast from "react-hot-toast";
import AccessLogo from "../../../../../../../assets//svgs/access-logo.svg";
import { api } from "../../../../../../../api/clients";
import { useQuery } from "react-query";
import { formatDistanceToNow, parseISO } from "date-fns";

const InvoiceProfile = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { data: profileData, isLoading: profileDataLoading } = useQuery(
    ["profile-data"],
    async () => {
      const response = await api.getClientSummary({
        id: user?.org?.id,
        type: "profile",
      });
     
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
  return (
    <div className="card-container">
      <div className="flex flex-col items-center justify-center">
        <img src={AccessLogo} alt="access-logo" className=" " />
      </div>
      <hr className="my-4" />
      <div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i class="bi bi-calendar-range text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">User ID</p>
            <p className="font-normal text-xs text-[#667085]">
              {profileData?.organization?.id}
            </p>
          </div>
        </div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i class="bi bi-buildings-fill text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">Company</p>
            <p className="font-normal text-xs text-[#667085]">
              {profileData?.organization?.name}
            </p>
          </div>
        </div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i class="bi bi-envelope text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">E-mail</p>
            <p className="font-normal text-xs text-[#667085]">
              {profileData?.organization?.email}
            </p>
          </div>
        </div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i class="bi bi-telephone text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">Phone Number</p>
            <p className="font-normal text-xs text-[#667085]">
              {profileData?.organization?.phoneNumber}
            </p>
          </div>
        </div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i class="bi bi-calendar-event-fill text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">Created</p>
            <p className="font-normal text-xs text-[#667085]">
              {profileData?.organization?.createdAt
                ? formatDistanceToNow(
                    parseISO(profileData.organization.createdAt),
                    { addSuffix: true }
                  )
                : "N/A"}
            </p>
          </div>
        </div>
        <hr className="my-4" />
        {/* <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i class="bi bi-calendar-event-fill text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">Client Admin</p>
            <p className="font-normal text-xs text-[#667085]">ID-011221</p>
          </div>
        </div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i class="bi bi-envelope text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">E-mail</p>
            <p className="font-normal text-xs text-[#667085]">Ajoke@mail.com</p>
          </div>
        </div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i class="bi bi-telephone text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">Phone Number</p>
            <p className="font-normal text-xs text-[#667085]">050 414 8778</p>
          </div>
        </div>
        <div className="flex gap-2 items-center p-2">
          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-[#F0F1F3]">
            <i class="bi bi-calendar-event-fill text-[#667085]"></i>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold text-sm text-[#1D1F2C]">Created</p>
            <p className="font-normal text-xs text-[#667085]">1day ago</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default InvoiceProfile;
