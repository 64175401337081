import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';

const DropdownMenu_ = ({ id, name, cohortId }) => {

  const navigate = useNavigate()

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="bi bi-three-dots-vertical" aria-label="Customise options">
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
          <DropdownMenu.Item
              className="DropdownMenuItem text-left hover:text-white text-gray-900"
              onClick={ () => navigate(`/admin/learners/teams/assign-learners/${id}/${name}?cohortId=${cohortId}`) }
          >
            <i className='bi bi-plus-circle pr-1' /> Assign Learners
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator" />
          <DropdownMenu.Item 
             className="DropdownMenuItem text-left hover:text-white text-gray-900"
             onClick={ () => navigate(`/admin/learners/teams/learners/${id}/${name}`) }
          >
            <i className='bi bi-eye pr-1' /> View Learners
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator hidden" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DropdownMenu_;