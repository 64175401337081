import React, { useState } from "react";
import { api } from "../../../../../../../../api/resources"; 
import { ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import EmptyData from "../../../../../../../../components/EmptyData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import img_icon from "../../../../../../../../assets/img_icon.png";
import ConfirmModal from "../../../../../../../../components/Modal/ConfirmModal";

const AllAddedResources = ({resources, isLoading, refetch}) => {

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedResources, setSelectedResource] = useState(null);
 

  const deleteResources = async () => {
    if (!selectedResources) return;
    try {
      const response = await api.deleteResources(selectedResources.id);
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div>
      <div className="rounded-lg shadow">
        {isLoading ? (
          <div className="mt-10 flex justify-center items-center">
            <ScaleLoader color="#FF9100" />
          </div>
        ) : (
          <div>
            {resources?.length > 0 ? (
              resources.map((resource, index) => (
                <div
                  key={index}
                  className="flex justify-between border-2 border-dotted border-slate-400 mb-3 items-center py-2 px-3 rounded-lg"
                >
                  <div className="text-sm flex items-center justify-start gap-2">
                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <img
                      src={img_icon}
                      className="w-10 h-10"
                      alt={`img-icon ${index}`}
                    />
                    <p className="text-xs font-semibold">
                      {resource?.resourceItem?.topic || resource?.resourceItem?.name  || ""}
                    </p>
                  </div>
                  <div className="flex items-center justify-end gap-2">
                    <p className="text-xs">{resource?.type}</p>
                    <button
                      onClick={() => {
                        setSelectedResource(resource);
                        setDeleteModal(true);
                      }}
                    >
                      <FontAwesomeIcon
                        className="text-sm text-red-500 ml-2"
                        icon={faTrash}
                      />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <EmptyData
                text={"Select and add Resources to your learning path"}
              />
            )}
          </div>
        )}
      </div>
      {deleteModal && (
        <ConfirmModal
          title="Delete Resources"
          description={`Are you sure you want to delete ‘‘${selectedResources?.resourceItem?.name || selectedResources?.resourceItemname?.topic || ""}”?`}
          onYes={deleteResources}
          onClose={() => setDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default AllAddedResources;
