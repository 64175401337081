import React from 'react';
import ViewSports from './components/ViewSports';
import { useLocation } from 'react-router-dom';

const ViewQuizAndSports = ({activeSport, setActiveSport, activeQuiz, setActiveQuiz}) => {

 const location = useLocation();
 const queryParams = new URLSearchParams(location.search);
 const tab = queryParams.get('tab');

  return (
    <div>
        <header>
             <h5 className='text-md font-semibold mb-5'>
                 Inter House Sports Overview
             </h5>
        </header>
        <ViewSports activeSport={activeSport} setActiveSport={setActiveSport} />
    </div>
  )
}

export default ViewQuizAndSports