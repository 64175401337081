import createApiClient from "./api";

const client = createApiClient()
export default client;

export const api = {
    // LOGIN
  login: (payload) => client.post("auth/login", {
    ...payload, accountType: 'admin'
  }).then(({ data }) => data),

  // REGISTER
  register: (payload) => client.post("auth/register", payload).then(({ data }) => data),

  resendEmailVerification: (payload) => client.post("auth/resend-email-verification", payload).then(({ data }) => data),

  verifyEmail: (payload) => client.post("auth/email-verification", payload).then(({ data }) => data),

  forgotPassword: (payload) => client.post("auth/forgot-password", payload).then(({ data }) => data),

  resetPassword: (payload) => client.post("auth/reset-password", payload)
    .then(({ data }) => data),

  getCountries: () => {
    // External API to get countries and their states
    const fetchCountries = async () => {
      const response = await fetch('https://countriesnow.space/api/v0.1/countries/states');
      if (!response.ok) {
        throw new Error('Failed to fetch states');
      }
      return response.json();
    };
    return fetchCountries();
  },

  createRootAccount: (payload) => client
    .post("/admin/organizations/root-account", payload)
    .then(({ data }) => data),

  updateRootAccount: (payload, id) => client
    .patch(`/admin/organizations/root-account/${id}`, payload)
    .then(({ data }) => data),

  createOrganisationProfile: (payload) => client
    .post("/admin/organizations", payload).then(({ data }) => data),

  updateOrganisationProfile: (payload) => client
    .patch(`/admin/organizations/${payload?.orgId}`, {...payload}?.data).then(({ data }) => data),

  createOrganisationAppearance: (payload, orgId) => client
    .post(`/admin/organizations/${orgId}/appearances`, payload)
    .then(({ data }) => data),

  createDemoGraphics: ({ orgId, payload }) => client
    .post(`/admin/organizations/${orgId}/demographics`, payload)
    .then(({ data }) => data),

  updateDemoGraphics: ({ orgId, payload, id }) => client
    .patch(`/admin/organizations/${orgId}/demographics/${id}`, payload)
    .then(({ data }) => data),

  deleteDemoGraphics: ({ orgId, id }) => client
    .delete(`/admin/organizations/${orgId}/demographics/${id}`)
    .then(({ data }) => data),

  getDemoGraphics: (orgId) => client
    .get(`/admin/organizations/${orgId}/demographics`)
    .then(({ data }) => data),
    
  getOrganisationSettings: (orgId) => client
    .get(`/admin/organizations/${orgId}/profile`)
    .then(({ data }) => data),

  setupConfirmation: ({ orgId, id }) => client
    .post(`/admin/organizations/${orgId}/confirm-setup`, { userId: id })
    .then(({ data }) => data),
}; 