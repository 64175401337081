import React from 'react'
import Button from '../../../../../../../components/Buttons/Button'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from 'react-switch'
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { errorResponse } from '../../../../../../../components/utils/errorResponse';
import { api } from '../../../../../../../api/quiz-and-sport';
import { useNavigate } from 'react-router-dom';

const CreateQuiz = ({activeQuiz, setActiveQuiz}) => {

    const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
    const user = JSON.parse(sessionStorage.getItem('user'))?.userId
    const navigate = useNavigate()

    const initialValues = {
        createdBy: user,
        name: activeQuiz === null ? "" : activeQuiz?.name,
        description: activeQuiz === null ? "" : activeQuiz?.description,
        startDate: activeQuiz === null ? "" : activeQuiz?.startDate,
        endDate: activeQuiz === null ? "" : activeQuiz?.endDate,
        startTime: activeQuiz === null ? "" : activeQuiz?.startTime,
        endTime: activeQuiz === null ? "" : activeQuiz?.endTime,
        domainId: "c331d766-fe1f-4826-b63c-805cec637c5e", //default
        status: activeQuiz === null ? "active" : activeQuiz?.status
   }

   const createQiuzMutation = useMutation(
        activeQuiz === null ? api.createQuiz : api.updateQuiz , {
        onSuccess: res => {
            toast.remove()
            toast.success(res?.message)
            if(activeQuiz !== null) setActiveQuiz(null)
            navigate(`/admin/learners/quiz-and-sports?tab=quiz`)
        },
        onMutate: () => {
            toast.loading('Loading...')
        },
        onError: error => errorResponse(error)
    })

   const validate = (values) => {
       const errors = {};
       if(!values?.name) errors.name = `Error! quiz name is required`
       else if(!values?.description) errors.description = `Error! description is required.`
       else if(!values?.startDate) errors.startDate = `Error! start date is required.`
       else if(!values?.endDate) errors.endDate = `Error! end date is required.`
       else if(!values?.startTime) errors.startTime = `Error! start time is required.`
       else if(!values?.endTime) errors.endTime = `Error! end time is required.`
       return errors;
   }

   const createQuiz = (values, actions) => {
      actions.setSubmitting(false);
      createQiuzMutation.mutate(
       activeQuiz === null ? { data: values, orgId} : { data: values, orgId, id: activeQuiz?.id }
      )
   }

  return (
    <div>
        <header className='flex items-center justify-between'>
             <h5 className='text-md font-semibold -mt-2 bi bi-plus-circle'> 
               &nbsp; Create Quiz
             </h5>
             <Button 
               width={'w-[100px]'}
               text={'Back'}
               renderAs='link'
               to={-1}
               bgColor={'bg-secondary'}
             />
        </header>
        <div className='bg-white md:w-[600px] mx-auto mt-3 rounded-xl p-4 shadow-lg'>
        <Formik
            initialValues={initialValues}
            validate={validate}
            enableReinitialize={true}
            onSubmit={createQuiz}
        >
            {({ values, setFieldError, setFieldValue, isValid, errors }) => (
                <Form>
                    <div className='w-[90%] mx-auto mt-1'>
                        <label htmlFor="name" className='text-xs block font-semibold'>
                            Quiz Name <span className='text-sm pl-1 text-red-500'>*</span>
                        </label>
                        <Field 
                           style={{border: errors?.name ? '1px solid red' : '1px solid #ddd' }} 
                          className='input-md mt-1 shadow' name='name' 
                          placeholder='Enter quiz name...'
                        />
                        <p className='text-xs mt-2 text-red-500'>
                            <ErrorMessage name='name'  />
                        </p>
                    </div>
                    <div className='w-[90%] mx-auto mt-3'>
                        <label htmlFor="description" className='text-xs block font-semibold'>
                           Description <span className='text-sm pl-1 text-red-500'>*</span>
                        </label>
                        <Field 
                           style={{border: errors?.description ? '1px solid red' : '1px solid #ddd' }} 
                          className='input-md mt-1 shadow h-[70px]' name='description' 
                          placeholder='Enter quiz description...'
                          as='textarea'
                        />
                        <p className='text-xs mt-2 text-red-500'>
                            <ErrorMessage name='description'  />
                        </p>
                    </div>
                     <div className='w-[90%] mt-3 mx-auto  grid grid-cols-3 gap-3'>
                        <div>
                            <label htmlFor="description" className='text-xs block font-semibold'>
                               Start Date <span className='text-sm pl-1 text-red-500'>*</span>
                            </label>
                            <ReactDatePicker
                                onChange={ date => setFieldValue('startDate', date.toISOString())}
                                selected={values?.startDate}
                                dateFormat="dd/MM/yyyy"
                                className="primary-form-input w-full"
                                placeholderText='Select Date'
                                maxDate={values.endDate}
                            />
                            <p className='text-xs mt-2 text-red-500'>
                                <ErrorMessage name='startDate'  />
                            </p>
                        </div>
                        <div>
                            <label htmlFor="description" className='text-xs block font-semibold'>
                               End Date <span className='text-sm pl-1 text-red-500'>*</span>
                            </label>
                            <ReactDatePicker
                                onChange={ date => setFieldValue('endDate', date.toISOString())}
                                selected={values?.endDate}
                                dateFormat="dd/MM/yyyy"
                                className="primary-form-input w-full shadow"
                                placeholderText='Select Date'
                                minDate={values?.startDate}
                            />
                            <p className='text-xs mt-2 text-red-500'>
                                <ErrorMessage name='endDate'  />
                            </p>
                        </div>
                        <div className='flex text-xs font-semibold pt-2 pl-4 items-center'>
                            Status: &nbsp;&nbsp;&nbsp;&nbsp;
                                <Switch
                                    height={20}
                                    width={40}
                                    handleDiameter={18}
                                    checked={values?.status === 'active'}
                                    onChange={() =>
                                     setFieldValue('status', values?.status === 'active' ? 'inactive' : 'active')
                                    }
                                />
                            </div>
                     </div>
                     <div className='w-[90%] mt-3 mx-auto grid grid-cols-2 gap-3'>
                        <div>
                            <label htmlFor="description" className='text-xs block font-semibold'>
                               Start Time <span className='text-sm pl-1 text-red-500'>*</span>
                            </label>
                            <Field 
                                style={{border: errors?.startTime ? '1px solid red' : '1px solid #ddd' }} 
                                className='input-md mt-1 shadow' name='startTime' 
                                placeholder='Select time'
                                type='time'
                            />
                            <p className='text-xs mt-2 text-red-500'>
                                <ErrorMessage name='startTime'  />
                            </p>
                        </div>
                        <div>
                            <label htmlFor="description" className='text-xs block font-semibold'>
                               End Time <span className='text-sm pl-1 text-red-500'>*</span>
                            </label>
                            <Field 
                                style={{border: errors?.endTime ? '1px solid red' : '1px solid #ddd' }} 
                                className='input-md mt-1 shadow' name='endTime' 
                                placeholder='Select time'
                                type='time'
                            />
                            <p className='text-xs mt-2 text-red-500'>
                                <ErrorMessage name='endTime'  />
                            </p>
                        </div>
                     </div>
                    <div className='w-[90%] flex justify-center mx-auto my-3'>
                        <Button
                          type='submit'
                          width={'w-[150px]'}
                          bgColor={'bg-secondary'}
                          text={ activeQuiz === null ? 'Create Quiz': 'Edit Quiz'}
                          onClick={ () => document.querySelector('#create-sport-btn').click() }
                        />
                        <button type='submit' className='hidden' id='create-sport-btn' />
                    </div>
                 </Form>
             )}
         </Formik>
        </div>
    </div>
  )
}

export default CreateQuiz