import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { api as courses } from '../../../../../../api/courses'
import CourseInformation from '../createCourse/components/CourseInformation'
import CourseContent from '../createCourse/components/CourseContent'

export type stepType = (1 | 2)

const EditCourse: React.FC = (): React.ReactElement => { 
  
 const [step, setStep] = useState<stepType>(1)
 const { id } = useParams()

 const { data: course, isLoading: courseLoading } = useQuery(
    ["get course info"],
    () => {
      return courses.getIndividualCourse(id!);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  return (
    <div className='p-5'> 
          { step === 1 ? 
           <CourseInformation courseInfo={course} type={'edit'} setStep={setStep} /> :
           <CourseContent 
              setStep={setStep}  type={'edit'} 
              courseModules={
                 course?.courseModules?.length ? course?.courseModules?.map( (c: any) => c?.module ) 
                 : []
             } 
            />
          }
    </div>
  )
}

export default EditCourse