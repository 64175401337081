import createApiClient from "./api";

const client = createApiClient();

export const api = {
  createCertificate: ({ orgId, payload }) =>
    client
      .post(`admin/org/${orgId}/certificate-templates`, payload)
      .then(({ data }) => data),

  updateCertificate: ({ orgId, certificateId, payload }) =>
    client
      .patch(
        `admin/org/${orgId}/certificate-templates/${certificateId}`,
        payload
      )
      .then(({ data }) => data),

  getAllCertificates: ({ orgId, search, perPage, currentPage }) =>
    client
      .get(
        `admin/org/${orgId}/certificate-templates?search=${search}&perPage=${perPage}&page=${currentPage}`
      )
      .then(({ data }) => data),

  getCertificate: ({ orgId, certificateId }) =>
    client
      .get(`admin/org/${orgId}/certificate-templates/${certificateId}`)
      .then(({ data }) => data),

  deleteCertificate: ({ orgId, certificateId }) =>
    client
      .delete(`admin/org/${orgId}/certificate-templates/${certificateId}`)
      .then(({ data }) => data),
};
