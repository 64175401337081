import { useState } from "react";
import * as Yup from "yup";
import { OptionType } from "../hooks/types";

export const documentTypes: OptionType[] = [
  { value: "pptx", label: "PowerPoint Presentation (.pptx)" },
  { value: "pdf", label: "PDF Document (.pdf)" },
  { value: "docx", label: "Microsoft Word (.docx)" },
  { value: "xlsx", label: "Microsoft Excel (.xlsx)" },
  { value: "csv", label: "CSV File (.csv)" },
  { value: "txt", label: "Plain Text (.txt)" },
];

export const documentSizes: OptionType[] = [
  { value: "A4", label: "A4 Portrait (8.27 x 11.69 Inches)" },
  { value: "A3", label: "A3 Portrait (11.69 x 16.54 Inches)" },
  { value: "letter", label: "Letter (8.5 x 11 Inches)" },
  { value: "legal", label: "Legal (8.5 x 14 Inches)" },
  { value: "tabloid", label: "Tabloid (11 x 17 Inches)" },
  { value: "custom", label: "Custom Size" },
];


export const unitOptions: OptionType[] = [
  { value: "pixels", label: "Pixels" },
  { value: "inches", label: "Inches" },
  { value: "centimeters", label: "Centimeters" },
  { value: "millimeters", label: "Millimeters" },
];

export const useReportModal = (selectedReport: any) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState<OptionType | null>(documentTypes[0]);
  const [selectedDocumentSize, setSelectedDocumentSize] = useState<OptionType | null>(documentSizes[0]);
  const [customWidth, setCustomWidth] = useState<string>("");
  const [customHeight, setCustomHeight] = useState<string>("");
  const [unit, setUnit] = useState<OptionType | null>(unitOptions[0]);

  const isCustomSize = selectedDocumentSize?.value === "custom";
  const isEditMode = !!selectedReport;

  const initialValues = {
    name: selectedReport?.name || "",
    description: selectedReport?.description || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name field is required"),
    description: Yup.string().required("Description field is required"),
  });

  const handleSubmit = async (
    values: { name: string; description: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(true);

    try {
      if (isEditMode) {
        // API logic for editing a report
      } else {
        // API logic for creating a report
      }
      // Close modal or show toast after success
    } catch (error: any) {
      // Handle error (e.g., show toast)
    } finally {
      setSubmitting(false);
    }
  };

  return {
    selectedDocumentType,
    setSelectedDocumentType,
    selectedDocumentSize,
    setSelectedDocumentSize,
    customWidth,
    setCustomWidth,
    customHeight,
    setCustomHeight,
    unit,
    setUnit,
    isCustomSize,
    isEditMode,
    initialValues,
    validationSchema,
    handleSubmit,
  };
};
