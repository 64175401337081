import React, { useState } from 'react'
import CenteredModal from '../../../../../../components/Modal/CenteredModal'
import Loader from '../../../../../../components/loader/Loader'
import { useMutation } from 'react-query'
import { api } from '../../../../../../api/courses'
import pcIcon from '../../../../../../assets/Bulk Upload.png'
import { useNavigate } from 'react-router-dom'

type PreviewModuleModalType = {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  modulePath: string,
  previewId: string,
  moduleId: string,
  isMulti: boolean,
  previewModuleMuation: any
}

type resultType = null | {
  score: number,
  status: string
}

type symbolType = 'en' | 'fr' | 'pt'

const PreviewModuleModal: React.FC<PreviewModuleModalType> = (props): React.ReactElement => {

 const {openModal, setOpenModal, modulePath, isMulti } = props
 const [currentView, setCurrentView] = useState<'preview'| 'result'>('preview')
 const [result, setResult] = useState<resultType>(null)
 const [language, setLanguage] = useState<symbolType>('en')
 const languages: {name: string, symbol: symbolType }[] = [
    { name: 'English', symbol: 'en'},
    { name: 'French', symbol: 'fr'},
    { name: 'Portiguese', symbol: 'pt'},
 ]

 const navigate  = useNavigate()
 const getResultMutation: any = useMutation(api.getModuleResult, {
   onSuccess: data => {
      setResult(data?.data?.previewData)
   }
 })

 const getResult = () => {
  setCurrentView('result')
  getResultMutation.mutate({ id: props.moduleId, previewId: props.previewId })
 }

  return (
    <>
       <CenteredModal
          title={
            <h5 className='text-md font-semibold bi bi-eye flex items-center'> 
                &nbsp;Preview Module
                { (isMulti && currentView === 'preview') && 
                 <select 
                   className='input-style bg-primaryGray ml-12'
                   value={language}
                   onChange={ (e: any) : void => {
                      setLanguage(e.target.value)
                      props.previewModuleMuation.mutate({ id: props.moduleId, language })
                   } }
                 >
                   {languages.map( (language: { name: string, symbol: symbolType }) => {
                      return (
                         <option key={language.symbol} value={language.symbol}>
                            {language.name}
                         </option>
                      )
                   } )}
                </select>
                }
           </h5>
          }
        bgColor={'bg-white'}
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[800px] h-[510px] overflow-y-auto'}
        headerClassName={'flex justify-between px-3 pt-0 sticky'}
        withCloseBtn={false}
        closeButtonLabel={'Close'}
        btnBgColor={'bg-secondary'}
        onClose={null}
      >  
         <section className='text-left my-3 border-2 border-slate-400 border-double w-full h-[380px]'>
               {
                 currentView === 'preview' ? 
                 props.previewModuleMuation.isLoading ? <Loader /> :
                 <iframe src={modulePath} className='w-full h-full p-0 m-0' />
                 : 
                 getResultMutation?.isLoading ? <Loader /> :
                 <div className='h-full w-full flex-wrap justify-center flex items-center'>
                      <div>
                        <img src={pcIcon} alt="laptop image" className='w-[350px] h-[200px] block mx-auto' />
                      <h5 className='text-center w-full text-sm mt-3'>
                        Module Score: {result?.score}
                     </h5>
                     <p className='text-center text-sm mt-2'>
                        Status: 
                        <span className={`ml-3 rounded-full ${result?.status === 'pass' ? 'bg-green-200 text-primary-green py-1 px-8' :
                           'text-primary bg-orange-200 py-1 px-8'
                        }`}>
                        {result?.status}
                        </span>
                     </p>
                      </div>
                 </div>
               }
         </section>
         <div className='flex justify-end mt-3'>
             {
              currentView === 'preview' ?
              <button 
               className='btn bg-primary-blue'
                onClick={getResult} disabled={getResultMutation.isLoading}
               >
                Finish, View Result
             </button>
             :
             <>
              <button 
               className='btn-light bg-primaryGray mr-5'
                onClick={ () => setCurrentView('preview') }
               >
                Back to preview
             </button>
              <button 
                  className='btn bg-primary-blue'
                  onClick={ () => navigate('/admin/courses/module/') }
               >
                Ok, view modules
             </button>
             </>
            }
         </div>
      </CenteredModal>
    </>
  )
}

export default PreviewModuleModal