import React, { useEffect, useRef, useState } from "react";
import CenteredModal from "../../../../../components/Modal/CenteredModal";
import { Rnd } from "react-rnd";

const PreviewCertificateModal = ({ openPreviewModal, setOpenPreviewModal }) => {
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [elements, setElements] = useState([]);
  const [activeElementIndex, setActiveElementIndex] = useState(null);
  const [isCardOpen, setIsCardOpen] = useState(true); // State to control card visibility
  // Load data from sessionStorage
  const loadFromSessionStorage = (key) => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  };

  useEffect(() => {
    if (openPreviewModal) {
      const storedBackgroundImage = loadFromSessionStorage("backgroundImage");
      const storedBackgroundColor = loadFromSessionStorage("backgroundColor");
      const storedElements = loadFromSessionStorage("elements");

      if (storedBackgroundImage) setBackgroundImage(storedBackgroundImage);
      if (storedBackgroundColor) setBackgroundColor(storedBackgroundColor);
      if (storedElements) setElements(storedElements);
    }
  }, [openPreviewModal]);

  const updateActiveElement = (key, value) => {
    const updatedElements = [...elements];
    updatedElements[activeElementIndex] = {
      ...updatedElements[activeElementIndex],
      [key]: value,
    };
    setElements(updatedElements);
  };

  const updateElementPosition = (index, x, y) => {
    const updatedElements = [...elements];
    updatedElements[index] = { ...updatedElements[index], x, y };
    setElements(updatedElements);
  };

  const updateElementSize = (index, width, height) => {
    const updatedElements = [...elements];
    updatedElements[index] = { ...updatedElements[index], width, height };
    setElements(updatedElements);
  };

  // Function to delete an element
  const deleteElement = (index) => {
    setElements((prev) => prev.filter((_, i) => i !== index));
  };

  // Function to duplicate an element
  const duplicateElement = (index) => {
    setElements((prev) => [
      ...prev,
      { ...prev[index], x: prev[index].x + 10, y: prev[index].y + 40 },
    ]);
  };

  // ref
  const cardRef = useRef(null); // Ref to track the card container
  const logoInputRef = useRef(null);
  const inputRef = useRef(null);
  const backgroundInputRef = useRef(null);
  const signature1InputRef = useRef(null);
  const signature2InputRef = useRef(null);

  const renderElement = (element, index) => {
    if (element.type === "text") {
      return (
        <Rnd
          key={index}
          size={{ width: "auto", height: "auto" }}
          position={{ x: element.x, y: element.y }}
          onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
          onClick={() => {
            setActiveElementIndex(index);
            setIsCardOpen(true); // Re-open the card when clicking on an element
            // Delay to ensure the input is focused before setting the cursor
            setTimeout(() => {
              if (inputRef.current) {
                const range = document.createRange();
                const selection = window.getSelection();
                range.selectNodeContents(inputRef.current);
                range.collapse(false); // Set the cursor to the end
                selection.removeAllRanges();
                selection.addRange(range);
              }
            }, 5000); // Adding a 0ms timeout to allow the focus event to complete
          }}
        >
          <div
            className="cursor-pointer"
            style={{
              fontSize: `${element.fontSize}px`,
              color: element.color,
              fontWeight: element.fontWeight,
              fontStyle: element.fontStyle,
              textAlign: element.textAlign,
              // border: activeElementIndex === index ? "1px solid blue" : "none",
              padding: "5px",
            }}
            contentEditable={element.editable}
            suppressContentEditableWarning={true}
            ref={inputRef}
            onInput={(e) => {
              // Store the current caret position
              const selection = window.getSelection();
              const range = selection.getRangeAt(0);
              const caretOffset = range.startOffset;

              // Update the element content
              updateActiveElement("content", e.currentTarget.textContent);

              // Restore caret position after update
              const newRange = document.createRange();
              const newSelection = window.getSelection();
              newRange.setStart(e.currentTarget.firstChild, caretOffset);
              newRange.collapse(true);
              newSelection.removeAllRanges();
              newSelection.addRange(newRange);
            }}

            // onInput={(e) =>
            //   updateActiveElement("content", e.currentTarget.textContent)
            // }
          >
            {element.content}
          </div>
          {isCardOpen && activeElementIndex === index && (
            <div
              ref={cardRef}
              className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl"
            >
              <button
                onClick={() => duplicateElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                {/* Tooltip for Duplicate Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Duplicate
                </div>
              </button>

              <button
                onClick={() => deleteElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                {/* Tooltip for Delete Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Delete
                </div>
              </button>
            </div>
          )}
        </Rnd>
      );
    } else if (element.type === "logo") {
      return (
        <Rnd
          key={index}
          size={{ width: element.width, height: element.height }}
          position={{ x: element.x, y: element.y }}
          onResizeStop={(e, direction, ref, delta, position) => {
            updateElementSize(index, ref.offsetWidth, ref.offsetHeight);
            updateElementPosition(index, position.x, position.y);
          }}
          onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
          onClick={() => {
            setActiveElementIndex(index);
            setIsCardOpen(true); // Re-open the card when clicking on an element
          }}
        >
          <img
            src={element.src}
            style={{
              width: "100%",
              height: "100%",
              // border: activeElementIndex === index ? "1px solid blue" : "none",
            }}
            alt="logo"
          />
          {isCardOpen && activeElementIndex === index && (
            <div
              ref={cardRef}
              className="absolute right-0 top-20 flex bg-white border rounded-lg shadow-2xl"
            >
              <button
                onClick={() => duplicateElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                {/* Tooltip for Duplicate Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Duplicate
                </div>
              </button>

              <button
                onClick={() => deleteElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                {/* Tooltip for Delete Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Delete
                </div>
              </button>
            </div>
          )}
        </Rnd>
      );
    } else if (element.type === "sign-1") {
      return (
        <Rnd
          key={index}
          size={{ width: element.width, height: element.height }}
          position={{ x: element.x, y: element.y }}
          onResizeStop={(e, direction, ref, delta, position) => {
            updateElementSize(index, ref.offsetWidth, ref.offsetHeight);
            updateElementPosition(index, position.x, position.y);
          }}
          onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
          onClick={() => {
            setActiveElementIndex(index);
            setIsCardOpen(true); // Re-open the card when clicking on an element
          }}
        >
          <img
            src={element.src}
            style={{
              width: "100%",
              height: "100%",
              // border: activeElementIndex === index ? "1px solid blue" : "none",
            }}
            alt="Signature 1"
          />
          {isCardOpen && activeElementIndex === index && (
            <div
              ref={cardRef}
              className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl"
            >
              <button
                onClick={() => duplicateElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                {/* Tooltip for Duplicate Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Duplicate
                </div>
              </button>

              <button
                onClick={() => deleteElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                {/* Tooltip for Delete Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Delete
                </div>
              </button>
            </div>
          )}
        </Rnd>
      );
    } else if (element.type === "sign-2") {
      return (
        <Rnd
          key={index}
          size={{ width: element.width, height: element.height }}
          position={{ x: element.x, y: element.y }}
          onResizeStop={(e, direction, ref, delta, position) => {
            updateElementSize(index, ref.offsetWidth, ref.offsetHeight);
            updateElementPosition(index, position.x, position.y);
          }}
          onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
          onClick={() => {
            setActiveElementIndex(index);
            setIsCardOpen(true); // Re-open the card when clicking on an element
          }}
        >
          <img
            src={element.src}
            style={{
              width: "100%",
              height: "100%",
              border: activeElementIndex === index ? "1px solid blue" : "none",
            }}
            alt="Signature 2"
          />
          {isCardOpen && activeElementIndex === index && (
            <div
              ref={cardRef}
              className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl"
            >
              <button
                onClick={() => duplicateElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                {/* Tooltip for Duplicate Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Duplicate
                </div>
              </button>

              <button
                onClick={() => deleteElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                {/* Tooltip for Delete Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Delete
                </div>
              </button>
            </div>
          )}
        </Rnd>
      );
    } else if (element.type === "line") {
      return (
        <Rnd
          key={index}
          size={{ width: element.width, height: element.height }}
          position={{ x: element.x, y: element.y }}
          onResizeStop={(e, direction, ref, delta, position) => {
            updateElementSize(index, ref.offsetWidth, ref.offsetHeight);
            updateElementPosition(index, position.x, position.y);
          }}
          onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
          onClick={() => {
            setActiveElementIndex(index);
            setIsCardOpen(true); // Re-open the card when clicking on an element
          }}
        >
          <div
            style={{
              backgroundColor: element.backgroundColor,
              width: "100%",
              height: "100%",
              border: activeElementIndex === index ? "1px solid blue" : "none",
            }}
          />
          {isCardOpen && activeElementIndex === index && (
            <div
              ref={cardRef}
              className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl"
            >
              <button
                onClick={() => duplicateElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                {/* Tooltip for Duplicate Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Duplicate
                </div>
              </button>

              <button
                onClick={() => deleteElement(index)}
                className="m-1 p-1 text-sm rounded relative group"
              >
                <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                {/* Tooltip for Delete Button */}
                <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                  Delete
                </div>
              </button>
            </div>
          )}
        </Rnd>
      );
    }

    return null;
  };

  return (
    <CenteredModal
      title={""}
      hasHeader={false}
      open={openPreviewModal}
      setOpen={setOpenPreviewModal}
      width={"w-1/2"}
      height={"h-full"}
    >
      <div
        className="relative border border-gray-300 h-screen"
        style={{
          backgroundColor: backgroundImage ? "transparent" : backgroundColor,
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {elements?.map((element, index) => renderElement(element, index))}
      </div>
    </CenteredModal>
  );
};

export default PreviewCertificateModal;
