import React from 'react'

import { v4 } from 'uuid'
import StarRating from './StarRating'
import OpenEndedComment from './OpenEndedComment'
import SatisfactionRating from './SatisfactionRating'
import YesOrNo from './YesOrNo'
import CenteredModal from '../../../../../../../components/Modal/CenteredModal'

const PreviewFeedback = ({ open, setOpen, questions, questionTypes }) => {

  return (
    <>
      <CenteredModal
         title={
                <h5 className='text-md font-semibold bi bi-eye'> 
                   &nbsp; Preview Questions ({questions?.length})
               </h5>
              }
         open={open}
         setOpen={setOpen}
         style={{padding: 0}}
         width={'w-[700px] h-[500px] overflow-y-auto'}
         headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
         withCloseBtn
         closeButtonLabel={'Close'}
         btnBgColor={'bg-secondary'}
         onClose={ () => {
            setOpen(false)
         }}
      >
          <div className='text-left mt-5'>
             {
                questions?.length ?
                  questions?.map( (question, index) => {
                     return <div key={v4()} className='border mb-5 border-gray-200 rounded-lg p-3'>
                              <h5 className='text-sm'> Question {index + 1}: </h5>
                              <p className='text-sm mt-1 font-light'>{question?.question}</p>
                              <div className='mt-2'>
                                 <h5 className='text-sm'> Answer: </h5>
                                 <div>
                                   { question?.type === questionTypes[0] && <StarRating /> }
                                   { question?.type === questionTypes[1] && <OpenEndedComment /> }
                                   { question?.type === questionTypes[2] && <SatisfactionRating /> }
                                   { question?.type === questionTypes[3] && <div className='flex items-center'>
                                    { 
                                        question?.emojis ? 
                                        question.emojis?.map( emoji => {
                                            return <div className='mr-5 px-3 my-2 rounded-full'>
                                                <img 
                                                    src={emoji} alt="emoji"
                                                    className='w-[50px] h-[50px] object-cover' 
                                                />
                                            </div>
                                        } )
                                        : null 
                                        }
                                     </div> }
                                   { question?.type === questionTypes[4] && <YesOrNo /> }
                                   { question?.type === questionTypes[5] && <div className='flex flex-wrap justify-between'>
                                    { 
                                        question?.answers ? 
                                        question.answers?.map( answer => {
                                            return <div key={answer?.id} className='w-[48%] flex mt-2 items-center'>
                                                     <div>
                                                       <input type="checkbox" className='w-[16px] block h-[16px] mr-2' />
                                                     </div>
                                                    <label className='text-sm font-light'>{answer?.title}</label>
                                                </div>
                                        } )
                                        : null 
                                        }
                                     </div> }
                                 </div>
                              </div>
                           </div>
                  })
                : null
             }
          </div>
      </CenteredModal>
    </>
  )
}

export default PreviewFeedback