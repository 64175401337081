import React, { useState } from 'react'
import DataWidget from './DataWidget'
import ChartsWidget from './ChartsWidget'
import { tableData } from '../CreateReport'

type DataProps = {
  tableData: tableData
  setTableData: React.Dispatch<React.SetStateAction<tableData>>
}
const Data: React.FC<DataProps> = ({tableData, setTableData}) : JSX.Element => {

  const [activeWidget, setActiveWidget] = useState<'' | 'data' | 'charts'>('')

  return (
    <div className='pt-2'>
       <header className='flex flex-col gap-2'>
        <button className={
          `bi bi-diamond ${activeWidget === 'data' ? 
          'btn bg-primary-blue' 
          : 'btn-light bg-lightGray'}`
        }
        onClick={() => setActiveWidget('data')}
        >
          &nbsp; Data Widget
        </button>
        <button className={
          `bi bi-bar-chart ${activeWidget === 'charts' ? 
          'btn bg-primary-blue' 
          : 'btn-light bg-lightGray'}`
        }
        onClick={() => setActiveWidget('charts')}
        >
          &nbsp; Charts & Graphs
        </button>
       </header>
       <section>
        {
          activeWidget === 'data' &&
           <DataWidget />
        }
        {
          activeWidget === 'charts' &&
           <ChartsWidget tableData={tableData} setTableData={setTableData} />
        }
       </section>
    </div>
  )
}

export default Data