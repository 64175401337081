import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ViewModules from './pages/viewModules/ViewModules'
import CreateModule from './pages/createModule/CreateModule'

const Modules: React.FC = () : React.ReactElement => {
  return (
    <div>
        <Routes>
            <Route path="/" element={<ViewModules />} />
            <Route path="/create" element={<CreateModule />} />
            <Route path="/edit/:id" element={<CreateModule />} />
        </Routes>
    </div>
  )
}

export default Modules