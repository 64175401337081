import React from 'react'
import flightIcon from '../assets/03.png'
import { Link } from 'react-router-dom'

type StarterPageType = {
  header: string,
  text: string
  linkText: string
  to: string
}

const StarterPage: React.FC<StarterPageType> = (props) : React.ReactElement => {
  return (
    <div className='flex flex-col justify-between'>
        <img src={flightIcon} alt="flight image" className='w-[800px] h-[300px] mx-auto block' />
        <div className='text-center mt-5'>
            <h3 className='text-md text-center text-primary font-semibold'>
              {props.header}
            </h3>
            <p className='text-sm mt-1 mb-4'>
              {props.text}
            </p>
            <Link to={props.to} className='btn mt-3 bg-primary-blue'> 
              {props.linkText}
            </Link>
        </div>
    </div>
  )
}

export default StarterPage