import React, {useEffect, useState} from 'react'
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import Search from '../../../../../../../../../../../components/Inputs/Search';
import CustomDropdown from './../../../../../../../../../../../components/dropdown/CustomDropdown';
import FilterModal from './components/FilterModal';
import TransactionTable from './components/TransactionsTable';
import { api } from '../../../../../../../../../../../api/billing';
import { useQuery } from 'react-query';
import Pagination from '../../../../../../../../../../../components/pagination/Pagination';

const TransactionHistory = ({orgId}) => {

 const [dateRange, setDateRange] = useState([new Date(), new Date()]);
 const [cohortId, setCohortId] = useState(null)
 const [learningPathId, setLearningPathId] = useState(null)
 const [courseId, setCourseId] = useState(null)
 const [openModal, setOpenModal] = useState(false)
 const [perPage, setPerPage] = useState(5);
const [search, setSearch] = useState("");
const [currentPage, setCurrentPage] = useState(1);

 const { data: transactions, isLoading, refetch } = useQuery(
    ["client transactions", { id: orgId, search, perPage, currentPage, cohortId, courseId, learningPathId }],
    ({ queryKey }) => {
      return api.getClientTransactions(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  useEffect( () => {
    refetch()
  }, [search, currentPage, perPage, cohortId, learningPathId, courseId] )

  return (
    <div>
        <section className='mt-2 bg-white p-3 rounded-lg'>
         <header className='flex justify-between items-center'>
            <h2 className='text-sm'>
              Transaction History ({transactions?.meta?.total})
            </h2>
            <Search 
               className={'mb-0 w-[300px]'} 
               placeholder={'Search transaction history'}
               onChange={setSearch}
             />
            <div className='flex items-center'>
                 <i 
                    className='bi bi-filter text-xl mr-3 hover:cursor-pointer'
                    onClick={ () => setOpenModal(true) }
                 />
                <FilterModal 
                  cohortId={cohortId}
                  setCohortId={setCohortId}
                  learningPathId={learningPathId}
                  setLearningPathId={setLearningPathId}
                  courseId={courseId}
                  setCourseId={setCourseId}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  orgId={orgId}
                />
            </div>
         </header>
         <section>
            <TransactionTable 
              transactions={transactions}
              perPage={perPage}
              isLoading={isLoading}
            />
             <Pagination
              data={transactions?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
         </section>
      </section>
    </div>
  )
}

export default TransactionHistory