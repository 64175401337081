import { Link, Outlet, useLocation } from "react-router-dom";

const SettingsLayout = () => {
  const location = useLocation();

  return (
    <div className={`flex h-screen gap-4 mt-2`}>
      {/* Settings Sidebar */}
      <div className=" bg-white w-fit h-screen p-4 shadow-lg">
        <ul className="space-y-1">
          <li>
            <Link
              to="/admin/settings/admins"
              className={`flex items-center font-semibold opacity-85 text-[13px]  px-4 py-2 ${
                location.pathname === "/admin/settings/admins"
                  ? "bg-gray-100 border-r-4 border-orange-500"
                  : "hover:bg-gray-50"
              }`}
            >
              <i className="bi bi-person-lock text-lg pr-1"/> Admins
            </Link>
          </li>
          <li>
            <Link
              to="/admin/settings/role-and-privileges"
              className={`flex font-semibold opacity-85 items-center text-[13px] px-4 py-2 ${
                location.pathname === "/admin/settings/role-and-privileges"
                  ? "bg-gray-100 border-r-4 border-orange-500"
                  : "hover:bg-gray-50"
              }`}
            >
              <i className="bi bi-house-lock text-lg pr-1"/> Roles & Privileges
            </Link>
          </li>
          <li>
            <Link
              to="/admin/settings/zoom"
              className={`flex font-semibold opacity-85 items-center text-[13px] px-4 py-2 ${
                location.pathname === "/admin/settings/zoom"
                  ? "bg-gray-100 border-r-4 border-orange-500"
                  : "hover:bg-gray-50"
              }`}
            >
             <i className="bi bi-camera-reels text-lg pr-1"/> Zoom
            </Link>
          </li>
          <li>
            <Link
              to="/admin/settings/email"
              className={`flex font-semibold opacity-85 items-center text-[13px] px-4 py-2 ${
                location.pathname === "/admin/settings/email"
                  ? "bg-gray-100 border-r-4 border-orange-500"
                  : "hover:bg-gray-50"
              }`}
            >
              <i className="bi bi-envelope-at text-lg pr-1"/> Email
            </Link>
          </li>
          <li>
            <Link
              to="/admin/settings/profile"
              className={`flex font-semibold opacity-85 items-center text-[13px] px-4 py-2 ${
                location.pathname === "/admin/settings/profile"
                  ? "bg-gray-100 border-r-4 border-orange-500"
                  : "hover:bg-gray-50"
              }`}
            >
              <i className="bi bi-person-circle text-lg pr-1"/> Profile & Account Setup
            </Link>
          </li>
        </ul>
      </div>

      {/* Dynamic Content Area */}
      <div className="content-area flex-1 p-3  bg-transparent h-screen">
        {/* React Router's Outlet component will render the matching content here */}
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
