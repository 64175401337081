// const LearningPathCard = ({ path }) => {
//     const totalModules = path.courseData.reduce((acc, course) => acc + course.numberOfModules, 0);
//     const completedModules = path.courseData.reduce(
//       (acc, course) => acc + course.numberOfCompletedModules,
//       0
//     );
//     const progressPercentage = Math.round((completedModules / totalModules) * 100);
  
//     return (
//       <div className="p-6 bg-white shadow-md rounded-lg mb-6">
//         <h2 className="text-lg font-semibold mb-4">{path.name}</h2>
//         <div className="mb-4">
//           <div className="flex items-center justify-between mb-1">
//             <span className="text-sm font-medium text-gray-600">Learning Path Progress</span>
//             <span className="text-sm text-gray-600">{progressPercentage}% Complete</span>
//           </div>
//           <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
//             <div
//               className="h-full bg-blue-500 rounded-full"
//               style={{ width: `${progressPercentage}%` }}
//             ></div>
//           </div>
//         </div>
//         {path.courseData.map((course) => {
//           const courseProgress = Math.round((course.numberOfCompletedModules / course.numberOfModules) * 100);
  
//           return (
//             <div key={course.courseId} className="mb-4 border border-gray-200 rounded-lg p-4">
//               <h3 className="text-sm font-semibold mb-1">{course.courseName}</h3>
//               <div className="flex items-center justify-between mb-1">
//                 <span className="text-sm text-gray-600">
//                   Progress: {course.numberOfCompletedModules}/{course.numberOfModules} modules completed
//                 </span>
//               </div>
//               <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
//                 <div
//                   className="h-full bg-blue-500 rounded-full"
//                   style={{ width: `${courseProgress}%` }}
//                 ></div>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     );
//   };

//   export default LearningPathCard


import React, { useState } from "react";

const LearningPathCard = ({ path }) => {
  const [expandedLearningPath, setExpandedLearningPath] = useState(false);
  const [expandedCourse, setExpandedCourse] = useState(null);

  const toggleLearningPath = () => {
    setExpandedLearningPath(!expandedLearningPath);
    setExpandedCourse(null); // Collapse all courses when toggling the learning path
  };

  const toggleCourse = (courseId) => {
    setExpandedCourse(expandedCourse === courseId ? null : courseId);
  };

  const totalModules = path.courseData.reduce(
    (acc, course) => acc + course.numberOfModules,
    0
  );
  const completedModules = path.courseData.reduce(
    (acc, course) => acc + course.numberOfCompletedModules,
    0
  );
  const progressPercentage = Math.round((completedModules / totalModules) * 100);

  return (
    <div className="p-6 bg-white shadow-md rounded-lg mb-6">
      {/* Learning Path */}
      <h2
        className="text-lg font-semibold mb-4 cursor-pointer"
        onClick={toggleLearningPath}
      >
        {path.name}
      </h2>
      <div className="mb-4">
        <div className="flex items-center justify-between mb-1">
          <span className="text-sm font-medium text-gray-600">
            Learning Path Progress
          </span>
          <span className="text-sm text-gray-600">
            {progressPercentage}% Complete
          </span>
        </div>
        <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-full bg-blue-500 rounded-full"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>

      {/* Course Data */}
      {expandedLearningPath &&
        path.courseData.map((course) => {
          const courseProgress = Math.round(
            (course.numberOfCompletedModules / course.numberOfModules) * 100
          );

          return (
            <div key={course.courseId} className="mb-4 border border-gray-200 rounded-lg">
              <div
                className="p-4 cursor-pointer"
                onClick={() => toggleCourse(course.courseId)}
              >
                <h3 className="text-sm font-semibold mb-1">{course.courseName}</h3>
                <div className="flex items-center justify-between mb-1">
                  <span className="text-sm text-gray-600">
                    Progress: {course.numberOfCompletedModules}/
                    {course.numberOfModules} modules completed
                  </span>
                </div>
                <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-blue-500 rounded-full"
                    style={{ width: `${courseProgress}%` }}
                  ></div>
                </div>
              </div>

              {/* Modules */}
              {expandedCourse === course.courseId && (
                <div className="p-4 bg-gray-50">
                  {course.modulesWithScores.map((module, index) => (
                    <div
                      key={index}
                      className="border-b border-gray-200 py-2 text-sm"
                    >
                      <h4 className="font-medium">
                        {module.moduleName}
                      </h4>
                      <p className="text-gray-500">
                        Status: {module.status}
                        {module.isAssessment && " (Assessment)"}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default LearningPathCard;
