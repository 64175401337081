import React from 'react'

export type menusType = {
    index: number,
    header: string,
    title: string,
    active: boolean
 }
type stepperTypes = {
   menus: menusType[],
   setMenus:  React.Dispatch<React.SetStateAction<menusType[]>>,
   currentStep: number,
   setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}

const Stepper = ({ menus, setMenus, currentStep, setCurrentStep }: stepperTypes) => {

  return (
    <div>
        {
          menus.map( (menu: menusType) => {
            return (
                <div className={`h-[90px] w-[200px] ${
                    currentStep >= menu.index ? 'border-secondary' : ' border-slate-200'
                } border-r-2 relative`}>
                    <span className={`step-counter ${ currentStep >= menu.index ? 
                        ' bg-secondary text-white' : 
                        ' bg-white border border-slate-400 text-secondary'
                    }`}>
                        {menu.index}
                    </span>
                    <h4 className='text-sm font-bold'>
                        {menu.header}
                    </h4>
                    <p className='text-xs mt-1  font-light'>
                      {menu.title}
                    </p>
                </div>
            )
          })
        }
    </div>
  )
}

export default Stepper