import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import HorizontalTab from '../../../../../../components/Tab/HorizontalTab'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import { scrollToTop } from '../../../../../../components/utils/scrollToTop'
import { api, api as courses } from '../../../../../../api/courses'  
import Switch from 'react-switch'
import * as Yup from 'yup'
import { createResource } from '../../../../../../api/uploadResourse'  
import { errorResponse } from '../../../../../../components/utils/errorResponse'
import ResourceLoader from '../../../../../../components/loader/ResourceLoader'
import PreviewModuleModal from './PreviewModuleModal'
import { useMutation } from 'react-query'
import Confirmation from '../../../../components/ConfimationModal'

type initialValuesType = {
    title: string, 
    description: string,
    assessment?: ('pre-assessment' | 'post-assessment' | "pre and post") 
    module_zip: string,
    title_f?: string, 
    description_f?: string,
    module_zip_f?: string,
    title_p?: string, 
    description_p?: string,
    module_zip_p?: string,
  }

  export type moduleLanguageType = {
    language: "english" | "french" | "portuguese",
    symbol: "en" | "fr" | "pt",
    name: string | undefined, 
    description: string | undefined, 
    isDefault: boolean,
    filePath: string | undefined,
    Points: number
}

const CreateModule = () => {

    const activeModule = sessionStorage.getItem("active-module")
    const editModuleData = JSON.parse(activeModule!)

    const [isAssessment, setIsAssessment] = useState<boolean>(
        activeModule ? editModuleData?.isAssessment  : false
    )
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false)
    const [modulePath, setModulePath] = useState<string>('')
    const [ openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false)
    const [useMultipleLanguages, setUseMultipleLanguages] = useState<boolean>(
        activeModule ? editModuleData?.languages?.length > 1 : false
    ); 
    const [moduleLoading, setModuleLoading] = useState<boolean>(false)
    const [previewModuleId, setPreviewModuleId] = useState<string>('')
    const [moduleId, setModuleId] = useState<string>(
     activeModule ? editModuleData?.id  : ''
    )
    const navigate = useNavigate()
    const previewModuleMuation: any = useMutation(api.previewModule, {
       onSuccess: (response: any) => {
          setPreviewModuleId(response?.data?.previewModuleId)
          setModulePath(response?.data?.url)
          setTimeout( () => {
            setOpenConfirmationModal(false);
            setOpenPreviewModal(true);
          }, 500 )
       },
       onError: (error) => errorResponse(error)
    })

    const getLanguageData = (symbol : 'en' | 'fr' | 'pt', key: string) : string => {
        return activeModule ?  
           editModuleData?.languages?.length > 1 ? 
             editModuleData?.languages?.find( (language: any) => {
               return language?.symbol === symbol
             })[key] : '' : ''
      }

      const initialValues: initialValuesType = {
        title: activeModule ? editModuleData?.name : '', 
        description: activeModule ? editModuleData?.description : '',
        module_zip: activeModule ? editModuleData?.filePath : '',
        title_f: getLanguageData('fr', 'name'), 
        description_f: getLanguageData('fr', 'description'),
        module_zip_f: getLanguageData('fr', 'filePath'),
        title_p: getLanguageData('pt', 'name'), 
        description_p: getLanguageData('pt', 'description'),
        module_zip_p: getLanguageData('pt', 'filePath'),
        assessment: activeModule ? editModuleData?.assessmentType :  "pre and post",
      }

      const assessmentType: string[] = ['pre-assessment', 'post-assessment', 'pre and post']

      const validationSchema = Yup.object<Yup.AnyObject, {}>().shape({    
        title: Yup.string().required('Error! field title field is required.'),
        description: Yup.string().required('Error! description field is required.'),
      });

      const supportedLanguages: ('English' | 'French' | 'Portuguese')[] = ['English', 'French', 'Portuguese']

      const tabsData = (setFieldValue: any, values: any) : { label: string, content: React.ReactElement }[] => { 
        return supportedLanguages.map( (language: string) => { 
         return {
         label: language,
         content: (
             <>
                  <div className='grid grid-cols-1 mt-0 gap-3'>
                          </div>
                                <section>
                                <div className='mt-3'>
                                    <label 
                                     htmlFor={language.startsWith('E') ? "title" : `title_${
                                      language.toLocaleLowerCase().slice(0,1)
                                     }`} 
                                     className='text-xs'>
                                      Module Title { language.startsWith('E') ? null : `(${language})` }
                                      <span className='text-lg text-red-500'>*</span>
                                    </label>
                                    <Field 
                                        id={language.startsWith('E') ? "title" : `title_${
                                          language.toLocaleLowerCase().slice(0,1)
                                         }`} 
                                        className="input-style bg-primaryGray" 
                                        name={language.startsWith('E') ? "title" : `title_${
                                          language.toLocaleLowerCase().slice(0,1)
                                         }`}  
                                        placeholder={`Type module ${ language.startsWith('E') ? '' : `${language.toLocaleLowerCase()}` } title here...`}
                                    />
                                    <p className='mt-2 text-red-500 text-sm'>
                                        <ErrorMessage name={language.startsWith('E') ? "title" : `title_${
                                           language.toLocaleLowerCase().slice(0,1)
                                           }`}  
                                        />
                                    </p>
                                </div>
                                <div className='mt-2'>
                                    <label 
                                      htmlFor={language.startsWith('E') ? "description" : `description_${
                                        language.toLocaleLowerCase().slice(0,1)
                                       }`} 
                                      className='text-xs'
                                    >
                                      Module Description { language.startsWith('E') ? null : `(${language})` }
                                      <span className='text-lg text-red-500'>*</span>
                                    </label>
                                    <Field 
                                        id={language.startsWith('E') ? "description" : `description_${
                                          language.toLocaleLowerCase().slice(0,1)
                                         }`} 
                                        className="input-style pt-5 pb-3 bg-primaryGray" 
                                        name={language.startsWith('E') ? "description" : `description_${
                                          language.toLocaleLowerCase().slice(0,1)
                                         }`} 
                                         placeholder={`Type module ${ language.startsWith('E') ? '' : `${language.toLocaleLowerCase()}` } description here...`}
                                        as='textarea'
                                   />
                                    <p className='mt-2 text-red-500 text-sm'>
                                        <ErrorMessage 
                                           name={language.startsWith('E') ? "description" : `description_${
                                            language.toLocaleLowerCase().slice(0,1)
                                           }`}
                                       />
                                    </p>
                                </div>
                  </section>
             </>
           )
        }}
      )
      }

    const uploadModuleZip = async ( event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any ) : Promise<void> => {
        setIsUploading(true)
        try{
           const file: File =  event?.target?.files![0]; 
        if (file) {
           const formData: FormData = new FormData();
           formData.append("file", file);
           const response = await createResource.uploadModuleResource(formData)
           toast.success(response?.data?.message)
           setFieldValue(event.target.name, response?.data?.data?.url)
           event.target.value = ''
           setIsUploading(false)
        }  
        } catch(error){
           setIsUploading(false)
           errorResponse(error)
        }
    }

    const createModule = async (values: initialValuesType, action: any) : Promise<any> => {
        action?.setSubmitting(false)
        if(useMultipleLanguages){
          if(
            !values.title_f || !values.title_p || !values.description_f || !values.description_p
            || !values.module_zip_f || !values.module_zip_p 
          ){
            toast.error(`Error! module name, description and module zip. 
                           cannot be empty for all languages check and validate 
                           before you proceed.`
                        )
            return;
          }
        }
        if(!values.module_zip){
           toast.error('Error! module zip file is required.')
           return
        }

          const defaultModuleLanguage: moduleLanguageType = {
            name: values.title,
            description: values.description,
            filePath: values.module_zip,
            Points: 1,
            language: 'english',
            symbol: 'en',
            isDefault: true,
          }
          const languages: moduleLanguageType[] = useMultipleLanguages ? [
             defaultModuleLanguage,
            {
              name: values.title_f,
              description: values.description_f,
              filePath: values.module_zip_f,
              Points: 1,
              language: 'french',
              symbol: 'fr',
              isDefault: false,
            },
            {
              name: values.title_p,
              description: values.description_p,
              filePath: values.module_zip_p,
              Points: 1,
              language: 'portuguese',
              symbol: 'pt',
              isDefault: true,
            }
          ] : [defaultModuleLanguage]

        const payload = {
          assessmentType: values.assessment,
          filePath: values.module_zip,
          duration: 84,
          sorting: 15,
          isAssessment: isAssessment,
          Points: 22,
          languages,
        }
      try{
          toast.loading('Loading...')
          setModuleLoading(true)
          let response: any
          if(activeModule) response = await courses.updateModule(payload, editModuleData?.id)
          else response = await courses.createModule(payload)
          toast.remove()
          setModuleLoading(false)
          toast.success(response?.message)
          setModuleId(activeModule ? editModuleData?.id : response?.data?.id)
          setOpenConfirmationModal(true)
          action.resetForm();
          scrollToTop()
      } catch(error:any){
        toast.remove()
        setModuleLoading(false)
        toast.error(error?.response?.data?.message)
      }
    }

    const previewModule = () : void => {
       previewModuleMuation.mutate({id: moduleId, language: 'en'})
    }

  return (
    <div className='p-5'>
        { isUploading && <ResourceLoader /> }
         <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={createModule}
        >
         {({ values, setFieldError, setFieldValue, setFieldTouched }) => (
        <Form>
          <header className='flex justify-between'>
            <h3 className='text-md font-semibold'>
                {activeModule ? 'Edit Module' : 'Create New Module'}
            </h3>
            <div className='flex items-center'>
             <Link to={'/admin/courses/module/'} className='btn-light pl-8 pr-8 bg-slate-300'>
               Back
            </Link>
             </div>
         </header>
         <section className='border flex justify-between pr-0 px-7 border-slate-400 rounded-lg mx-3 mt-8'>
             <div className='w-[40%] py-7'>
                <div>
                 <h5 className='text-xs'>
                    Upload Module Zip
                 </h5>
                 <input 
                    type="file" className='hidden' id='module_zip'
                    name='module_zip'
                    onChange={ e => uploadModuleZip(e, setFieldValue)}  accept=".zip" 
                  />
                  <div className='w-[90%] relative flex-col flex items-center justify-center mt-3 mx-auto border-2 border-dotted border-slate-300 h-[200px] rounded-lg'>
                     <label htmlFor='module_zip' className='btn bg-primary-blue'>
                         Upload Module
                     </label>
                     <p className='text-xs text-center px-8 pt-4'>
                        Please note that only zip file are allowed.
                     </p>
                     {
                        values.module_zip &&
                        <span className='text-primary-green py-1 bg-green-200 px-3 text-xs'>
                          Module uploaded successfully!
                       </span>
                     }
                 </div>
                </div>
                { useMultipleLanguages &&
                <div className='mt-4'>
                        <h5 className='text-x flex justify-between'>
                            Upload French Module Zip
                        </h5>
                        <input 
                            type="file" className='hidden' id='module_zip_f'
                            name='module_zip_f'
                            onChange={ e => uploadModuleZip(e, setFieldValue)}  accept=".zip" 
                            />
                            <div className='w-[90%] flex-col flex items-center justify-center mt-3 mx-auto border-2 border-dotted border-slate-300 h-[200px] rounded-lg'>
                            <label htmlFor='module_zip_f' className='btn bg-primary-blue'>
                                Upload Module
                            </label>
                            <p className='text-xs text-center px-8 pt-4'>
                                Please note that only zip file are allowed.
                            </p>
                            {
                                values.module_zip_f &&
                                <span className='text-primary-green py-1 bg-green-200 px-3 text-xs'>
                                    French module uploaded successfully!
                                </span>
                            }
                        </div>
                    </div>
                }
                { useMultipleLanguages &&
                <div className='mt-4'>
                        <h5 className='text-xs'>
                            Upload Portuguese Module Zip
                        </h5>
                        <input 
                            type="file" className='hidden' id='module_zip_p'
                            name='module_zip_p'
                            onChange={ e => uploadModuleZip(e, setFieldValue)}  accept=".zip" 
                            />
                            <div className='w-[90%] flex-col flex items-center justify-center mt-3 mx-auto border-2 border-dotted border-slate-300 h-[200px] rounded-lg'>
                            <label htmlFor='module_zip_p' className='btn bg-primary-blue'>
                                Upload Module
                            </label>
                            <p className='text-xs text-center px-8 pt-4'>
                                Please note that only zip file are allowed.
                            </p>
                            {
                                values.module_zip_p &&
                                <span className='text-primary-green py-1 bg-green-200 px-3 text-xs'>
                                    Portuguese module uploaded successfully!
                                </span>
                            }
                        </div>
                    </div>
                }
             </div>
               <section className='w-[58%]'>
               <div className='rounded-e-lg h-auto bg-white p-5 px-8 pb-12'>
                  <h5 className='text-lg font-semibold'>
                    Module Information
                  </h5>
                  <p className='text-xs font-semibold border-b border-gray-300 pb-2 mt-1 mb-5'>
                      Give detailed Information about the module 
                  </p>
                  <div>
                          <div className="mb-3 border-b border-slate-300 pb-2">
                            <label className="block font-semibold mt-3 w-full text-sm mb-1">
                              Would you like to create modules in other languages?
                            </label>
                            <div className='flex items-center'>
                              <input
                                type="checkbox"
                                className="mr-2"
                                checked={useMultipleLanguages}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUseMultipleLanguages(e.target.checked)}
                              />
                              <label className="text-xs">
                                Yes, I want to create module in different languages
                              </label>
                            </div>
                          </div>
                             {useMultipleLanguages ? (
                                <HorizontalTab 
                                  withPadding={false} tabsData={tabsData(setFieldValue, values)} 
                                />
                           ) :  <HorizontalTab  
                                    withPadding={false} tabsData={tabsData(setFieldValue, values).slice(0,1)}
                                  />
                          } 
                         <section className='grid grid-cols-1 gap-5 mt-1'>
                            <div className='flex border-b border-gray-400 pb-2 -mt-2 items-center justify-between pr-3'>
                             <span className='text-sm'>
                                Is assessment ?
                             </span>
                             <Switch
                                height={20}
                                width={40}
                                handleDiameter={18}
                                checked={isAssessment}
                                onChange={ () : void => {
                                  setIsAssessment(!isAssessment)
                                }}
                            />
                         </div>
                         { isAssessment ? 
                            <div className='-mt-4'>
                                <label htmlFor="assessment" className='text-xs'>
                                  Asessment Type <span className='text-lg text-red-500'>*</span>
                                </label>
                                <Field 
                                    id='assessment' className="input-style bg-primaryGray" 
                                    name='assessment' as='select'
                                >
                                  {assessmentType.map( (assessment) : React.ReactElement =>  {
                                     return (
                                       <option value={assessment} key={assessment}>
                                         {assessment}
                                       </option>
                                     )
                                  })}
                                </Field>
                                <p className='mt-2 text-red-500 text-sm'>
                                    <ErrorMessage name='assessment' />
                                </p>
                            </div>
                         : null
                         }
                         <PreviewModuleModal
                            openModal={openPreviewModal}
                            setOpenModal={setOpenPreviewModal}
                            modulePath={modulePath}
                            moduleId={moduleId}
                            previewId={previewModuleId}
                            previewModuleMuation={previewModuleMuation}
                            isMulti={useMultipleLanguages}
                         />
                         <Confirmation
                            openModal={openConfirmationModal}
                            setOpenModal={setOpenConfirmationModal}
                            title={
                              activeModule ? 'Module Updated Successfully!'
                              : 'Module Created Successfully!'
                            }
                            text='Would you like to preview your module before you proceed'
                            width='w-[400px]'
                            footerButtonsAlignment='end'
                            footer={
                            <>
                              <button 
                                 className='btn-light pl-8 pr-8 bg-primaryGray mr-3'
                                 onClick={ () => navigate('/admin/courses/module/') }
                              >
                                 Skip
                              </button>
                              <button 
                                 className='btn pl-8 pr-8 bg-primary-blue'
                                 onClick={previewModule}
                              >
                                 Preview
                              </button>
                            </>
                           }
                         />
                         <button disabled={isUploading } type='submit' className='btn w-full text-center bg-primary-blue'>
                            { isUploading ? 'Uploading Module Zip...' : 
                             !activeModule ? 'Create Module' : 'Edit Module'}
                         </button>
                         </section>
                  </div>
             </div>
               </section>
        </section>
        </Form>
            )}
    </Formik>
    </div>
  )
}

export default CreateModule