import createApiClient from "./api";

const client = createApiClient();

export const api = {
  testConnection: ({ orgId, payload }) =>
    client
      .post(`admin/org/${orgId}/zoom-meetings/test-connection`, payload)
      .then(({ data }) => data),
      
  createZoomSetting: ({ orgId, payload }) =>
    client
      .post(`admin/org/${orgId}/zoom-settings`, payload)
      .then(({ data }) => data),
  createZoomMeeting: ({ orgId, payload }) =>
    client
      .post(`admin/org/${orgId}/zoom-meetings`, payload)
      .then(({ data }) => data),
  getAllMeetings: ({ orgId, search, perPage, currentPage }) =>
    client
      .get(`admin/org/${orgId}/zoom-meetings?search=${search}&perPage=${perPage}&page=${currentPage}`)
      .then(({ data }) => data),
  getMeeting: ({ orgId, meetingId }) =>
    client
      .get(`admin/org/${orgId}/zoom-meetings/${meetingId}`)
      .then(({ data }) => data),
  getMeetingSettings: ({ orgId }) =>
    client
      .get(`admin/org/${orgId}/zoom-settings`)
      .then(({ data }) => data),
  getMetingParticipants: ({ orgId, meetingId }) =>
    client
      .get(`admin/org/${orgId}/zoom-meetings/${meetingId}/participants`)
      .then(({ data }) => data),

  updateMeetingStatus: ({ orgId, meetingId,payload }) =>
    client
      .patch(
        `admin/org/${orgId}/zoom-meetings/${meetingId}/update-status`,
        payload
      )
      .then(({ data }) => data),

  updateMeeting: ({ orgId, meetingId,payload }) =>
    client
      .patch(
        `admin/org/${orgId}/zoom-meetings/${meetingId}`,
        payload
      )
      .then(({ data }) => data),

  deleteMeeting: ({ orgId, meetingId }) =>
    client.delete(`/admin/org/${orgId}/zoom-meetings/${meetingId}`).then(({ data }) => data),
};
