import React, { useEffect, useMemo } from "react";
import { Ripple, initTE } from "tw-elements";
import debounce from "lodash.debounce";

const Search = ({
  iconColor,
  value,
  placeholder,
  bgColor,
  onClick,
  onChange,
  showButton = true,
  debounceDelay = 300, // Optional debounce delay, default is 300ms
  className,
  width,
  mb = 'mb-3',
  ...rest
}) => {

  useEffect(() => {
    initTE({ Ripple }) 
  }, []);

  // memoized debounce function
  const debouncedOnChange = useMemo(
    () =>
      debounce((value) => {
        if (onChange) {
          onChange(value);
        }
      }, debounceDelay),
    [onChange, debounceDelay]
  );

  const handleChange = (event) => {
    debouncedOnChange(event.target.value); // Pass the input value to the debounced function
  };

  return (
    <div className={`${mb} ${width}`}>
      {/* <div className={className || 'mb-5'}> */}
      <div className="relative flex w-full flex-wrap items-stretch">
        <input
          type="search"
         placeholder={`🔍 ${placeholder}` || "🔍e.g learner-email@gmail.com or name.."}
          aria-label="Search"
          className={`input-style bg-primaryGray ${className}`}
          aria-describedby="button-addon1"
          onChange={handleChange}
          value={value}
          {...rest}
        />
      </div>
    </div>
  );
};

export default Search;
