import React from 'react'
import flightIcon from '../../../../../../../assets/Bulk Upload.png'
import { SelectionModalType } from './ChooseType'

const StarterPage: React.FC<SelectionModalType> = (props) => {
  return (
    <div className='flex flex-col justify-between'>
        <img src={flightIcon} alt="flight image" className='w-[600px] h-[300px] mx-auto block' />
        <div className='text-center mt-5'>
            <h3 className='text-md text-center text-primary font-semibold'>
              Create Your First Learning Path
            </h3>
            <p className='text-sm mt-1 mb-4'>
              Guide your learners through a sequence of courses
            </p>
            <button
              onClick={ () => {
                 props.handleSelectionModal(true)
              } } 
             className='btn mt-3 bg-primary-blue pl-8 pr-8'> 
                 Get Started
             </button>
        </div>
    </div>
  )
}

export default StarterPage