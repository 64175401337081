import React from 'react'

const Team = ({name, cohort, point}) => {
  return (
    <div className='flex justify-between items-center  shadow-md p-2 mb-3'>
       <div className='flex items-center'>
            <div className='bi bi-people h-[35px] w-[35px] text-white rounded-lg bg-secondary flex items-center justify-center text-xl' />
            <h5 className='text-xs pl-1 font-medium'>
              {name} 
               <span className='text-[11px] block -mt-1 text-gray-400'>{cohort}</span>
            </h5>
       </div>
       <span className='bi bi-plus-circle text-xs font-bold text-green-500'>&nbsp;{point} points</span>
    </div>
  )
}

export default Team