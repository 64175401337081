import React, { useState } from "react";
import CenteredModal from "../../../../components/Modal/CenteredModal";
import { useQuery } from "react-query";
import { api } from "../../../../api/admin-management";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Select from "react-select";
import useSelectStyles from "../../../../hooks/useSelectStyles";

const NewUserModal = ({
  selectedUser,
  setSelectedUser,
  refetch,
  openNewUserModal,
  setOpenNewUserModal,
}) => {
  const isEditMode = !!selectedUser;
  const selectStyles = useSelectStyles();
  const [showPassword, setShowPassword] = useState(false);

  const [selectedRole, setSelectedRole] = useState(
    selectedUser?.role
      ? { value: selectedUser.role.id, label: selectedUser.role.name }
      : null
  );
  const user = JSON.parse(window.sessionStorage.getItem("user"));
  const orgId = user?.org?.id;
  const accountType = user?.role?.slug;
  const isClientAdmin = accountType === "client-admin";

  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    name: selectedUser?.name || "",
    email: selectedUser?.email || "",
    password: "", // Keep empty for editing, unless changing password
    // phone_number: selectedUser?.phone_number || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name field is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field is required"),
    password: isEditMode
      ? Yup.string()
      : Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(
            /[a-z]/,
            "Password must contain at least one lowercase letter"
          )
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase letter"
          )
          .matches(/\d/, "Password must contain at least one number")
          .matches(
            /[@$!%*?&]/,
            "Password must contain at least one special character"
          )
          .required("Password is required"),
  });

  const { data: rolesData, isLoading } = useQuery(
    ["all-roles", search, perPage, currentPage],
    async () => {
      const response = await api.getAllRoles({ ...(isClientAdmin ? { orgId } : {}),
      search,
      perPage,
      currentPage, });
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const roleOptions = rolesData?.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!selectedRole || !selectedRole.value) {
      toast.error("Please select a role");
      return;
    }

    setSubmitting(true);

    try {
      let response;
      if (isEditMode) {
        response = await api.updateAdmin({
          adminId: selectedUser.id,
          payload: {
            name: values.name,
            email: values.email,
            roleId: selectedRole.value,
            // Only send password if it is being updated
            ...(values.password ? { password: values.password } : {}),
            ...(isClientAdmin ? { orgId: orgId } : {}),
          },
        });
        toast.success(response?.message || "User updated successfully!");
        setSelectedUser(null);
      } else {
        response = await api.createAdmin({
          name: values.name,
          email: values.email,
          password: values.password,
          roleId: selectedRole.value,
          // phone_number: values.phone_number,
          ...(isClientAdmin ? { orgId: orgId } : {}),
        });

        toast.success(response?.message || "User created successfully!");
      }
      refetch();
      setOpenNewUserModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <CenteredModal
      title={isEditMode ? "Edit User" : "New User"}
      hasHeader={true}
      open={openNewUserModal}
      setOpen={setOpenNewUserModal}
      width={"w-[600px]"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="w-full p-6 bg-white text-left">
              <p>
                {isEditMode
                  ? "Edit user details"
                  : "Add new role and assign appropriate access to the user"}
              </p>

              <div className="mb-4">
                {/* Name */}
                <div className="flex flex-col mb-4 text-left">
                  <label
                    htmlFor="name"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Name
                  </label>
                  <div className="flex relative">
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="input-style bg-primaryGray"
                      placeholder="Enter Name"
                    />
                  </div>
                  <ErrorMessage
                    name="name"
                    component="p"
                    className="error-style"
                  />
                </div>
                {/* Email */}
                <div className="flex flex-col mb-4">
                  <label
                    htmlFor="email"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Email
                  </label>
                  <div className="flex relative">
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      className="input-style bg-primaryGray"
                      placeholder="Enter email address"
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="p"
                    className="error-style"
                  />
                </div>
                {/* Password */}
                {/* Password Field */}
                {!isEditMode && (
                  <div className="flex flex-col gap-2 mb-4">
                    <label
                      htmlFor="password"
                      className="mb-1 text-base font-medium text-gray600"
                    >
                      Password
                    </label>
                    <div className="flex relative">
                      <Field
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        className="input-style bg-primaryGray"
                        placeholder="Enter your password"
                      />
                      <button
                        type="button"
                        aria-label="Toggle password visibility"
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-3 text-gray-500 cursor-pointer focus:outline-none"
                      >
                        {showPassword ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="p"
                      className="error-style"
                    />
                  </div>
                )}

                <div>
                  <Select
                    options={roleOptions}
                    onChange={handleRoleChange}
                    value={selectedRole}
                    placeholder="Select a role"
                    styles={selectStyles}
                    className="text-base"
                  />
                </div>

                {/* Phone Number */}
                {/* <div className="flex flex-col mb-4">
                  <label
                    htmlFor="phone_number"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Phone Number
                  </label>
                  <div className="flex relative">
                    <Field
                      type="text"
                      id="phone_number"
                      name="phone_number"
                      className="input-style bg-primaryGray"
                      placeholder="Enter phone number"
                    />
                  </div>
                  <ErrorMessage
                    name="phone number"
                    component="p"
                    className="error-style"
                  />
                </div> */}
              </div>

              <div className="">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-primaryBlue hover:opacity-50 text-white w-full mb-4 rounded-md cursor-pointer font-bold py-3 px-6"
                >
                  {isSubmitting ? (
                    <div className="flex justify-center">
                      <ClipLoader color="#fff" />
                    </div>
                  ) : isEditMode ? (
                    "Update"
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CenteredModal>
  );
};

export default NewUserModal;
